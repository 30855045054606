import { axiosClient, getApiRoutes } from 'api';
import axios from 'axios';
import { PermissionsAdminUser } from 'api/models/AdminPermissions/PermissionsAdminUser';
import { PermissionsAdminUserGetRequest } from 'api/models/AdminPermissions/PermissionsAdminUserGetRequest';
import { UpdatePermissionsAdminUserRequest } from './models/AdminPermissions/UpdatePermissionsAdminUserRequest';

export const fetchUsersFromPermissionsDb = async (userIds: string[]) => {
  const api = await getApiRoutes();

  const data: PermissionsAdminUserGetRequest = {
    userIds,
  };

  const response = await axiosClient.post<PermissionsAdminUser[]>(
    api.permissionsUser,
    data,
  );

  return response;
};

export const updateSingleUserPermissions = async (
  data: UpdatePermissionsAdminUserRequest,
) => {
  const api = await getApiRoutes();

  const response = await axiosClient.put(api.permissionsUser, data);

  return response;
};
