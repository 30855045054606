import Spinner from 'components-lib/Spinner';
import { LoadingStateEnum } from 'models/enums';
import React, { useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { PermissionsAdminUserView } from '../models/PermissionsAdminUserView';
import { updateSingleUserPermissions } from 'api';

interface IProps extends ModalProps {
  userData: PermissionsAdminUserView;
  onSuccess?: (result: any) => void;
}

// TODO: add validation
export const UpdatePermissionsAdminUserModal = ({
  onSuccess,
  userData,
  ...props
}: IProps) => {
  const [isOpen, setOpen] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStateEnum.IDLE);
  const [basicAccessChecked, setBasicAccessChecked] = useState(
    !!userData.basic_access,
  );
  const [fullAccessChecked, setFullAccessChecked] = useState(
    !!userData.full_access,
  );

  const closeModal = () => {
    setOpen(false);
  };

  const handleUpdatePermissions = async () => {
    const basic_access = basicAccessChecked;
    const full_access = fullAccessChecked;

    try {
      setLoadingStatus(LoadingStateEnum.PENDING);
      const result = await updateSingleUserPermissions({
        user_id: userData.id.toString(),
        basic_access,
        full_access,
      });
      if (onSuccess) onSuccess(result);

      console.log(result);
    } finally {
      setLoadingStatus(LoadingStateEnum.IDLE);
    }
  };

  const handleBasicAccessClick = () => {
    setBasicAccessChecked(!basicAccessChecked);
  };

  const handleFullAccessClick = () => {
    setFullAccessChecked(!fullAccessChecked);
  };

  const buttonsDisabled = loadingStatus === LoadingStateEnum.PENDING;

  return (
    <>
      <Modal {...props} backdrop="static" show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton={!buttonsDisabled}>
          <Modal.Title>Update permissions for {userData.email}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>
            basic access
            <input
              type="checkbox"
              name="basic_access"
              onClick={handleBasicAccessClick}
              checked={basicAccessChecked}
            />
          </label>
          <label>
            full access
            <input
              type="checkbox"
              name="full_access"
              onClick={handleFullAccessClick}
              checked={fullAccessChecked}
            />
          </label>
        </Modal.Body>

        <Modal.Footer>
          {loadingStatus === LoadingStateEnum.PENDING && <Spinner />}
          <Button
            variant="primary"
            onClick={handleUpdatePermissions}
            disabled={buttonsDisabled}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={buttonsDisabled}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePermissionsAdminUserModal;
