import {
  EvalFormField,
  EventFormValues,
  UpdateEventDTO,
} from 'features/event/models';
import { updateEvent } from 'features/event/store/eventThunks';
import { UpdateEventModeEnum } from 'models/enums/Event.enum';
import { Dispatch } from 'react';
import {
  createEvalPermissionsFromEvalPermissionArray,
  createFeatureFlagsFromFlagsArray,
  createObjectFromLdapFieldPermissionFieldsArray,
  createObjectFromNotificationFieldsArray,
} from '.';
import { createEvalFormsDTO } from '../formTemplateHelper';
import { createStaticObjArrFromStaticLinkArr } from './createStaticObjArrFromStaticLinkArr';
import { createSubmissionObjArrFromField } from './createSubmissionObjArrFromField';

/**
 * @description create EventDTO from EventFormValues and dispatch create or update event action
 * @param {EventFormValues} fields
 * @param {*} actions
 * @param {Dispatch<any>} dispatch
 * @param {string} eventId
 * @param {EvalFormField[]} oldEventEvalNamesArray
 * @param {string[]} oldEventReadRoles
 * @param {string} userId
 * @param {*} setIsFormSubmit
 */
export const onEventSubmit = async (
  fields: EventFormValues,
  actions: any,
  dispatch: Dispatch<any>,
  eventId: string,
  oldEventEvalNamesArray: EvalFormField[],
  setIsFormSubmit: any,
) => {
  console.log(fields.evalFormId);
  const evalForms = createEvalFormsDTO(
    fields.evalFormId,
    eventId,
    oldEventEvalNamesArray,
  );

  const updatedEvent: UpdateEventDTO = {
    id: eventId,
    title: fields.title,
    description: fields.description,
    type: fields.type,
    evalForms,
    loginDomain: fields.loginDomain,
    notificationFields: createObjectFromNotificationFieldsArray(
      fields.notificationFields,
    ),
    stages: fields.stages.filter(Boolean),
    staticPages: fields.staticPages.filter(Boolean),
    staticLinks: createStaticObjArrFromStaticLinkArr(
      fields.staticLinks.filter(Boolean),
    ),
    archived: fields.archived,
    hidden: fields.hidden,
    commentNotifications: fields.commentNotifications,
    commentReplyNotifications: fields.commentReplyNotifications,
    anonymousSubmissions: fields.anonymousSubmissions,
    anonymousComments: fields.anonymousComments,
    reportingTemplateId: fields.reportingTemplateId,
    highlightedField: fields.highlightedField,
    hubEventIds: fields.hubEventIds.filter(Boolean),
    featureFlags: createFeatureFlagsFromFlagsArray(fields),
    creatorManagerPermissions: createEvalPermissionsFromEvalPermissionArray(
      fields.creatorManagerPermissions,
    ),
    creatorPermissions: createEvalPermissionsFromEvalPermissionArray(
      fields.creatorPermissions,
    ),
    ldapFieldPermission: createObjectFromLdapFieldPermissionFieldsArray(
      fields.ldapFieldPermission,
    ),
    viewSubmissionTabs: createSubmissionObjArrFromField(
      fields.viewSubmissionTabs,
    ),
    issuer: fields.issuer,
    updateType: UpdateEventModeEnum.UPDATE,
    customNotificationEmailSettings: fields.customNotificationEmailSettings,
  };
  console.log(updatedEvent);
  //update event
  await dispatch(updateEvent(updatedEvent));
  setIsFormSubmit(true);

  return;
};
