import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { Button, CircularProgress, TooltipProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, styled } from '@mui/system';
import * as api from 'api';
import { EventRole } from 'features/event/models';
import { eventSlice } from 'features/event/store/eventSlice';
import { generateRoleKey } from 'features/utils/generateRoleKey';
import * as React from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/store';
import { RoleFormModalDialog } from './Modals/RoleFormModalDialog';
import { RoleForm } from './RoleForm';
interface IProps {
  eventId: string;
  eventRole: EventRole;
  hideName?: boolean;
  customName?: string; // Used to replace the roleName, in that case the roleName is not editable.
  openInEditMode?: boolean;
}

export const RoleCardForm = ({
  eventId,
  eventRole,
  hideName,
  customName,
  openInEditMode = false,
}: IProps) => {
  const { roleName } = eventRole;
  const [isInEditMode, setIsInEditMode] = React.useState(openInEditMode);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const dispatch = useAppDispatch();

  const openModal = () => {
    if (isDeleting) return;
    setIsInEditMode(true);
  };

  /** delete logic */
  const handleDelete = async (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsDeleting(true);

    const roleKeyToRemove = generateRoleKey(eventRole, eventId);

    try {
      await api.deleteRoles(eventId, [eventRole]);
      dispatch(eventSlice.actions.removeRole(roleKeyToRemove));

      toast.success(`Deleted`);
    } catch (e) {
      toast.error(`${e}`);
    } finally {
      setIsDeleting(false);
    }
  };

  let cardTitle = customName ? <>{customName}</> : <>{roleName}</>;

  return (
    <>
      <HtmlTooltip
        enterDelay={300}
        enterNextDelay={300}
        title={
          <Box sx={{ p: 1 }}>
            <RoleForm
              eventId={eventId}
              eventRole={eventRole}
              customName={customName || eventRole.roleName}
              isEditable={false}
            />
          </Box>
        }
      >
        <Button
          sx={{
            display: 'inline-flex',
            width: '100%',
          }}
          variant="outlined"
          onClick={openModal}
          disableRipple
        >
          <Box
            sx={{
              width: '66%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'left',
              textTransform: 'none',
            }}
          >
            {cardTitle}
          </Box>
          <Box sx={{ width: '33%', textAlign: 'right' }}>
            <IconButton aria-label="edit" disabled={isDeleting}>
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? <CircularProgress size={'1.5rem'} /> : <Delete />}
            </IconButton>
          </Box>
        </Button>
      </HtmlTooltip>

      {isInEditMode && (
        <RoleFormModalDialog
          open={isInEditMode}
          eventId={eventId}
          eventRole={eventRole}
          customName={customName}
          onClose={() => {
            setIsInEditMode(false);
          }}
          onSuccess={() => {
            setIsInEditMode(false);
          }}
        />
      )}
    </>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    m: 0,
  },
}));
