import React from 'react';
import { useState } from 'react';
import { deleteObjects } from 'api/staticFiles';
import { LoadingStateEnum } from 'models/enums';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { IRouteParams } from 'routes/models/IRouteParams';
import Spinner from 'components-lib/Spinner';
import { ChonkyActions, FileArray, FileBrowser, FileList } from 'chonky';

interface IProps extends ModalProps {
  objects: FileArray;
  onSuccess?: () => void;
}

export const DeleteObjectsModal = ({
  onSuccess,
  objects,
  ...props
}: IProps) => {
  const [isOpen, setOpen] = useState(true);
  const [status, setStatus] = useState(LoadingStateEnum.IDLE);

  const { eventId } = useParams<IRouteParams>();

  const closeModal = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setStatus(LoadingStateEnum.PENDING);

    const objectPaths: string[] = [];
    objects.forEach((item) => {
      if (item?.id) {
        objectPaths.push(item.id);
      }
    });

    deleteObjects(eventId, objectPaths)
      .then(() => {
        setStatus(LoadingStateEnum.COMPLETE);
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        setStatus(LoadingStateEnum.ERROR); // TODO: error handling
      });
  };

  const buttonsDisabled = status === LoadingStateEnum.PENDING;

  return (
    <>
      <Modal {...props} backdrop="static" show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton={!buttonsDisabled}>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileBrowser
            files={objects}
            disableDragAndDrop
            disableSelection
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
          >
            <FileList />
          </FileBrowser>
        </Modal.Body>

        <Modal.Footer>
          {status === LoadingStateEnum.PENDING && <Spinner />}
          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={buttonsDisabled}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={buttonsDisabled}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteObjectsModal;
