import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import { DEFAULT_ISSUER } from 'models/constants';
import { CreateEventFormValues } from '../models/CreateEventFormValues';
import { generateIdBasedOnCustomSuffix } from './generateIdBasedOnCustomSuffix';

export const generatePreviewText = (
  values: CreateEventFormValues,
  userIssuer: string | undefined,
) => {
  const { mainAppURL } = getConfigFromSessionStorage();

  const newEventId = generateIdBasedOnCustomSuffix(
    values.id,
    values.suffix,
    values.skipSuffixGeneration,
  );

  const pre = `${mainAppURL}/event/`;
  if (values.skipSuffixGeneration) {
    return `${pre}${newEventId}`;
  } else {
    if (userIssuer === DEFAULT_ISSUER) {
      return `${pre}${newEventId}-random`;
    } else {
      if (!userIssuer) throw new Error('userIssuer is required in this case');

      return `${pre}${newEventId}-${userIssuer}`;
    }
  }
};
