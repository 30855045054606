import React from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { ROUTES } from 'routes';
import { AccessMsgs } from 'models/enums';
import { RootState } from 'store/store';
interface IProps {
  eventId: string;
}

export function Event({ eventId }: IProps) {
  const { event } = useSelector((state: RootState) => state.event);

  const isUsingRoleMapSystem = event?.featureFlags?.isUsingRoleMapSystem;

  return isUsingRoleMapSystem ? (
    <div className="container ml-0">
      <div className="row">
        <div className="col">
          <Link
            to={generatePath(ROUTES.home.events.actions.edit, {
              eventId: eventId,
            })}
            className="btn btn-link"
          >
            Edit Event
          </Link>
          <div className="p-3 border overflow-hidden">
            <p className="text-monospace small mb-0">{JSON.stringify(event)}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <h2 className="text-danger">{AccessMsgs.OLD_ROLES_EVENT_EDIT}</h2>
  );
}
