import React from 'react';
import { Badge } from 'react-bootstrap';

export const booleanRenderer = (value?: boolean) => {
  if (!value) value = false; // handle 'undefined' case
  const variant = value ? 'success' : 'danger';
  return (
    <div>
      <Badge variant={variant}>{value.toString()}</Badge>
    </div>
  );
};
