import { EventRole } from 'features/event/models';
import { EventRoleTypeEnum } from 'models/enums';

/**
 * Generates a key for the roles object based on role name and roleType.
 * @param role
 * @param eventId
 * @returns
 */
export const generateRoleKey = (role: EventRole, eventId: string) => {
  if (role.roleType === EventRoleTypeEnum.CUSTOM) {
    return `${role.roleName}#${eventId}`;
  } else {
    return role.roleName;
  }
};
