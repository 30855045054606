import * as Yup from 'yup';

const signUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  given_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  family_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required'),
  userRoles: Yup.array()
    .min(1, 'At least one custom role is required')
    .test(
      'test-custom-roles',
      'At least one custom role is required',
      function (roleObjects) {
        return roleObjects.some(
          (roleObject) => Object.values(roleObject)[0] === true,
        );
      },
    ),
  // password: Yup.string()
  //   .required('No password provided.')
  //   .min(8, 'Password is too short - should be 8 chars minimum.')
  //   .matches(
  //     /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*/,
  //     'Password should contain uppercase letter, lowercase letter and number.',
  //   ),
});

const ssoSignUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  given_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  family_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required'),
  issuer: Yup.string()
    .min(1, 'Please select an option')
    .required('No issuer provided.'),
});

const editUserSchema = Yup.object().shape({
  selectedReadRoles: Yup.string()
    .min(1, 'Select at least one read role')
    .required('No read role is selected'),
});

export { signUpSchema, ssoSignUpSchema, editUserSchema };
