import { FileArray } from 'chonky';
import { useState } from 'react';
import { ROOT_PREFIX } from '../constants';

export const useFileExplorerState = () => {
  // const [error, setError] = useState<string | null>(null);
  const [reloadTrigger, setReloadTrigger] = useState(false); // Used to trigger file list reloads on every value change.

  const [folderPrefix, setFolderPrefix] = useState(ROOT_PREFIX);

  const [showCreateFolder, setShowCreateFolder] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [files, setFiles] = useState<FileArray>([]);
  const [filesInClipboard, setFilesInClipboard] = useState<FileArray>([]);
  const [objectsToDelete, setObjectsToDelete] = useState<FileArray>([]);
  const [objectToRename, setObjectToRename] = useState<Object>({});
  const [failedUploads, setFailedUploads] = useState<Array<string>>([]);
  const [uploadState, setUploadState] = useState({
    total: 0,
    completed: 0,
    failed: 0,
  });
  const [modalTitle, setModalTitle] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  return {
    reloadTrigger,
    setReloadTrigger,
    folderPrefix,
    setFolderPrefix,
    showCreateFolder,
    setShowCreateFolder,
    showDeleteModal,
    setShowDeleteModal,
    showRenameModal,
    setShowRenameModal,
    showProgress,
    setShowProgress,
    files,
    setFiles,
    filesInClipboard,
    setFilesInClipboard,
    objectsToDelete,
    setObjectsToDelete,
    objectToRename,
    setObjectToRename,
    failedUploads,
    setFailedUploads,
    uploadState,
    setUploadState,
    modalTitle,
    setModalTitle,
    loading,
    setLoading,
  };
};
