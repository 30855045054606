import { DEFAULT_EVALS_PERMISSIONS } from 'features/event/components/constants';
import { EvalPermissions } from 'features/event/models';

/**
 * @description transfer eval permission field input to EvalPermissions object
 * @param {string[]} evalPermissionArray
 * @returns {EvalPermissions}
 */
export function createEvalPermissionsFromEvalPermissionArray(
  evalPermissionArray: string[],
): EvalPermissions {
  const result: any = {};
  if (!evalPermissionArray) return result;

  Object.keys(DEFAULT_EVALS_PERMISSIONS).forEach((key) => {
    evalPermissionArray.includes(key)
      ? (result[key] = true)
      : (result[key] = false);
  });
  return result as EvalPermissions;
}
