import { FileExplorerV2 } from 'components-lib/FileExplorerV2/FileExplorerV2';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';

export default function StaticPages() {
  return (
    <>
      <FileExplorerV2 />
      <div className="mt-3 border-top">
        <Link to={`${ROUTES.home.events.root}`}>Go To Events List Page</Link>
      </div>
    </>
  );
}
