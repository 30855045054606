import { Alert, Button, Dialog } from '@mui/material';
import { Box } from '@mui/system';
import { ConfirmRejectCancelModal } from 'components-lib/Modals/ConfirmRejectCancelModal';
import Spinner from 'components-lib/Spinner';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CodeEditor } from '../CodeEditor/CodeEditor';
import { useConfirmSaveActions } from './hooks/useConfirmSaveActions';
import { useFormatCode } from './hooks/useFormatCode';
import { useHandleSave } from './hooks/useHandleSave';
import { useLoadFile } from './hooks/useLoadFile';
import { useTemplateEditorTabs } from './hooks/useTemplateEditorTabs';
import { TemplateEditorTab } from '../../Models/TemplateEditorTab.enum';
import { TemplateApplyToForm } from '../TemplateApplyToForm/TemplateApplyToForm';

interface IProps {
  selectedNode: string;
  setSelectedNode: React.Dispatch<React.SetStateAction<string>>;
  showApplyToTab?: boolean;
}

// TODO: add a delete dialog
export const LoginTemplateEditor = ({
  selectedNode,
  setSelectedNode,
  showApplyToTab,
}: IProps) => {
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
  const [nextNode, setNextNode] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<TemplateEditorTab>(
    TemplateEditorTab.HTML,
  );

  const editorRef = useRef(null);
  const { saveCodeAsync, saved, setSaved, isSaving } = useHandleSave({
    editorRef,
    selectedNode,
    selectedTab,
  });

  const { edtiorMode, loadFile, loadedCode, loadingFile } = useLoadFile({
    selectedNode,
    selectedTab,
  });

  useEffect(() => {
    if (
      selectedTab === TemplateEditorTab.HTML ||
      selectedTab === TemplateEditorTab.CSS
    ) {
      loadFile(selectedTab);
    }
  }, [loadFile, selectedTab]);

  const editor = useMemo(
    () => (
      <>
        {loadingFile ? (
          <Spinner />
        ) : (
          <CodeEditor
            initialCode={loadedCode}
            readOnly={loadingFile}
            mode={edtiorMode}
            onChange={() => {
              setSaved(false);
            }}
            ref={editorRef}
          />
        )}
      </>
    ),
    [edtiorMode, loadedCode, loadingFile, setSaved],
  );
  const {
    handleFormatCode,
    formatCodeError,
    resetFormatCodeError,
  } = useFormatCode(editorRef, edtiorMode);

  // TODO: Update confirm save logic
  const { onConfirmSave, onRejectSave, onCancel } = useConfirmSaveActions({
    saveCodeAsync,
    nextNode,
    setSaved,
    selectedNode,
    setSelectedNode,
    setShowConfirmSaveModal,
  });

  const confirmSaveModal = useMemo(() => {
    return (
      <ConfirmRejectCancelModal
        open={showConfirmSaveModal}
        texts={{ confirm: 'Save', reject: `Don't save`, cancel: 'Cancel' }}
        title={'Changes not saved'}
        content={`Are you sure you want to leave without saving the changes`}
        onConfirm={onConfirmSave}
        onReject={onRejectSave}
        onCancel={onCancel}
        onClose={onCancel}
      />
    );
  }, [onCancel, onConfirmSave, onRejectSave, showConfirmSaveModal]);

  const applyToForm = useMemo(
    () => <TemplateApplyToForm selectedNode={selectedNode} />,
    [selectedNode],
  );

  const { tabs } = useTemplateEditorTabs({
    editor,
    selectedTab,
    setSelectedTab,
    applyToSettings: applyToForm,
    showApplyToTab,
  });
  return (
    <Box>
      <Dialog
        open={!!formatCodeError}
        onBackdropClick={() => {
          resetFormatCodeError();
        }}
      >
        <Alert severity="error">
          Formatting failed, please review your code
        </Alert>
      </Dialog>

      {selectedNode && (
        <>
          <Box>{selectedNode}</Box>
        </>
      )}

      <Box>{tabs}</Box>
      {!loadingFile &&
        typeof loadedCode === 'string' && ( // ensure that empty strings are also considered valid
          <>
            {(selectedTab === TemplateEditorTab.HTML ||
              selectedTab === TemplateEditorTab.CSS) && (
              <Box>
                <Button
                  variant="contained"
                  disabled={saved || isSaving}
                  onClick={async () => {
                    await saveCodeAsync();
                  }}
                >
                  Save
                </Button>
                <Button variant="contained" onClick={handleFormatCode}>
                  Format code
                </Button>
              </Box>
            )}
          </>
        )}

      {showConfirmSaveModal && confirmSaveModal}
    </Box>
  );
};
