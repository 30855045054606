import Spinner from 'components-lib/Spinner';
import { resetEvent } from 'features/event/store/eventActions';
import { LoadingStateEnum } from 'models/enums';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { RootState } from 'store/models';
import { Event } from './Event';

export default function Main() {
  let { eventId } = useParams<Record<string, string>>();
  const { loading } = useSelector((state: RootState) => state.event);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetEvent());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading === LoadingStateEnum.IDLE ? (
    <>
      <Event eventId={eventId} />

      <div className="col mt-4">
        <Link
          to={`${ROUTES.home.events.root}`}
          className="d-flex align-items-center small"
        >
          <i className="material-icons">arrow_left</i>Back to event list
        </Link>
      </div>
    </>
  ) : (
    <Spinner />
  );
}
