import * as api from 'api';
import { FormInputField } from 'components-lib';
import Spinner from 'components-lib/Spinner';
import { handleToastOnResponse } from 'features/utils/toastHelper';
import { signUpSchema } from 'features/validations/userSchema';
import { Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { ErrorMsgsEnum, EventRoleTypeEnum, SuccessMsgEnum } from 'models/enums';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/models';

interface UserFormValues {
  email: string;
  given_name: string;
  family_name: string;
  userRoles: { [key: string]: boolean }[];
  password: string;
}

interface IProps {
  onCancel: () => void;
}

export const CreateUserForm = ({ onCancel }: IProps) => {
  const { event } = useSelector((state: RootState) => state);
  const roles = event.event?.roles;
  const customRoles =
    roles &&
    Object.entries(roles).filter(
      ([, role]) => role.roleType === EventRoleTypeEnum.CUSTOM,
    );

  const onSubmit = async (
    values: UserFormValues,
    actions: FormikHelpers<UserFormValues>,
  ) => {
    const userRoles = values.userRoles // roleObjects should be
      .filter((roleObject) => Object.values(roleObject)[0]) // only the ones that are true
      .map((roleObject) => Object.keys(roleObject)[0]); // get the key

    const user = {
      email: values.email,
      firstName: values.given_name,
      lastName: values.family_name,
      userRoles,
    };

    //create user in user table
    const responseForCreateUser = await api.createUser(user);
    actions.resetForm();

    handleToastOnResponse(
      responseForCreateUser,
      ErrorMsgsEnum.INVITE_USER,
      SuccessMsgEnum.INVITE_USER,
    );
    //TODO: TBD where to redirect user to user detail page or user main page
    // push(generatePath(ROUTES.dashboard.events.users.root, { eventId }));
  };

  const initialValues: UserFormValues = {
    email: '',
    given_name: '',
    family_name: '',
    userRoles: [],
    password: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        validationSchema={signUpSchema}
      >
        {({
          values,
          errors,
          status,
          touched,
          resetForm,
          isSubmitting,
          dirty,
          isValid,
          setFieldValue,
        }) =>
          isSubmitting ? (
            <Spinner />
          ) : (
            <Form className="w-75">
              <FormInputField
                fieldLabel="Email"
                fieldName="email"
                type="email"
                touched={touched.email}
                errors={errors.email}
                isRequired={true}
              />

              <FormInputField
                fieldLabel="First Name"
                fieldName="given_name"
                touched={touched.given_name}
                errors={errors.given_name}
                isRequired={true}
              />

              <FormInputField
                fieldLabel="Last Name"
                fieldName="family_name"
                touched={touched.family_name}
                errors={errors.family_name}
                isRequired={true}
              />

              <div className="MuiFormHelperText-root Mui-error">
                {errors.userRoles}
              </div>
              <h6>Select roles to add to the user:</h6>
              <FieldArray
                name="userRoles"
                render={(arrayHelpers) => (
                  <div>
                    {customRoles?.map(([roleKey, role], index) => (
                      <div key={roleKey}>
                        <label>
                          <Field
                            type="checkbox"
                            name={`userRoles.${index}.${roleKey}`}
                          />
                          {role.roleName}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              />

              <button
                type="submit"
                className="btn btn-success mt-4"
                disabled={!(isValid && dirty) || isSubmitting}
              >
                Invite
              </button>
              <button
                className="btn btn-danger mt-4"
                type="button"
                onClick={() => {
                  resetForm({
                    values: initialValues,
                  });
                  onCancel();
                }}
              >
                Cancel
              </button>
            </Form>
          )
        }
      </Formik>
    </>
  );
};
