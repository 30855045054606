import React from 'react';
import path from 'path';
import { FileArray, FileData } from 'chonky';
import { ROOT_PREFIX } from '../constants';
import { useFileExplorerProps } from './useFileExplorerProps';

const lastSlashRegex = /\/*$/;

export const useFolderChain = (folderPrefix: string) => {
  const { eventId, pathPrefix } = useFileExplorerProps();

  const folderChain = React.useMemo(() => {
    let folderChain: FileArray;
    if (folderPrefix === ROOT_PREFIX) {
      folderChain = [];
    } else {
      // console.log('folderPrefix', folderPrefix);
      let currentPrefix = '';
      folderChain = folderPrefix
        .replace(pathPrefix, '')
        .replace(lastSlashRegex, '')
        .split('/')
        .map((prefixPart): FileData => {
          // console.log('currentPrefix, prefixPart', currentPrefix, prefixPart);
          currentPrefix = currentPrefix
            ? path.join(
                currentPrefix.replace(/^(\.\.(\/|\\|$))+/, ''),
                prefixPart.replace(/^(\.\.(\/|\\|$))+/, ''),
              )
            : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          };
        });
    }

    folderChain.unshift({
      id: ROOT_PREFIX,
      name: eventId,
      isDir: true,
    });

    return folderChain;
  }, [folderPrefix, pathPrefix, eventId]);

  return { folderChain };
};
