import Modal from '@mui/material/Modal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  FormArrayField,
  FormInputField,
  FormSelectField,
  FormSingleCheckboxField,
  FormTextareaField,
} from 'components-lib';
import Spinner from 'components-lib/Spinner';
import {
  EvalFormField,
  EventFormValues,
  LdapFieldPermissionField,
  NotificationField,
  StaticLink,
  SubmissionTabField,
} from 'features/event/models';
import { createIssuerField } from 'features/event/util/createIssuerField';
import {
  DEFAULT_FORM_FIELD_OPTIONS,
  DEFAULT_FORM_FIELD_TYPE_OPTIONS,
} from 'features/formTemplate/components/constants';
import { resetFormTemplateState } from 'features/formTemplate/store/formTemplateActions';

import {
  createArrayFromLdapFieldPermissionObject,
  createArrayFromNotificationFieldsObject,
  filterKeysWithTrueValue,
  onEventSubmit,
} from 'features/utils/eventUtils';
import { createStaticLinkArrFromStaticObjArr } from 'features/utils/eventUtils/createStaticLinkArrFromStaticObjArr';
import { createSubmissionTabFieldArrFromObjArr } from 'features/utils/eventUtils/createSubmissionTabFieldArrFromObjArr';
import { eventSchema } from 'features/validations/eventSchema';
import { Form, Formik } from 'formik';
import {
  DEFAULT_CUSTOM_NOTIFICATION_EMAIL_SETTINGS,
  DEFAULT_STATIC_PAGE,
} from 'models/constants';
import { LdapFieldPermissionFieldEnum, LoadingStateEnum } from 'models/enums';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { RootState } from 'store/store';
import {
  DEFAULT_EVENT_TYPE,
  DEFAULT_EVENT_TYPE_OPTIONS,
  DEFAULT_LDAP_PERMISSION_ACTIONS_OPTIONS,
  DEFAULT_LDAP_PERMISSION_USER_GROUP_OPTIONS,
} from '../constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const EventForm = () => {
  const { loading, eventError, event, publicEventId } = useSelector(
    (state: RootState) => state.event,
  );
  const { loading: loadingTemplate, formTemplate } = useSelector(
    (state: RootState) => state.formTemplate,
  );
  const { user } = useSelector((state: RootState) => state);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { eventId } = useParams<Record<string, string>>();
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    if (eventError) {
      push(ROUTES.errorPage);
    }

    //to redirect after successful event submit
    if (isFormSubmit && event) {
      setIsFormSubmit(false);
      const id = decodeURIComponent(event.id || eventId);

      push(
        generatePath(
          eventId ? ROUTES.home.events.withId : ROUTES.home.events.actions.edit,
          {
            eventId: id,
          },
        ),
      );
    }
  }, [event, eventError, eventId, isFormSubmit, push]);

  const oldEventEvalNamesArray: any[] =
    eventId && event?.evalFormId ? event.evalFormId : [];

  const mainFormTemplateFieldOptions =
    eventId && event && formTemplate
      ? formTemplate.order?.map((fieldName) => ({
          value: fieldName,
          name: fieldName,
        })) ?? DEFAULT_FORM_FIELD_OPTIONS
      : DEFAULT_FORM_FIELD_OPTIONS;

  const notificationFields: NotificationField[] =
    eventId && event && formTemplate
      ? createArrayFromNotificationFieldsObject(event)
      : [];

  const ldapFieldPermission: LdapFieldPermissionField[] =
    eventId && event && formTemplate
      ? createArrayFromLdapFieldPermissionObject(event)
      : [];

  const staticLinks: StaticLink[] =
    eventId && event && event.staticLinks
      ? createStaticLinkArrFromStaticObjArr(event.staticLinks)
      : [];

  const viewSubmissionTabs: SubmissionTabField[] =
    eventId && event ? createSubmissionTabFieldArrFromObjArr(event) : [];

  if (!event || !eventId) return <Spinner />;

  const initialValues: EventFormValues = {
    id: eventId,
    title: event.title,
    description: event.description ?? '',
    type: event.type ?? DEFAULT_EVENT_TYPE.toLowerCase(),
    formId: event.formId ?? '',
    evalFormId: oldEventEvalNamesArray,
    loginDomain: event.loginDomain ?? '',
    archived: !!event.archived ?? false,
    hidden: !!event.hidden ?? false,
    commentNotifications: !!event.commentNotifications,
    commentReplyNotifications: !!event.commentReplyNotifications,
    anonymousSubmissions: !!event.anonymousSubmissions,
    anonymousComments: !!event.anonymousComments,
    stages: event.stages ?? [],
    staticPages: event.staticPages ?? [DEFAULT_STATIC_PAGE],
    staticLinks,
    notificationFields: notificationFields,
    reportingTemplateId: event.reportingTemplateId ?? '',
    highlightedField: event.highlightedField ?? '',
    hubEventIds: event.hubEventIds ?? [],
    featureFlags: filterKeysWithTrueValue(event.featureFlags),
    creatorManagerPermissions: event.creatorManagerPermissions
      ? filterKeysWithTrueValue(event.creatorManagerPermissions)
      : [],
    creatorPermissions: event.creatorPermissions
      ? filterKeysWithTrueValue(event.creatorPermissions)
      : [],
    ldapFieldPermission,
    viewSubmissionTabs,
    isUpdate: eventId ? true : false,
    issuer: event.issuer ?? '',
    customNotificationEmailSettings:
      event.customNotificationEmailSettings ??
      DEFAULT_CUSTOM_NOTIFICATION_EMAIL_SETTINGS,
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return loading === LoadingStateEnum.IDLE &&
    loadingTemplate === LoadingStateEnum.IDLE ? (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={eventSchema}
        onSubmit={(values, actions) =>
          onEventSubmit(
            values,
            actions,
            dispatch,
            eventId,
            oldEventEvalNamesArray,
            setIsFormSubmit,
          )
        }
      >
        {({
          values,
          errors,
          status,
          touched,
          resetForm,
          isSubmitting,
          isValid,
          dirty,
        }) =>
          isSubmitting ? (
            <Spinner />
          ) : (
            <Form className="pb-5">
              <h1 className="h4 rounded-pill bg-dark text-white px-3 py-1 mb-4 d-inline-block font-weight-light">
                Event Configuration
              </h1>

              <div className="max-800">
                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                  {eventId && (
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={tabIndex}
                      onChange={handleTabChange}
                      aria-label="Vertical tabs example"
                      sx={(theme) => ({
                        borderRight: `1px solid ${theme.palette.divider}`,
                        overflow: 'visible',
                        minWidth: '160px',
                      })}
                    >
                      <Tab label="Basic Details" {...a11yProps(0)} />
                      <Tab label="Custom Pages" {...a11yProps(1)} />
                      <Tab label="Workflow" {...a11yProps(2)} />
                      <Tab label="Permissions" {...a11yProps(3)} />
                      <Tab label="Reporting" {...a11yProps(4)} />
                      {user.isSuperAdmin && (
                        <Tab label="Admin Only" {...a11yProps(5)} />
                      )}
                    </Tabs>
                  )}
                  <TabPanel value={tabIndex} index={0}>
                    {eventId && (
                      <>
                        <FormInputField
                          fieldLabel="Event Title"
                          fieldName="title"
                          errors={errors.title}
                          touched={touched.title ?? false}
                          isRequired={true}
                        />
                        <FormTextareaField
                          fieldLabel="Event Description"
                          fieldName="description"
                          errors={errors.description ?? ''}
                          touched={touched.description ?? false}
                          rows="5"
                          isRequired={false}
                        />
                        <FormSelectField
                          fieldLabel="Event Type"
                          fieldName="type"
                          errors={errors.type ?? ''}
                          touched={touched.type ?? false}
                          options={DEFAULT_EVENT_TYPE_OPTIONS}
                          isRequired={false}
                        />

                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="basic-features"
                            id="basic-features"
                          >
                            <Box
                              sx={{ fontSize: '1rem' }}
                              className={`text-uppercase text-secondary font-weight-bold`}
                            >
                              Enable Features
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="row">
                              <div className="col">
                                <FormSingleCheckboxField
                                  fieldLabel="Submission card image"
                                  fieldName="featureFlags"
                                  fieldValue="submissionCardImages"
                                />
                                <FormSingleCheckboxField
                                  fieldLabel="Kanban board view"
                                  fieldName="featureFlags"
                                  fieldValue="kanbanView"
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="advanced-features"
                            id="advanced-features"
                          >
                            <Box
                              sx={{ fontSize: '1rem' }}
                              className={`text-uppercase text-secondary font-weight-bold`}
                            >
                              Advanced
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <FormSingleCheckboxField
                                    fieldLabel="InfoMeta (Sentiment)"
                                    fieldName="featureFlags"
                                    fieldValue="infoMeta"
                                  />
                                  <FormSingleCheckboxField
                                    fieldLabel="Idea Buildr"
                                    fieldName="featureFlags"
                                    fieldValue="ideaBuildr"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <FormSingleCheckboxField
                                    fieldLabel="Submission export"
                                    fieldName="featureFlags"
                                    fieldValue="exportDoc"
                                  />
                                  <FormSingleCheckboxField
                                    fieldLabel="Review widget"
                                    fieldName="featureFlags"
                                    fieldValue="reviewSection"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 mb-4">
                                  <h6 className="text-uppercase text-secondary font-weight-bold">
                                    Visibility
                                  </h6>

                                  {eventId && (
                                    <FormSingleCheckboxField
                                      fieldLabel="Archived"
                                      fieldName="archived"
                                    />
                                  )}
                                  <FormSingleCheckboxField
                                    fieldLabel="Hidden"
                                    fieldName="hidden"
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <h6 className="text-uppercase text-secondary font-weight-bold">
                                    Comments
                                  </h6>
                                  <FormSingleCheckboxField
                                    fieldLabel="Comment Notifications"
                                    fieldName="commentNotifications"
                                  />
                                  <FormSingleCheckboxField
                                    fieldLabel="Comment Reply Notifications"
                                    fieldName="commentReplyNotifications"
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <h6 className="text-uppercase text-secondary font-weight-bold">
                                    Anonymous
                                  </h6>
                                  <FormSingleCheckboxField
                                    fieldLabel="Anonymous Submissions"
                                    fieldName="anonymousSubmissions"
                                  />
                                  <FormSingleCheckboxField
                                    fieldLabel="Anonymous Comments"
                                    fieldName="anonymousComments"
                                  />
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Custom Pages
                    </h6>

                    <FormArrayField
                      fieldLabel="Static pages"
                      fieldName="staticPages"
                      errors={errors.staticPages ?? ''}
                      touched={touched.staticPages ?? false}
                      fieldArr={values.staticPages}
                      btnLabel="Add a static page"
                      placeholder="static page name"
                    />
                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Custom Navigation Links
                    </h6>

                    <FormArrayField
                      fieldLabel="Static links"
                      fieldName="staticLinks"
                      errors={errors.staticLinks ?? ''}
                      touched={touched.staticLinks ?? false}
                      fieldArr={values.staticLinks}
                      btnLabel="Add a static link"
                      placeholder=""
                      fieldDataArray={[
                        { name: 'tabName', placeholder: 'Tab Name' },
                        { name: 'path', placeholder: 'Path' },
                        { name: 'target', placeholder: 'Target' },
                      ]}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={2}>
                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Stages
                    </h6>
                    <FormArrayField
                      fieldLabel="Stages"
                      fieldName="stages"
                      errors={errors.stages ?? ''}
                      touched={touched.stages ?? false}
                      fieldArr={values.stages}
                      btnLabel="Add stage"
                      placeholder=""
                      withControls={true}
                      fieldDataArray={[
                        { name: 'name', placeholder: 'Stage name...' },
                      ]}
                    />
                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Evaluations
                    </h6>

                    <FormArrayField
                      fieldLabel="Evaluation Forms"
                      fieldName="evalFormId"
                      errors={errors.evalFormId ?? ''}
                      touched={touched.evalFormId ?? false}
                      fieldArr={values.evalFormId}
                      btnLabel="Add evaluation"
                      placeholder=""
                      withControls={true}
                      fieldDataArray={[
                        {
                          name: 'evalForm',
                          placeholder: 'Evaluation form name...',
                        },
                        {
                          name: 'formId',
                          placeholder: 'Form id',
                          hidden: true,
                        },
                      ]}
                    />
                    {errors.evalFormId && (
                      <div className="text-danger">{errors.evalFormId}</div>
                    )}

                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Submission tabs
                    </h6>
                    <FormArrayField
                      fieldLabel="View Submission Tabs"
                      fieldName="viewSubmissionTabs"
                      fieldArr={values.viewSubmissionTabs}
                      btnLabel="Add a submission tab"
                      placeholder=""
                      fieldDataArray={[
                        { name: 'tabName', placeholder: 'Tab Name' },
                        { name: 'forms', placeholder: 'Forms' },
                      ]}
                    />
                    {errors.viewSubmissionTabs && (
                      <div className="text-danger">
                        {errors.viewSubmissionTabs}
                      </div>
                    )}

                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Notification Fields
                    </h6>

                    {eventId && (
                      <FormArrayField
                        fieldLabel="Notification fields"
                        fieldName="notificationFields"
                        errors={errors.notificationFields ?? ''}
                        touched={touched.notificationFields ?? false}
                        fieldArr={values.notificationFields}
                        btnLabel="Add a notification field"
                        placeholder=""
                        fieldDataArray={[
                          {
                            name: 'mainFormTemplateFieldName',
                            placeholder: 'Main form field name',
                            options: mainFormTemplateFieldOptions,
                          },
                          {
                            name: 'mainFormTemplateFieldValue',
                            placeholder: 'Main form field value',
                          },
                          { name: 'id', placeholder: 'Id' },
                          {
                            name: 'type',
                            placeholder: 'Type',
                            options: DEFAULT_FORM_FIELD_TYPE_OPTIONS,
                          },
                        ]}
                      />
                    )}

                    <FormSelectField
                      fieldLabel="Highlighted Field"
                      fieldName="highlightedField"
                      errors={errors.highlightedField ?? ''}
                      touched={touched.highlightedField ?? false}
                      options={mainFormTemplateFieldOptions}
                      isRequired={false}
                    />

                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Custom notification email settings
                    </h6>

                    <FormInputField
                      fieldLabel="From"
                      fieldName="customNotificationEmailSettings.Source"
                      errors={errors.customNotificationEmailSettings?.Source}
                      touched={touched.customNotificationEmailSettings ?? false}
                      isRequired={false}
                    />

                    <FormArrayField
                      fieldLabel="Reply to addresses"
                      fieldName="customNotificationEmailSettings.ReplyToAddresses"
                      errors={
                        errors.customNotificationEmailSettings?.ReplyToAddresses
                      }
                      touched={
                        touched.customNotificationEmailSettings
                          ?.ReplyToAddresses ?? false
                      }
                      fieldArr={
                        values.customNotificationEmailSettings?.ReplyToAddresses
                      }
                      btnLabel="Add reply to address"
                      placeholder="Reply to"
                    />
                    <Box className="invalid-feedback d-block" sx={{ m: 1 }}>
                      {touched.customNotificationEmailSettings &&
                        errors.customNotificationEmailSettings &&
                        typeof errors.customNotificationEmailSettings ===
                          'string' && (
                          <div>{errors.customNotificationEmailSettings}</div>
                        )}
                    </Box>
                  </TabPanel>

                  <TabPanel value={tabIndex} index={3}>
                    <FormInputField
                      fieldLabel="Login Domain"
                      fieldName="loginDomain"
                      errors={errors.loginDomain}
                      touched={touched.loginDomain ?? false}
                      isRequired={false}
                    />
                    <h6 className="text-uppercase text-secondary font-weight-bold">
                      Person Field Permissions
                    </h6>

                    <FormArrayField
                      fieldLabel=""
                      fieldName="ldapFieldPermission"
                      errors={errors.ldapFieldPermission ?? ''}
                      touched={touched.ldapFieldPermission ?? false}
                      fieldArr={values.ldapFieldPermission}
                      btnLabel="Add a field"
                      placeholder=""
                      fieldDataArray={[
                        {
                          name: LdapFieldPermissionFieldEnum.LDAP_FIELD_NAME,
                          placeholder: '',
                          options: mainFormTemplateFieldOptions,
                        },
                        {
                          name: LdapFieldPermissionFieldEnum.LDAP_USER_GROUP,
                          placeholder: '',
                          options: DEFAULT_LDAP_PERMISSION_USER_GROUP_OPTIONS,
                        },
                        {
                          name: LdapFieldPermissionFieldEnum.LDAP_ACTIONS,
                          placeholder: '',
                          options: DEFAULT_LDAP_PERMISSION_ACTIONS_OPTIONS,
                          multiple: true,
                        },
                      ]}
                    />
                    {errors.ldapFieldPermission && (
                      <div className="text-danger">
                        {errors.ldapFieldPermission}
                      </div>
                    )}

                    <div className="row mb-4">
                      <div className="col-md-6 mb-3">
                        <h6 className="text-uppercase text-secondary font-weight-bold">
                          Submission Creator
                        </h6>
                        <FormSingleCheckboxField
                          fieldLabel="Submit all evaluations"
                          fieldName="creatorPermissions"
                          fieldValue="submitEvals"
                        />
                        <FormSingleCheckboxField
                          fieldLabel="View all evaluations"
                          fieldName="creatorPermissions"
                          fieldValue="viewAllEvals"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <h6 className="text-uppercase text-secondary font-weight-bold">
                          {"Creator's Manager"}
                        </h6>
                        <FormSingleCheckboxField
                          fieldLabel="Submit all evaluations"
                          fieldName="creatorManagerPermissions"
                          fieldValue="submitEvals"
                        />
                        <FormSingleCheckboxField
                          fieldLabel="View all evaluations"
                          fieldName="creatorManagerPermissions"
                          fieldValue="viewAllEvals"
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={4}>
                    <FormInputField
                      fieldLabel="Reporting Template Id"
                      fieldName="reportingTemplateId"
                      errors={errors.reportingTemplateId}
                      touched={touched.reportingTemplateId ?? false}
                      isRequired={false}
                    />

                    <FormArrayField
                      fieldLabel="Hub Event Ids"
                      fieldName="hubEventIds"
                      errors={errors.hubEventIds ?? ''}
                      touched={touched.hubEventIds ?? false}
                      fieldArr={values.hubEventIds}
                      btnLabel="Add a hub event id"
                      placeholder="hub Event Id"
                    />
                  </TabPanel>
                  {user.isSuperAdmin && (
                    <TabPanel value={tabIndex} index={5}>
                      {createIssuerField(errors, touched)}
                      <FormSingleCheckboxField
                        fieldLabel="Enable Public Submit app"
                        fieldName="featureFlags"
                        fieldValue="enablePublicSubmitApp"
                      />
                      <div>Public Event Id {publicEventId}</div>
                    </TabPanel>
                  )}
                </Box>
                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    className="mr-3"
                    type="button"
                    onClick={() => {
                      resetForm({
                        values: initialValues,
                      });
                      //goBack();
                    }}
                  >
                    Undo Changes
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className=""
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </>
  ) : (
    <Spinner />
  );
};
