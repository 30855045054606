import { TemplateEditorTab } from 'app/home/adminTools/LoginPages/Models/TemplateEditorTab.enum';

export const DEFAULT_PAGE_LIMIT = 100;
export const DEFAULT_TOAST_AUTO_CLOSE = 5000;

export const DEFAULT_STATIC_PAGE = 'Overview';
export const DEFAULT_USER_ROLE = 'owner';
export const DEFAULT_ADMIN_EVENT_ROLE = 'ignite_admin';
export const DEFAULT_ISSUER = 'GENERAL';
export const SSO_ISSUER = 'SSO';
export const DEFAULT_ATTACHMENTS_FOLDER = 'files';

export const MAIN_DASHBOARD_EVENTS_SECTION_NAME = 'Event Management';
export const MAIN_DASHBOARD_USERS_SECTION_NAME = 'Users';
export const ADMIN_TOOLS_NAME = 'Admin tools';

export const EVENT_INFO_SECTION_NAME = 'Event Configuration';
export const EVENT_USER_ACCESS_SECTION_NAME = 'User Access';
export const EVENT_FORMS_SECTION_NAME = 'Form Templates';
export const EVENT_STATIC_PAGES_SECTION_NAME = 'Static Pages';
export const EVENT_VOTING_ROUNDS_SECTION_NAME = 'Voting Rounds';

export const DEFAULT_SEPARATOR = '\n';
// export const DEFAULT_SEPARATOR = /[\s,]+/;
export const DEFAULT_ITEM_SEPARATOR = ',';

export const DEFAULT_CUSTOM_NOTIFICATION_EMAIL_SETTINGS = {
  ReplyToAddresses: [],
  Source: '',
};

export const TEMPLATES_FOLDER = `__templates`;
export const DEFAULT_TEMPLATE_ID = `__DEFAULT`;

export const TEMPLATE_FILE_NAMES = {
  HTML: TemplateEditorTab.HTML as const,
  CSS: TemplateEditorTab.CSS as const,
  DOMAIN_SETTINGS: 'domainSettings.json' as const,
} as const;
