export const isFolder = (path: string) => {
  return path.endsWith('/');
};

export const getCurrentCode = (editorRef: React.MutableRefObject<null>) => {
  if (!editorRef.current) return;
  const code = (editorRef.current as any).editor?.getValue();
  // skip if the editor is empty
  if (typeof code !== 'string') {
    return;
  }

  return code;
};
