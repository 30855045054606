import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'routes';
import { AdminHome } from 'app/home/AdminHome';
import PublicRoutes from './PublicRoutes';

function PrivateRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={`${ROUTES.home.root}`} />
      </Route>
      <Route path={`${ROUTES.home.root}`}>
        <AdminHome />
      </Route>
      <PublicRoutes />
    </Switch>
  );
}

export default PrivateRoutes;
