export enum EventTypeEnum {
  CHALLENGE = 'Challenge',
  NOMINATION = 'Nomination',
  FORUM = 'Forum',
}

export enum EventFormTypeEnum {
  UPDATE = 'Update',
  CREATE = 'Create',
}

export enum EventRoleTypeEnum {
  DOMAIN = 'domain',
  CUSTOM = 'custom',
  OWNER = 'owner',
  ADMIN = 'admin',
  ISSUER = 'issuer',
}

export enum UpdateEventModeEnum {
  VOTING = 'voting',
  ACCESS = 'access',
  UPDATE = 'update',
}
