import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

interface IProps {
  roles: string[];
  onSelectionChange?: (items: string[]) => void;
}

export const RoleList = ({ roles, onSelectionChange }: IProps) => {
  const [value, setValue] = useState<string[]>([]);

  /*
   * The second argument that will be passed to
   * `handleChange` from `ToggleButtonGroup`
   * is the SyntheticEvent object, but we are
   * not using it in this example so we will omit it.
   */
  const handleChange = (val: string[]) => setValue(val);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(value);
    }
  }, [value, onSelectionChange]);

  return (
    <ToggleButtonGroup
      type="checkbox"
      className="flex-column"
      value={value}
      onChange={handleChange}
    >
      {roles.map((role) => (
        <ToggleButton
          variant="outline-primary"
          className="mb-2"
          value={role}
          key={role}
        >
          {role}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
