import { UpdateEventResponse } from './models/response/UpdateEventsResponse';
import { CreateEventResponse } from './models/response/CreateEventResponse';
import {
  AccessEventDTO,
  UpdateEventDTO,
  VotingEventDTO,
} from 'features/event/models/EventDTO';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { EventItem, EventReadRoleItem } from './models';
import { MethodEnum } from 'models/enums';
import { getApiRoutes } from './routes';
import { axiosClient, axiosCustom } from 'api/axios';
import { CreateEventDTO } from 'features/event/models';

export const fetchEventById = async (eventId: string) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: `${api.events}/${eventId}`,
  };
  const response = await axios(request);

  return response;
};

export const fetchPublicEventById = async (eventId: string) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: `${api.events}/${eventId}/publicEventId`,
  };
  const response = await axios(request);

  return response;
};

export const fetchEventsList = async () => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: api.events,
  };
  const response: AxiosResponse<EventItem[]> = await axios(request);

  return response;
};

export const createEvent = async (event: CreateEventDTO) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: api.events,
    data: event,
  };

  const response: AxiosResponse<CreateEventResponse> = await axiosClient(
    request,
  );

  return response;
};

export const updateEvent = async (
  event: UpdateEventDTO | VotingEventDTO | AccessEventDTO,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.PUT,
    url: `${api.events}/${event.id}`,
    data: event,
  };

  const response: AxiosResponse<UpdateEventResponse> = await axiosClient(
    request,
  );

  return response;
};

export const createEventReadRoles = async (
  eventReadRoleData: EventReadRoleItem,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: `${api.events}/${eventReadRoleData.eventId}/${api.eventReadRoles}`,
    data: { roleName: eventReadRoleData.roleName },
  };

  const response = await axiosClient(request);

  return response;
};

export const deleteEventReadRoles = async (
  eventReadRoleData: EventReadRoleItem,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.DELETE,
    url: `${api.events}/${eventReadRoleData.eventId}/${api.eventReadRoles}/${eventReadRoleData.roleName}`,
  };

  const response = await axiosClient(request);

  return response;
};

export const fetchEventReadRolesByEventId = async (eventId: string) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: `${api.events}/${eventId}/${api.eventReadRoles}`,
  };
  const response = await axios(request);

  return response;
};

export const uploadFileToS3 = async (url: string, file: File) => {
  const request: AxiosRequestConfig = {
    method: MethodEnum.PUT,
    url,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      // const loaded = (100 * progressEvent.loaded) / progressEvent.total;
      // Do something with the progress details
    },
  };
  // console.log('request uploadFileToS3', request);
  const response = await axiosCustom(request);

  return response;
};

export const deleteFileFromS3 = async (
  contentType: string,
  s3key: string,
  eventId: string,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.DELETE,
    url: `${api.events}/${eventId}/staticPages`,
    params: { s3key },
  };
  const response = await axiosClient(request);

  return response;
};

export const renameFileFromS3 = async (
  contentType: string,
  oldS3Key: string,
  newS3Key: string,
  eventId: string,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: `${api.events}/${eventId}/staticPages`,
    params: { oldS3Key, newS3Key },
  };
  const response = await axiosClient(request);

  return response;
};

export const listFilesFromS3 = async (s3key: string, eventId: string) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: `${api.events}/${eventId}/staticPages`,
    params: { s3key },
  };
  const response = await axios(request);

  return response;
};
