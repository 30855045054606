import { EventFormValues } from 'features/event/models';
import { createIssuerField } from 'features/event/util/createIssuerField';
import { FormikErrors, FormikTouched } from 'formik';
import { useMemo } from 'react';

export const useIssuerField = (
  errors: FormikErrors<EventFormValues>,
  touched: FormikTouched<EventFormValues>,
) => {
  return useMemo(() => createIssuerField(errors, touched), [errors, touched]);
};
