import * as Yup from 'yup';

const idRegex = /^[A-Za-z0-9]+[A-Za-z0-9_-]*$/;
const suffixRegex = /^[A-Za-z0-9]+[A-Za-z0-9_]*$/;

const createEventSchema = Yup.object().shape({
  id: Yup.string()
    .required('SiteID is required')
    .matches(
      idRegex,
      'Must start with letters or numbers and contain only letters, numbers, underscore or dash',
    ),
  suffix: Yup.string().when('skipSuffixGeneration', {
    is: true,
    then: Yup.string()
      .required('Site suffix is required')
      .matches(
        suffixRegex,
        'Must start with letters or numbers and contain only letters, numbers or underscore',
      ),
  }),
  title: Yup.string().required('Title is required').max(50),
  skipSuffixGeneration: Yup.boolean().required(),
});

export { createEventSchema };
