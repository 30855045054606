import { EventsMain } from 'app/events/EventsMain';
import { AdminTools } from 'app/home/adminTools/AdminTools';
import { PrivateRoute } from 'app/PrivateRoute';
import { CreateEventForm } from 'features/event/components/CreateEventForm/CreateEventForm';
import EventContainer from 'features/event/components/EventContainer';
import { EventNavbar } from 'features/event/components/EventNavbar';
import { CreateUser } from 'features/user/components/CreateUser/CreateUser';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes';
import { RootState } from 'store/store';
import { Sidebar } from './Sidebar';
import { AdminNavBar } from './adminTools/AdminNavBar';
/**
 * Container and routing for the admin dashboard section of the app
 */
export const AdminHome = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <main role="main" id="content-wrapper" className="d-flex flex-column">
          <PrivateRoute path={`${ROUTES.home.events.withId}`}>
            <EventNavbar />
          </PrivateRoute>
          <PrivateRoute path={`${ROUTES.home.adminTools.root}`}>
            <AdminNavBar />
          </PrivateRoute>

          <div id="content" className="container-fluid p-lg-4 p-3">
            <Switch>
              <PrivateRoute
                exact
                path={`${ROUTES.home.createEvent}`}
                component={CreateEventForm}
              />
              <PrivateRoute
                path={`${ROUTES.home.events.withId}`}
                component={EventContainer}
              />
              <PrivateRoute path={`${ROUTES.home.events.root}`}>
                <EventsMain />
              </PrivateRoute>

              <PrivateRoute
                exact
                path={`${ROUTES.home.createUser}`}
                component={CreateUser}
              />
              {/* <PrivateRoute
                path={`${ROUTES.home.adminTools.adminPermissions.root}`}
              >
                <UserRouting />
              </PrivateRoute> */}

              {user.isSuperAdmin && (
                <PrivateRoute path={`${ROUTES.home.adminTools.root}`}>
                  <AdminTools />
                </PrivateRoute>
              )}

              <PrivateRoute path={`${ROUTES.home.events.root}`}>
                <EventsMain />
              </PrivateRoute>

              <Route exact path={`${ROUTES.home.root}`}>
                <Redirect to={`${ROUTES.home.events.root}`} />
              </Route>
            </Switch>
          </div>
        </main>
      </div>
    </>
  );
};
