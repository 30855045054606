import React from 'react';
import { Badge } from 'react-bootstrap';

export const rolesRenderer = (roles: string[]) => {
  return (
    <div>
      {roles.map((role) => (
        <Badge variant="primary" key={role}>
          {/* Remove the '#{eventId}' from the role */}
          {role.replace(/#.*$/i, '')}
        </Badge>
      ))}
    </div>
  );
};
