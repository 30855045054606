import { SelectOptions } from 'components-lib';

export function createSelectOptionsFromStringArray(
  arr: string[],
): SelectOptions[] {
  return arr.map((fieldName: string) => ({
    value: fieldName,
    name: fieldName,
  }));
}
