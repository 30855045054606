import { moveObject } from 'api/staticFiles';
import { LoadingStateEnum } from 'models/enums';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, ModalProps } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { IRouteParams } from 'routes/models/IRouteParams';
import { ROOT_PREFIX } from '../constants';
import Spinner from 'components-lib/Spinner';

interface IProps extends ModalProps {
  folderPrefix: string;
  onSuccess?: Function;
}

// TODO: add validation
export const RenameModal = ({
  onSuccess,
  objectToRename,
  folderPrefix,
}: IProps) => {
  // const { objectToRename, folderPrefix } = props;
  const [isOpen, setOpen] = useState(true);
  const [input, setInput] = useState('');
  const [renamingStatus, setRenamingStatus] = useState(LoadingStateEnum.IDLE);
  const { eventId } = useParams<IRouteParams>();

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInput(objectToRename.name);
    ref.current?.focus();
  }, [objectToRename]);

  const closeModal = () => {
    setOpen(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === 'Enter') {
      handleMoveObject();
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInput(ev.target.value);
  };

  const handleMoveObject = () => {
    if (ref.current?.value && ref.current?.value !== objectToRename.name) {
      setRenamingStatus(LoadingStateEnum.PENDING);
      const suffix = objectToRename.isDir === true ? '/' : '';
      const folderPath = folderPrefix === ROOT_PREFIX ? '' : folderPrefix;
      const sourcePath = folderPath + objectToRename.name + suffix;
      const destinationPath = folderPath + ref.current.value + suffix;
      moveObject(eventId, sourcePath, destinationPath)
        .then(() => {
          setRenamingStatus(LoadingStateEnum.COMPLETE);
          if (onSuccess) onSuccess();
        })
        .catch(() => {
          setRenamingStatus(LoadingStateEnum.ERROR);
        });
    }
  };

  const buttonsDisabled = renamingStatus === LoadingStateEnum.PENDING;

  return (
    <>
      <Modal backdrop="static" show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton={!buttonsDisabled}>
          <Modal.Title>
            Rename {objectToRename.isDir ? 'Folder' : 'File'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            name="folderName"
            id="folderName"
            value={input}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            ref={ref}
          />
        </Modal.Body>

        <Modal.Footer>
          {renamingStatus === LoadingStateEnum.PENDING && <Spinner />}
          <Button
            variant="primary"
            onClick={handleMoveObject}
            disabled={buttonsDisabled}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={buttonsDisabled}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenameModal;
