import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { EventRoleTypeEnum } from 'models/enums';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useCreateRole } from '../hooks/useCreateRole';
import { useRoleCards } from '../hooks/useRoleCards';
import { RoleFormModalDialog } from '../Modals/RoleFormModalDialog';

interface IProps {
  allowedDomains: string[];
}

const DomainRoles = ({ allowedDomains }: IProps) => {
  const { event: eventState } = useSelector((state: RootState) => state);
  const { domainRoleCards } = useRoleCards(eventState);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);
  const newRole = useCreateRole(EventRoleTypeEnum.DOMAIN, ``);

  if (!eventState.event?.id) return <>No event loaded</>;

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid item lg={3}>
        <Stack direction="row">
          <h3>Domain Roles</h3>
          <Tooltip
            title={
              <>
                <Typography>Allowed domains:</Typography>
                {allowedDomains.map((issuer, index) => (
                  <Typography key={index}>{issuer}</Typography>
                ))}
              </>
            }
          >
            <InfoIcon fontSize="inherit" />
          </Tooltip>
        </Stack>
        <p>Provide access to users based on their email domain.</p>
        <p className="rounded-pill bg-white p-1 text-center">
          <span className="small text-primary font-weight-bold mr-2">
            EXAMPLE:
          </span>
          <span className="font-italic">helloignite.com</span>
        </p>
      </Grid>

      <Grid item lg={9}>
        <Button
          onClick={() => {
            setShowCreateRoleDialog(true);
          }}
          variant="contained"
        >
          Create domain role
        </Button>
        <Grid container>
          {domainRoleCards.map((card, index) => (
            <Grid key={index} item xl={4} md={6} sm={12} sx={{ p: 0.5 }}>
              {card}
            </Grid>
          ))}
        </Grid>
      </Grid>

      {showCreateRoleDialog && (
        <RoleFormModalDialog
          eventId={eventState.event.id}
          eventRole={newRole}
          open={showCreateRoleDialog}
          onClose={() => {
            setShowCreateRoleDialog(false);
          }}
          onSuccess={() => {
            setShowCreateRoleDialog(false);
          }}
        />
      )}
    </Grid>
  );
};

export default DomainRoles;
