import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'uniqueName', function (message, mapper = (a) => a) {
  return this.test('uniqueName', message, function (list) {
    const filteredList = list.filter(Boolean);
    return filteredList.length === new Set(filteredList.map(mapper)).size;
  });
});

Yup.addMethod(
  Yup.array,
  'uniqueNotificationField',
  function (message, mapper = (a) => a) {
    return this.test('uniqueNotificationField', message, function (list) {
      const fieldNameWithValues = {};
      let isDuplicated = false;
      for (let index = 0; index < list.length; index++) {
        const notificationField = list[index];

        const oldValueArray =
          fieldNameWithValues[notificationField.mainFormTemplateFieldName] ??
          [];

        isDuplicated = oldValueArray.some(
          (e) => e === notificationField.mainFormTemplateFieldValue,
        );

        if (!isDuplicated) {
          fieldNameWithValues[notificationField.mainFormTemplateFieldName] = [
            ...oldValueArray,
            notificationField.mainFormTemplateFieldValue,
          ];
        } else {
          break;
        }
      }

      return !isDuplicated;
    });
  },
);

Yup.addMethod(Yup.array, 'emptyName', function (message, mapper = (a) => a) {
  return this.test('emptyName', message, function (list) {
    if (list.some((item) => !item || !item.evalForm)) return false;
    return true;
  });
});

/** require both parameters if one is specified */
Yup.addMethod(
  Yup.object,
  'customNotificationEmailSettings',
  function (message, mapper = (a) => a) {
    return this.test(
      'customNotificationEmailSettings',
      message,
      function (object) {
        console.log(object);
        if (object.Source && !object.ReplyToAddresses?.length) return false;
        if (object.ReplyToAddresses?.length) {
          if (!object.Source) return false;
          // ensure all inputs in the list have a value
          if (!object.ReplyToAddresses?.every((replyToEmail) => replyToEmail))
            return false;
        }
        return true;
      },
    );
  },
);

Yup.addMethod(
  Yup.object,
  'requireSourceField',
  function (message, mapper = (a) => a) {
    return this.test('requireSourceField', message, function (object) {
      if (object.ReplyToAddresses?.length && !object.Source) return false;

      return true;
    });
  },
);

Yup.addMethod(
  Yup.object,
  'requireReplyToAddressesField',
  function (message, mapper = (a) => a) {
    return this.test(
      'requireReplyToAddressesField',
      message,
      function (object) {
        if (object.Source && !object.ReplyToAddresses?.length) return false;

        return true;
      },
    );
  },
);

Yup.addMethod(
  Yup.object,
  'requireAllToBeFilled',
  function (message, mapper = (a) => a) {
    return this.test('requireSourceField', message, function (object) {
      if (!object.ReplyToAddresses?.every((replyToEmail) => replyToEmail))
        return false;

      return true;
    });
  },
);

Yup.addMethod(
  Yup.array,
  'requiredNotificationFieldData',
  function (message, mapper = (a) => a) {
    return this.test('requiredNotificationFieldData', message, function (list) {
      const filteredList = list.filter(
        (el) =>
          el.mainFormTemplateFieldName !== undefined &&
          el.mainFormTemplateFieldValue !== undefined &&
          el.id !== undefined &&
          el.type !== undefined &&
          el.mainFormTemplateFieldName.trim().length !== 0 &&
          el.mainFormTemplateFieldValue.trim().length !== 0 &&
          el.id.trim().length !== 0 &&
          el.type.trim().length !== 0,
      );
      return filteredList.length === list.length;
    });
  },
);

const eventSchema = Yup.object().shape({
  isUpdate: Yup.boolean(),
  id: Yup.string()
    .required('SiteID is required')
    .matches(
      /^[A-Za-z][A-Za-z0-9_-]+$/,
      'Must start with letter and contain only letters, numbers, underscore or dash',
    ),
  title: Yup.string().required('Title is required').max(50),
  loginDomain: Yup.string().email('Invalid email'),
  evalFormId: Yup.array()
    .of(
      Yup.object().shape({
        evalForm: Yup.string(),
        formId: Yup.string().default(''),
      }),
    )
    .uniqueName('Duplicate eval form name', (a) => a.evalForm)
    .emptyName('No empty string for eval form name', (a) => a.evalForm),
  notificationFields: Yup.array().when('isUpdate', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          mainFormTemplateFieldName: Yup.string(),
          mainFormTemplateFieldValue: Yup.string(),
          id: Yup.string(),
          type: Yup.string(),
        }),
      )
      .uniqueNotificationField(
        'Duplicate notification field name value',
        (a) => a,
      )
      .requiredNotificationFieldData(
        'Please add data to all notification field inputs',
        (a) => a,
      ),
  }),
  customNotificationEmailSettings: Yup.object().when(['isUpdate'], {
    is: true,
    then: Yup.object()
      .shape({
        Source: Yup.string().email('Invalid email'),
        ReplyToAddresses: Yup.array()
          .of(Yup.string().email('Invalid email'))
          .uniqueName('No duplicates allowed', (a) => a),
      })
      .requireSourceField(`You need to fill the 'From' field`)
      .requireReplyToAddressesField('You need to at least one reply to address')
      .requireAllToBeFilled(
        'All reply to fields must be valid e-mail addresses',
      ),
  }),
});

export { eventSchema };
