import { axiosCustom } from 'api';
import { isFolder } from 'app/home/adminTools/util';
import { AxiosRequestConfig } from 'axios';
import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import { TEMPLATES_FOLDER, TEMPLATE_FILE_NAMES } from 'models/constants';
import { TemplateType } from 'models/TemplateType';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

export interface DomainProperties {
  title: string;
  faviconURL: string;
}

export const useLoadDomainSettings = (selectedNode: string) => {
  const [domainSettings, setDomainSettings] = useState<
    DomainProperties | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);

  const { mainAppURL } = getConfigFromSessionStorage();

  const loadDomainSettings = useCallback(() => {
    if (!selectedNode || isFolder(selectedNode)) return;

    (async () => {
      try {
        setLoading(true);
        const url = `${mainAppURL}/public/${TemplateType.CustomDomains}/${TEMPLATES_FOLDER}/${selectedNode}/${TEMPLATE_FILE_NAMES.DOMAIN_SETTINGS}`;
        const request: AxiosRequestConfig = {
          method: 'GET',
          url,

          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'text/plain',
            Pragma: 'no-cache',
            Expires: '0',
          },
        };

        const { data } = await axiosCustom(request);

        // TODO: validate data format
        setDomainSettings(data);
      } catch (e) {
        toast.error(`Unable to load domain settings`);
        setDomainSettings(undefined);
      } finally {
        setLoading(false);
      }
    })();
  }, [mainAppURL, selectedNode]);

  return { domainSettings, loadDomainSettings, loading };
};
