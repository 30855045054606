import { Button, CircularProgress, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { FormInputField } from 'components-lib';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { ROUTES } from 'routes';
import { RootState } from 'store/store';
import { useIssuerField } from '../hooks/useIssuerField';
import { CreateEventFormValues } from './models/CreateEventFormValues';
import { generatePreviewText } from './util/generatePreviewText';
import { handleCreateEventSubmit } from './util/handleCreateEventSubmit';
import { createEventSchema } from './validations/createEventSchema';

const initialValues: CreateEventFormValues = {
  id: '',
  suffix: '',
  title: '',
  skipSuffixGeneration: false,
  issuer: '',
};

export const CreateEventForm = () => {
  const { push } = useHistory();
  const handleSubmit = async (values: CreateEventFormValues) => {
    const response = await handleCreateEventSubmit(values);

    if (response.status === 200) {
      const path = generatePath(ROUTES.home.events.actions.edit, {
        eventId: response.data.id,
      });

      push(path);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createEventSchema}
      onSubmit={handleSubmit}
    >
      <FormComponents />
    </Formik>
  );
};

const FormComponents = () => {
  const user = useSelector((state: RootState) => state.user);
  const userIssuer = user.user?.issuer;
  const form = useFormikContext<CreateEventFormValues>();
  const {
    values,
    errors,
    touched,
    resetForm,
    isValid,
    dirty,
    isSubmitting,
  } = form;
  const [
    suffixCheckboxField,
    metaSuffixCheckboxField,
  ] = useField<CreateEventFormValues>('skipSuffixGeneration');
  const [idField, metaIdField] = useField<CreateEventFormValues>('id');
  const [f2, meta2] = useField<CreateEventFormValues>('suffix');

  const issuerField = useIssuerField(errors, touched);

  return (
    <Box sx={{ maxWidth: '800px' }}>
      <Form>
        <h1 className="h4 rounded-pill bg-dark text-white px-3 py-1 mb-4 d-inline-block font-weight-light">
          Create Event
        </h1>
        <Box>
          <CheckboxWithLabel
            field={suffixCheckboxField}
            meta={metaSuffixCheckboxField}
            form={form}
            Label={{ label: 'Create custom suffix' }}
          />
        </Box>
        <Stack direction="row">
          <TextField
            sx={{ my: 2 }}
            label="Site ID"
            fullWidth
            className={
              form.errors.id && form.touched.id
                ? ' is-invalid bg-white'
                : 'bg-white'
            }
            type={'text'}
            variant="outlined"
            field={idField}
            meta={metaIdField}
            form={form}
          />

          {values.skipSuffixGeneration && (
            <>
              <Box sx={{ px: 1, pt: 2, my: 2 }}>-</Box>
              <TextField
                sx={{ my: 2 }}
                label="suffix"
                fullWidth
                className={
                  errors.suffix && touched.suffix
                    ? ' is-invalid bg-white'
                    : 'bg-white'
                }
                autoComplete="off"
                type={'text'}
                variant="outlined"
                field={f2}
                meta={meta2}
                form={form}
              />
            </>
          )}
        </Stack>
        <Box>
          <p className="rounded-pill bg-white p-1">
            <span className="small text-primary font-weight-bold mr-2">
              Site URL Preview:
            </span>
            {values.id && (
              <span className="font-italic">
                {generatePreviewText(values, userIssuer)}
              </span>
            )}
          </p>
        </Box>
        <FormInputField
          fieldLabel="Event Title"
          fieldName="title"
          errors={errors.title}
          touched={touched.title ?? false}
          isRequired={true}
        />

        {user.isSuperAdmin && <Box>{issuerField}</Box>}

        <Box className="d-flex mt-4 justify-content-end">
          <Button
            className="mr-3"
            type="button"
            onClick={() => {
              resetForm({
                values: initialValues,
              });
            }}
          >
            Undo Changes
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className=""
            disabled={!(isValid && dirty) || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="secondary" size={'1.5rem'} />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
