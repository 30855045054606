import { axiosClient, getApiRoutes } from 'api';
import axios, { AxiosRequestConfig } from 'axios';
import { LoginPageTemplateDTO } from './models/LoginPageTemplateDTO';

export const getLoginPageTemplateMap = async (templateId: string) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `${api.loginPageTemplates}`,
    params: {
      templateId,
    },
  };
  const response = await axios(request);

  return response;
};

export const putLoginPageTemplateMap = async ({
  templateId,
  domain,
  eventId,
  eventIssuer,
}: LoginPageTemplateDTO) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: 'PUT',
    url: `${api.loginPageTemplates}`,
    data: {
      templateId,
      domain,
      eventId,
      eventIssuer,
    },
  };
  const response = await axiosClient(request);

  return response;
};

export const deleteLoginPageTemplateMap = async ({
  templateId,
  domain,
  eventId,
  eventIssuer,
}: LoginPageTemplateDTO) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${api.loginPageTemplates}`,
    data: {
      templateId,
      domain,
      eventId,
      eventIssuer,
    },
  };
  const response = await axiosClient(request);

  return response;
};
