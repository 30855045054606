import { Button } from '@mui/material';
import { Box } from '@mui/system';
import Spinner from 'components-lib/Spinner';
import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHandleSaveDomainSettings } from './hooks/useHandleSaveDomainSettings';
import {
  DomainProperties,
  useLoadDomainSettings,
} from './hooks/useLoadDomainSettings';
import { useUploadFavicon } from './hooks/useUploadFavicon';

type Inputs = {
  title?: string;
};

interface IProps {
  selectedNode: string;
}

export const DomainSettingsForm = ({ selectedNode }: IProps) => {
  const [reloadTrigger, setReloadTrigger] = useState(false);

  const triggerReload = () => setReloadTrigger(!reloadTrigger);

  const { register, handleSubmit } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    let title = data.title || '';

    if (domainSettings) {
      await saveDomainSettings({ ...domainSettings, title });
    }
  };

  const [selectedFavicon, setSelectedFavicon] = useState<string | undefined>(
    undefined,
  );
  const faviconFileRef = useRef<HTMLInputElement>(null);

  const { mainAppURL } = getConfigFromSessionStorage();
  const { loadDomainSettings, domainSettings, loading } = useLoadDomainSettings(
    selectedNode,
  );
  const { handleUploadFavicon, isUploadingFavicon } = useUploadFavicon(
    selectedNode,
    faviconFileRef,
  );
  const { saveDomainSettings } = useHandleSaveDomainSettings(
    selectedNode,
    triggerReload,
  );

  useEffect(() => {
    if (reloadTrigger) {
    }

    loadDomainSettings();
  }, [loadDomainSettings, reloadTrigger]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <input
              placeholder="Set custom title"
              {...register('title')}
              defaultValue={domainSettings?.title}
            />

            <input type="submit" value="Set title" />
          </form>
          <Box>
            <input
              id="favicon-file-select"
              type="file"
              hidden
              ref={faviconFileRef}
              onChange={(e) => {
                setSelectedFavicon(e.target?.files?.[0]?.name);
              }}
            />
            <label htmlFor="favicon-file-select">
              <Button variant="contained" component="span">
                Choose favicon
              </Button>
            </label>
            <Button
              variant="contained"
              disabled={!selectedFavicon || isUploadingFavicon}
              onClick={async () => {
                const uploadPath = await handleUploadFavicon();
                if (uploadPath && domainSettings) {
                  const fullUploadPath = `${mainAppURL}/public/${uploadPath}`;
                  const newDomainSettings: DomainProperties = {
                    ...domainSettings,
                    faviconURL: fullUploadPath,
                  };

                  await saveDomainSettings(newDomainSettings);
                }
              }}
            >
              Upload
            </Button>
            <span>{selectedFavicon && selectedFavicon}</span>
            <Box>
              {domainSettings?.faviconURL ? (
                <>
                  <span>Currently set favicon:</span>
                  <img
                    style={{ width: '16px', height: '16px' }}
                    src={domainSettings.faviconURL}
                    title={domainSettings.faviconURL}
                    alt="current set favicon"
                  />
                </>
              ) : (
                'No favicon set'
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
