import axios, { AxiosRequestConfig } from 'axios';
import { MethodEnum } from 'models/enums';

import { axiosClient, getApiRoutes } from 'api';

export const fetchFormTemplateById = async (formTemplateId: string) => {
  const api = await getApiRoutes();
  const response = await axios.get(`${api.formTemplates}/${formTemplateId}`);
  return response;
};

export const createFormTemplate = async (formTemplate: any) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: api.formTemplates,
    data: formTemplate,
  };

  const response = await axiosClient(request);

  return response;
};

export const updateFormTemplate = async (formTemplate: any) => {
  const api = await getApiRoutes();

  const formId = formTemplate.id;

  const request: AxiosRequestConfig = {
    method: MethodEnum.PUT,
    url: `${api.formTemplates}/${formId}`,
    data: formTemplate,
  };

  const response = await axiosClient(request);

  return response;
};
