import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { DEFAULT_ISSUER } from 'models/constants';
import { EventRoleTypeEnum } from 'models/enums';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useRoleCards } from '../hooks/useRoleCards';
import { RoleFormModalDialog } from '../Modals/RoleFormModalDialog';
import { RoleCardForm } from '../RoleCardForm';
import { createNewRole } from '../util/createNewRole';

enum IssuerRoleVariant {
  CUSTOM,
  PUBLIC,
  ORGANIZATION,
}
interface IProps {
  allowedIssuers: string[];
}

const IssuerRoles = ({ allowedIssuers }: IProps) => {
  const { event: eventState, user } = useSelector((state: RootState) => state);
  const { issuerRoleCards } = useRoleCards(eventState);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);
  const [issuerRoleVariant, setIssuerRoleVariant] = useState(
    IssuerRoleVariant.CUSTOM,
  );

  const userIssuer = user.user?.issuer;
  const roles = eventState.event?.roles;

  const publicAccess = useMemo(() => {
    return roles
      ? Object.keys(roles).some((roleKey) => roleKey === DEFAULT_ISSUER)
      : false;
  }, [roles]);

  const organizationAccess = useMemo(() => {
    return roles
      ? Object.keys(roles).some((roleKey) => roleKey === userIssuer)
      : false;
  }, [roles, userIssuer]);

  let organizationAccessCard;
  if (organizationAccess && userIssuer) {
    const organizationAccessRole = roles?.[userIssuer];
    const eventId = eventState.event?.id;

    if (organizationAccessRole && eventId) {
      organizationAccessCard = (
        <RoleCardForm
          eventId={eventId}
          eventRole={organizationAccessRole}
          customName={`Permissions for my organization`}
        />
      );
    }
  }

  let publicAccessCard;
  if (publicAccess) {
    const publicAccessRole = roles?.[DEFAULT_ISSUER];
    const eventId = eventState.event?.id;

    if (publicAccessRole && eventId) {
      publicAccessCard = (
        <RoleCardForm
          eventId={eventId}
          eventRole={publicAccessRole}
          customName={`Public permissions`}
        />
      );
    }
  }

  const createNewRoleDialogFromVariant = (variant: IssuerRoleVariant) => {
    if (!eventState.event?.id) throw new Error('event id is undefined');

    let customRoleName = '';
    const newRole = createNewRole(EventRoleTypeEnum.ISSUER, ``);
    switch (variant) {
      case IssuerRoleVariant.CUSTOM:
        newRole.roleName = ``;
        break;
      case IssuerRoleVariant.ORGANIZATION:
        if (!userIssuer) throw new Error(`User issuer is undefined`);

        newRole.roleName = userIssuer;
        customRoleName = `Permissions for my organization`;
        break;
      case IssuerRoleVariant.PUBLIC:
        newRole.roleName = DEFAULT_ISSUER;
        customRoleName = `Public permissions`;
        break;
    }

    return (
      <RoleFormModalDialog
        eventId={eventState.event.id}
        eventRole={newRole}
        open={showCreateRoleDialog}
        customName={customRoleName}
        onClose={() => {
          setShowCreateRoleDialog(false);
        }}
        onSuccess={() => {
          setShowCreateRoleDialog(false);
        }}
      />
    );
  };

  const handleCreateIssuerRoleClick = () => {
    setShowCreateRoleDialog(true);
    setIssuerRoleVariant(IssuerRoleVariant.CUSTOM);
  };

  const handleCreateOrganizationAccessClick = () => {
    setShowCreateRoleDialog(true);
    setIssuerRoleVariant(IssuerRoleVariant.ORGANIZATION);
  };

  const handleCreatePublicAccessClick = () => {
    setShowCreateRoleDialog(true);
    setIssuerRoleVariant(IssuerRoleVariant.PUBLIC);
  };

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid item lg={3}>
        <Stack direction="row">
          <h3>Organization Roles</h3>
          <Tooltip
            title={
              <>
                <Typography>Allowed issuers:</Typography>
                {allowedIssuers.map((issuer, index) => (
                  <Typography key={index}>{issuer}</Typography>
                ))}
              </>
            }
          >
            <InfoIcon fontSize="inherit" />
          </Tooltip>
        </Stack>
        <p>Provide access to users within your organization.</p>
      </Grid>

      {/* Cards */}
      <Grid item lg={9}>
        {user.isSuperAdmin && (
          <div>
            <Button onClick={handleCreateIssuerRoleClick} variant="contained">
              Create issuer role
            </Button>
          </div>
        )}

        {!user.isSuperAdmin && (
          <>
            {!publicAccess && (
              <div>
                <Button
                  onClick={handleCreatePublicAccessClick}
                  variant="contained"
                >
                  Allow public access
                </Button>
              </div>
            )}
            {!organizationAccess && (
              <div>
                <Button
                  onClick={handleCreateOrganizationAccessClick}
                  variant="contained"
                >
                  Allow access for company members
                </Button>
              </div>
            )}
          </>
        )}

        {/* Super admins */}
        {user.isSuperAdmin && (
          <Grid container>
            {issuerRoleCards.map((card, index) => (
              <Grid key={index} item xl={4} md={6} sm={12} sx={{ p: 0.5 }}>
                {card}
              </Grid>
            ))}
          </Grid>
        )}

        {/* The rest of the users */}
        {!user.isSuperAdmin && (
          <Grid container>
            <Grid item xl={4} md={6} sm={12} sx={{ p: 0.5 }}>
              {publicAccessCard}
            </Grid>
            <Grid item xl={4} md={6} sm={12} sx={{ p: 0.5 }}>
              {organizationAccessCard}
            </Grid>
          </Grid>
        )}
      </Grid>
      {showCreateRoleDialog &&
        createNewRoleDialogFromVariant(issuerRoleVariant)}
    </Grid>
  );
};

export default IssuerRoles;
