/* eslint-disable import/no-webpack-loader-syntax */
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-monokai';
import React, { useEffect, useMemo, useState } from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { EditorMode } from './interfaces/EditorMode';

ace.config.setModuleUrl(
  'ace/mode/html',
  require('file-loader?esModule=false!ace-builds/src-noconflict/mode-html.js'),
);
ace.config.setModuleUrl(
  'ace/mode/html_worker',
  require('file-loader?esModule=false!ace-builds/src-noconflict/worker-html.js'),
);

ace.config.setModuleUrl(
  'ace/mode/css',
  require('file-loader?esModule=false!ace-builds/src-noconflict/mode-css.js'),
);
ace.config.setModuleUrl(
  'ace/mode/css_worker',
  require('file-loader?esModule=false!ace-builds/src-noconflict/worker-css.js'),
);

ace.config.setModuleUrl(
  'ace/mode/json',
  require('file-loader?esModule=false!ace-builds/src-noconflict/mode-json.js'),
);
ace.config.setModuleUrl(
  'ace/mode/json_worker',
  require('file-loader?esModule=false!ace-builds/src-noconflict/worker-json.js'),
);

ace.config.setModuleUrl(
  'ace/mode/javascript',
  require('file-loader?esModule=false!ace-builds/src-noconflict/mode-javascript.js'),
);
ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  require('file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js'),
);

interface IProps extends IAceEditorProps {
  initialCode?: string;
}

export const CodeEditor = React.forwardRef<AceEditor, IProps>(
  // eslint-disable-next-line react/prop-types
  ({ initialCode = '', onChange, readOnly, mode = 'html' }, ref) => {
    const [value, setValue] = useState(initialCode);

    const handleChange = (code: string) => {
      setValue(code);

      if (onChange) onChange(code);
    };

    return (
      <Stack>
        <Box>
          <AceEditor
            ref={ref}
            placeholder="text"
            mode={mode}
            theme="monokai"
            name="loginPagesEditor"
            onChange={handleChange}
            width={'100%'}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={value}
            setOptions={{
              // enableBasicAutocompletion: true,
              // enableLiveAutocompletion: true,
              // enableSnippets: true,

              showLineNumbers: true,
              tabSize: 2,
              behavioursEnabled: true,
            }}
            readOnly={readOnly}
          />
        </Box>
      </Stack>
    );
  },
);
CodeEditor.displayName = 'CodeEditor';
