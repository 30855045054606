const pathConstants = {
  actions: {
    create: 'create',
    edit: 'edit',
  },
  login: 'login',
  logout: 'logout',

  home: 'home',

  events: 'events',
  eventId: ':eventId',

  access: 'access',

  users: 'users',
  userId: ':userId',
  search: 'search',

  adminTools: 'adminTools',
  adminPermissions: 'adminPermissions',

  formTemplates: 'formTemplates',
  formId: ':formId',

  staticPages: 'staticPages',

  votingRounds: 'votingRounds',
  roundId: ':roundId',

  createEvent: 'createEvent',
  createUser: 'createUser',
  searchForUsers: 'searchForUsers',
  errorPage: 'errorPage',

  customDomains: 'customDomains',

  loginPages: 'loginPages',
};

const pc = pathConstants;

export const ROUTES = {
  root: '',
  login: `/${pc.login}`,
  logout: `/${pc.logout}`,
  errorPage: `/${pc.errorPage}`,

  home: {
    root: `/${pc.home}`,

    createEvent: `/${pc.home}/${pc.createEvent}`,

    createUser: `/${pc.home}/${pc.createUser}`,

    events: {
      root: `/${pc.home}/${pc.events}`,
      withId: `/${pc.home}/${pc.events}/${pc.eventId}`,
      actions: {
        create: `/${pc.home}/${pc.events}/${pc.actions.create}`,
        edit: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.actions.edit}`,
      },

      access: {
        root: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.access}`,
      },

      formTemplates: {
        root: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.formTemplates}`,
        withId: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.formTemplates}/${pc.formId}`,

        actions: {
          create: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.formTemplates}/${pc.actions.create}`,
          edit: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.formTemplates}/${pc.formId}/${pc.actions.edit}`,
        },
      },

      staticPages: {
        root: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.staticPages}`,
      },

      votingRounds: {
        root: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.votingRounds}`,
        withId: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.votingRounds}/${pc.roundId}`,

        actions: {
          create: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.votingRounds}/${pc.actions.create}`,
          edit: `/${pc.home}/${pc.events}/${pc.eventId}/${pc.votingRounds}/${pc.actions.edit}/${pc.roundId}`,
        },
      },
    },

    adminTools: {
      root: `/${pc.home}/${pc.adminTools}`,

      adminPermissions: {
        root: `/${pc.home}/${pc.adminTools}/${pc.adminPermissions}`,
      },
      loginPages: {
        root: `/${pc.home}/${pc.adminTools}/${pc.loginPages}`,
      },
      searchForUsers: {
        root: `/${pc.home}/${pc.adminTools}/${pc.searchForUsers}`,
      },
      customDomains: {
        root: `/${pc.home}/${pc.adminTools}/${pc.customDomains}`,
      },
    },

    users: {
      root: `/${pc.home}/${pc.users}`,
      withId: `/${pc.home}/${pc.users}/${pc.userId}`,
    },
  },
};
