import { EventItem } from 'api/models';
import { SubmissionTabField } from 'features/event/models';

export function createSubmissionTabFieldArrFromObjArr(
  event: EventItem,
): SubmissionTabField[] {
  const result: SubmissionTabField[] = [];

  if (!event.viewSubmissionTabs || !event.viewSubmissionTabs.length)
    return result;

  event.viewSubmissionTabs.forEach(({ forms, tabName }) => {
    const currentTab: SubmissionTabField = {
      forms: forms.toString(),
      tabName,
    };
    result.push(currentTab);
  });

  return result as SubmissionTabField[];
}
