import { UpdateEventModeEnum } from 'models/enums/Event.enum';
import { VotingRoundData } from './../../voting/models/VotingRoundData';
import { VotingRoundFormValues } from 'features/voting/models';
import { Dispatch } from 'redux';
import { updateEvent } from 'features/event/store/eventThunks';
import { VotingEventDTO } from 'features/event/models';

export const onEventVotingRoundSubmit = async (
  fields: VotingRoundFormValues,
  actions: any,
  dispatch: Dispatch<any>,
  eventId: string,
  setIsFormSubmit?: any,
) => {
  const votingRounds: { [key: string]: VotingRoundData } =
    fields.event?.votingRounds ?? {};

  const { roundId, event, ...newVotingRound } = fields;

  if (newVotingRound.fieldFilters?.length === 0)
    newVotingRound.fieldFilters = undefined;
  if (newVotingRound.evalFieldFilters?.length === 0)
    newVotingRound.evalFieldFilters = undefined;
  if (newVotingRound.stageFilters?.length === 0)
    newVotingRound.stageFilters = undefined;

  const data = Object.assign({}, votingRounds, { [roundId]: newVotingRound });

  // @ts-ignore
  const updatedEvent: VotingEventDTO = {
    id: eventId,
    votingRounds: data,
    updateType: UpdateEventModeEnum.VOTING,
  };

  // console.log('updatedEvent', updatedEvent);

  await dispatch(updateEvent(updatedEvent));

  setIsFormSubmit(true);
};
