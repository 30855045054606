import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';

import Spinner from 'components-lib/Spinner';
import { LoadingStateEnum } from 'models/enums';
import { ROUTES } from 'routes';
import { RootState } from 'store/models';
import { selectEvent } from 'features/event/store/eventSelectors';
import { fetchEventById } from 'features/event/store/eventThunks';
import { resetTemplate } from 'features/formTemplate/store/formTemplateActions';

export default function FormTemplatesList() {
  const { eventId } = useParams<Record<string, string>>();
  const loadingEvent = useSelector((state: RootState) => state.event.loading);
  const loadingForm = useSelector(
    (state: RootState) => state.formTemplate.loading,
  );
  const event = useSelector(selectEvent);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!event || eventId !== event?.id) {
      dispatch(resetTemplate());
      dispatch(fetchEventById(eventId));
    }
  }, [event, eventId, dispatch]);

  return event &&
    loadingEvent === LoadingStateEnum.IDLE &&
    loadingForm === LoadingStateEnum.IDLE ? (
    <>
      <h1 className="h4 rounded-pill bg-dark text-white px-3 py-1 mb-4 d-inline-block font-weight-light">
        Form Templates
      </h1>

      <h3>Main submission form</h3>
      <div>
        <Link
          to={generatePath(ROUTES.home.events.formTemplates.actions.edit, {
            eventId: event?.id,
            formId: event?.formId,
          })}
        >
          Edit main form template
        </Link>
      </div>

      <h3>Evaluation forms</h3>
      {event?.evalFormId?.map((form, index) => (
        <div className="d-flex flex-column-reverse" key={index}>
          <Link
            to={generatePath(ROUTES.home.events.formTemplates.actions.edit, {
              eventId: event?.id,
              formId: form.formId,
            })}
          >
            Edit {form.evalForm} Template
          </Link>
        </div>
      ))}
    </>
  ) : (
    <Spinner />
  );
}
