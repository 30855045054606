import { TabList, TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { DEFAULT_TEMPLATE_ID } from 'models/constants';
import React, { useState } from 'react';
import { LoginTemplateEditor } from './components/LoginTemplateEditor/LoginTemplateEditor';
import { TemplateEditorTypeTab } from './Models/TemplateEditorTabs';
import { TemplateEditorPage } from './pages/TemplateEditorPage/TemplateEditorPage';

export const CustomLoginPages = () => {
  const [pageType, setPageType] = useState<TemplateEditorTypeTab>('templates');

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: TemplateEditorTypeTab,
  ) => {
    setPageType(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={pageType}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Login page type">
            <Tab label="Template list" value="templates" />
            <Tab label="default template" value="defaultTemplate" />
          </TabList>

          <TabPanel value="templates">
            <TemplateEditorPage />
          </TabPanel>
          <TabPanel value="defaultTemplate">
            <LoginTemplateEditor
              selectedNode={DEFAULT_TEMPLATE_ID}
              setSelectedNode={() => {}} // Empty function since no logic for changing the node is required
            />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};
