import {
  ADMIN_TOOLS_NAME,
  MAIN_DASHBOARD_EVENTS_SECTION_NAME,
} from 'models/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes';
import { RootState } from 'store/store';

/**
 * Sidebar displayed for the dashboard
 */
export function DashboardSidebar() {
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`${ROUTES.home.events.root}`}
        >
          <span data-feather="home"></span>
          {MAIN_DASHBOARD_EVENTS_SECTION_NAME}
        </NavLink>
      </li>
      {user.isSuperAdmin && (
        <li className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`${ROUTES.home.adminTools.root}`}
          >
            <span data-feather="home"></span>
            {ADMIN_TOOLS_NAME}
          </NavLink>
        </li>
      )}
    </>
  );
}
