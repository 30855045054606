import { GenericTable } from 'components-lib/GenericTable/GenericTable.component';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { adminPermissionsUserDefinition } from './util/adminPermssionsUserDefinition';
import { AdminPermissionNamesEnum } from 'api/models/AdminPermissions/AdminPermissionNamesEnum';
import { PermissionsAdminUserView } from './models/PermissionsAdminUserView';
import { SearchForUsers } from 'components-lib/SearchForUsers/SearchForUsers';
import { UserData } from 'api/models/SearchForUsersResponseDTO';
import { fetchUsersFromPermissionsDb } from 'api';
import { UpdatePermissionsAdminUserModal } from './modals/UpdatePermissionsAdminUserModal';

export const AdminPermissionsUserAccess = () => {
  const [dataRows, setDataRows] = useState<PermissionsAdminUserView[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [showEditPermissions, setShowEditPermissions] = useState(false);

  const handleSearchComplete = async (result: UserData[]) => {
    const userIds = result.map((user) => user.id.toString());
    const response = await fetchUsersFromPermissionsDb(userIds);
    const { data } = response;

    const rows = result.map((resultItem) => {
      const sqlDbData = data.find(
        (sqlDataItem) => sqlDataItem.user_id === resultItem.id,
      );
      return {
        ...sqlDbData,
        ...resultItem,
      } as PermissionsAdminUserView;
    });

    setDataRows(rows);
  };

  const selectedUserData = dataRows.find(
    (dataItem) => dataItem.id === selected[0],
  );

  return (
    <>
      <SearchForUsers
        onSearchComplete={handleSearchComplete}
        visibleColumns={['email', 'firstName', 'lastName']}
      />
      <Button
        onClick={() => setShowEditPermissions(true)}
        disabled={selected.length !== 1}
      >
        Edit access
      </Button>
      <GenericTable
        rows={dataRows}
        visibleColumns={[
          'firstName',
          'lastName',
          'email',
          AdminPermissionNamesEnum.basic_access,
          AdminPermissionNamesEnum.full_access,
        ]}
        headCellsDefinition={adminPermissionsUserDefinition}
        onSelectedItemsChange={setSelected}
      />

      {showEditPermissions && selectedUserData && (
        <UpdatePermissionsAdminUserModal
          onSuccess={() => {
            alert('success');
          }}
          userData={selectedUserData}
          onExit={() => {
            setShowEditPermissions(false);
          }}
        />
      )}
    </>
  );
};
