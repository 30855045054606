import { uploadFileToS3 } from 'api';
import { getS3SignedUrlForPublicResourcesUpload } from 'api/publicResources';
import path from 'path';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { TemplateEditorTab } from '../../../Models/TemplateEditorTab.enum';
import { getCurrentCode } from '../../../../util';
import { TEMPLATES_FOLDER } from 'models/constants';

interface IOptions {
  editorRef: React.MutableRefObject<null>;
  selectedNode: string;
  selectedTab: TemplateEditorTab;
}

export const useHandleSave = ({
  editorRef,
  selectedNode,
  selectedTab,
}: IOptions) => {
  const [saved, setSaved] = useState(true); // Initially the code is unedited so it is considered saved
  const [isSaving, setSaving] = useState(false);

  const saveCodeAsync = useCallback(async () => {
    let toastId;
    try {
      const code = getCurrentCode(editorRef);

      if (!code) return;

      toastId = toast.info('Saving...', { autoClose: false });

      setSaving(true);
      const fileName = path.basename(selectedNode);

      const blob = new Blob([code]);
      const file = new File([blob], fileName);

      const filePath = `LoginPages/${TEMPLATES_FOLDER}/${selectedNode}/${selectedTab}`;
      const response = await getS3SignedUrlForPublicResourcesUpload(
        file.type,
        filePath,
      );
      const url = response.data as string;
      await uploadFileToS3(url, file);

      setSaved(true);

      toast.success('Saved');
    } catch (e) {
      toast.error(`${JSON.stringify(e)}`);
    } finally {
      if (toastId) toast.dismiss(toastId);
      setSaving(false);
    }
  }, [editorRef, selectedNode, selectedTab]);

  return { saved, setSaved, saveCodeAsync, isSaving };
};
