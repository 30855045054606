import { EventItem } from 'api/models';
import { EventRole } from 'features/event/models';
import { resetEvent } from 'features/event/store/eventActions';
import { DEFAULT_TOAST_AUTO_CLOSE } from 'models/constants';
import { EventRoleTypeEnum, LoadingStateEnum } from 'models/enums';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ROUTES } from 'routes';
import { RootState } from 'store/store';
import BulkAddDomainRolesModal from './Modals/BulkAddDomainRolesModal';
import CreateUserModal from './Modals/CreateUserModal';
import Roles from './Roles/Roles';
import UserProvisioning from './UserProvisioning';

export const Access = () => {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [
    showBulkAddDomainRolesModal,
    setShowBulkAddDomainRolesModal,
  ] = useState(false);

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { loading, eventError, event } = useSelector(
    (state: RootState) => state.event,
  );
  const user = useSelector((state: RootState) => state.user);
  const customRoles = useMemo(
    () => getEventRoles(event, EventRoleTypeEnum.CUSTOM),
    [event],
  );
  const { userError } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (loading === LoadingStateEnum.ERROR || eventError || userError) {
      toast.error(eventError?.message, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });
      dispatch(resetEvent());
      push(ROUTES.errorPage);
    }
  }, [userError, loading, dispatch, push, eventError]);

  return (
    <div>
      <div>
        <h1 className="h4 rounded-pill bg-dark text-white px-3 py-1 mb-4 d-inline-block font-weight-light">
          User Access
        </h1>
      </div>

      <>
        <Roles />

        {user.isSuperAdmin && (
          <div className="mb-5">
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowBulkAddDomainRolesModal(true);
              }}
            >
              Add multiple domains
            </Button>

            {showBulkAddDomainRolesModal && (
              <BulkAddDomainRolesModal
                onExit={() => {
                  setShowBulkAddDomainRolesModal(false);
                }}
              />
            )}
          </div>
        )}

        <UserProvisioning />

        <div className="mb-5">
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowCreateUserModal(true);
            }}
            disabled={customRoles.length < 1}
          >
            Invite user
          </Button>
        </div>
        {showCreateUserModal && (
          <CreateUserModal
            onSuccess={() => {
              toast.success('User invited');
            }}
            onExit={() => {
              setShowCreateUserModal(false);
            }}
          />
        )}
      </>
    </div>
  );
};

/**
 * @description get event roles with specific type
 * @param {EventItem} event
 * @param {EventRoleTypeEnum} type
 * @returns {EventRole[]}
 */
function getEventRoles(
  event: EventItem | undefined,
  type: EventRoleTypeEnum,
): EventRole[] {
  return event?.roles
    ? Object.values(event.roles).filter((role) => role.roleType === type)
    : [];
}
