import { SelectOptions } from 'components-lib/FormSelectField';
import { FormEditorModeEnum } from 'models/enums';
import { FormSchema, FormTemplate } from '../models';

export const DEFAULT_EDITOR_MODES = Object.freeze(
  Object.values(FormEditorModeEnum),
);

export const formTemplateSchema: FormSchema = Object.freeze({
  title: 'FormTemplateSchema',
  type: 'object',
  properties: {
    formSchema: {
      type: 'object',
      properties: {
        properties: {
          type: 'object',
        },
        required: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
      required: ['properties', 'required'],
    },
    order: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['formSchema', 'order'],
});

export const formTemplateBaseForm: FormTemplate = Object.freeze({
  formSchema: {
    properties: {
      description: {
        maxLength: 500,
        title: 'Description Field',
        type: 'string',
      },
      title: {
        maxLength: 100,
        title: 'Example Title Field',
        type: 'string',
      },
    },
    required: ['title', 'description'],
    type: 'object',
  },
  id: 'sample id',
  order: ['title', 'description'],
});

const default_form_template_fields = Object.freeze(['title', 'description']);

export const DEFAULT_FORM_FIELD_OPTIONS: SelectOptions[] = default_form_template_fields.map(
  (fieldName: string) => ({
    value: fieldName.toLowerCase(),
    name: fieldName,
  }),
);

const field_types = Object.freeze(['group', 'user', 'email']);

export const DEFAULT_FORM_FIELD_TYPE_OPTIONS: SelectOptions[] = field_types.map(
  (typeName: string) => ({
    value: typeName.toLowerCase(),
    name: typeName,
  }),
);
