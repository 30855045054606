import { AxiosResponse } from 'axios';
import { DEFAULT_TOAST_AUTO_CLOSE } from 'models/constants';
import { toast } from 'react-toastify';

export const handleToastOnResponse = (
  response: AxiosResponse<any>,
  errMsg: string,
  successMsg: string,
) => {
  if (response.status !== 200) {
    toast.error(errMsg, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });
  } else {
    toast.success(successMsg, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });
  }
};
