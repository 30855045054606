import { axiosCustom } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import path from 'path';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { EditorMode } from '../../CodeEditor/interfaces/EditorMode';

export const useStaticFileLoad = () => {
  const [loadedCode, setLoadedCode] = useState<string | undefined>(undefined);
  const [loadingFile, setLoadingFile] = useState(false);
  const [edtiorMode, setEditorMode] = useState<EditorMode>('html');

  const { mainAppURL } = getConfigFromSessionStorage();

  const loadStaticFile = useCallback(
    async (fileName: string) => {
      setLoadingFile(true);

      try {
        const url = `${mainAppURL}/${fileName}`;
        const request: AxiosRequestConfig = {
          method: 'GET',
          url,
        };

        const { data } = await axiosCustom(request);

        let extension = path.extname(url);
        let mode: EditorMode;

        switch (extension) {
          case '.html':
            mode = 'html';
            break;
          case '.css':
            mode = 'css';
            break;
          case '.json':
            mode = 'json';
            break;
          case '.js':
            mode = 'javascript';
            break;
          default:
            mode = 'html';
        }

        setEditorMode(mode);

        // workaround for auto-parsing json data from the browser
        if (mode === 'json' && typeof data === 'object') {
          setLoadedCode(JSON.stringify(data, undefined, 2));
        } else {
          setLoadedCode(data);
        }
      } catch (e) {
        toast.error(`Unable to load ${fileName}`);
        setLoadedCode('');
      } finally {
        setLoadingFile(false);
      }
    },
    [mainAppURL],
  );

  return { loadedCode, loadingFile, edtiorMode, loadStaticFile };
};
