import {
  createCustomDomainJsonFile,
  createPublicResourcesEmptyTextFile,
  createPublicResourcesFolder,
  deletePublicResourcesObjects,
} from 'api/publicResources';
import { TEMPLATES_FOLDER, TEMPLATE_FILE_NAMES } from 'models/constants';
import { TemplateType } from 'models/TemplateType';
import { TreeNode } from 'path-list-to-tree';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface IOptions {
  templateType: TemplateType;
  selectedNode: string;
  triggerTreeReload: () => void;
  tree: TreeNode[];
}

export const useTemplateActions = ({
  templateType,
  selectedNode,
  triggerTreeReload,
  tree,
}: IOptions) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClicked = async () => {
    if (!selectedNode) return;

    let toastId;
    try {
      toastId = toast.info('Deleting...', { autoClose: false });

      setIsDeleting(true);
      const path = `${templateType}/__templates/${selectedNode}/`;
      await deletePublicResourcesObjects([path]);

      triggerTreeReload();

      toast.success('Deleted');
    } catch (e) {
      toast.error(JSON.stringify(e));
    } finally {
      if (toastId) toast.dismiss(toastId);
      setIsDeleting(false);
    }
  };

  const handleAddTemplate = async (newTemplateId: string) => {
    try {
      const templateExists = tree.some(
        (treeNode) => treeNode.name === newTemplateId,
      );

      if (templateExists) {
        throw new Error(`Template already exists`);
      }

      const folderPath = `${templateType}/${TEMPLATES_FOLDER}/${newTemplateId}`;

      await createPublicResourcesFolder(folderPath);
      switch (templateType) {
        case 'CustomDomains': {
          await Promise.all([
            createCustomDomainJsonFile(
              `${folderPath}/${TEMPLATE_FILE_NAMES.DOMAIN_SETTINGS}`,
            ),
          ]);
          break;
        }
        case 'LoginPages': {
          await Promise.all([
            createPublicResourcesEmptyTextFile(
              `${folderPath}/${TEMPLATE_FILE_NAMES.HTML}`,
            ),
            createPublicResourcesEmptyTextFile(
              `${folderPath}/${TEMPLATE_FILE_NAMES.CSS}`,
            ),
          ]);
        }
      }

      setShowAddModal(false);
      triggerTreeReload();
      toast.success('Added');
    } catch (e: any) {
      toast.error(e?.message ? e.message : JSON.stringify(e));
    }
  };

  const handleAddClicked = () => {
    setShowAddModal(true);
  };

  const handleAddTemplateModalExit = () => {
    setShowAddModal(false);
  };

  return {
    handleDeleteClicked,
    handleAddTemplate,
    handleAddClicked,
    handleAddTemplateModalExit,
    showAddModal,
    isDeleting,
  };
};
