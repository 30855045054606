import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { searchForUsers } from 'api';
import { UserData } from 'api/models/SearchForUsersResponseDTO';

interface IProps {
  onSearchComplete: (userData: UserData[]) => void;
  visibleColumns: Array<
    keyof {
      firstName: string;
      lastName: string;
      email: string;
    }
  >;
  eventId?: string;
}

export const SearchForUsers = ({
  onSearchComplete,
  visibleColumns,
  eventId,
}: IProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleSearchForUser = async () => {
    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;
    const email = emailRef.current?.value;

    let firstNames, lastNames, emails;
    if (firstName) firstNames = [firstName];
    if (lastName) lastNames = [lastName];
    if (email) emails = [email];

    setIsSearching(true);
    try {
      const result = await searchForUsers({
        firstNames,
        lastNames,
        emails,
        eventId,
      });

      const dataItems = result.result;
      const resultRows = Object.values(dataItems);

      onSearchComplete(resultRows);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="d-flex align-items-stretch justify-content-center mb-2 border p-3">
      {visibleColumns.includes('firstName') && (
        <label>
          First name:
          <input
            type="text"
            placeholder="Search by first name"
            name="firstName"
            ref={firstNameRef}
          />
        </label>
      )}
      {visibleColumns.includes('lastName') && (
        <label>
          Last name:
          <input
            type="text"
            placeholder="Search by last name"
            name="lastName"
            ref={lastNameRef}
          />
        </label>
      )}
      {visibleColumns.includes('email') && (
        <label className="mb-0">
          Find users
          <input
            className="p-1 ml-2"
            type="text"
            placeholder="Search by email"
            name="email"
            ref={emailRef}
          />
        </label>
      )}
      <Button
        variant="outline-primary"
        className="py-1"
        onClick={handleSearchForUser}
        disabled={isSearching}
      >
        Search
      </Button>
    </div>
  );
};
