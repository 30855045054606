import AWS from 'aws-sdk';
import { FileArray, FileData } from 'chonky';
import path from 'path';

export const convertToChonkyFileArray = (
  response: AWS.S3.ListObjectsV2Output,
  pathPrefix: string,
) => {
  const chonkyFiles: FileArray = [];
  const s3Objects = response.Contents;
  const s3Prefixes = response.CommonPrefixes;

  if (s3Objects) {
    s3Objects.forEach((object) => {
      const id = object.Key!;
      // Filter folder objects: sometimes the result contains a Key that represents the folder and we don't need to display that
      if (id.endsWith('/')) return;
      const shortId = id.replace(pathPrefix, '');

      chonkyFiles.push({
        id: shortId,
        name: path.basename(object.Key!),
        modDate: object.LastModified,
        size: object.Size,
      });
    });
  }

  if (s3Prefixes) {
    chonkyFiles.push(
      ...s3Prefixes.map(
        (prefix): FileData => ({
          id: prefix.Prefix!.replace(pathPrefix, ''),
          name: path.basename(prefix.Prefix!),
          isDir: true,
        }),
      ),
    );
  }

  return chonkyFiles;
};
