import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'routes';

import FormTemplatesList from 'features/formTemplate/components/FormTemplatesList';
import { FormTemplateForm } from 'features/formTemplate/components/FormTemplateForm';
import { VotingRoundsMain } from 'features/voting/components/VotingRoundsMain';
import { VotingRoundsForm } from 'features/voting/components/VotingRoundsForm';
import { Access } from 'features/access/components/Access';
import StaticPages from 'features/event/components/StaticPages/StaticPages';
import { EventForm } from 'features/event/components/EditEventForm/EventForm';

/**
 * Routing for the different categories of event properties and settings
 */
export function EventRouting() {
  return (
    <Switch>
      <Route exact path={`${ROUTES.home.events.actions.edit}`}>
        <EventForm />
      </Route>
      <Route exact path={`${ROUTES.home.events.withId}`}>
        <EventForm />
      </Route>
      <Route path={`${ROUTES.home.events.access.root}`}>
        <Access />
      </Route>
      <Route path={`${ROUTES.home.events.formTemplates.actions.edit}`}>
        <FormTemplateForm />
      </Route>
      <Route path={`${ROUTES.home.events.formTemplates.root}`}>
        <FormTemplatesList />
      </Route>
      <Route path={`${ROUTES.home.events.staticPages.root}`}>
        <StaticPages />
      </Route>
      <Route path={`${ROUTES.home.events.votingRounds.actions.create}`}>
        <VotingRoundsForm />
      </Route>
      <Route path={`${ROUTES.home.events.votingRounds.actions.edit}`}>
        <VotingRoundsForm />
      </Route>
      <Route path={`${ROUTES.home.events.votingRounds.root}`}>
        <VotingRoundsMain />
      </Route>
    </Switch>
  );
}
