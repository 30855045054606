import { UserSearch } from 'features/user/components/UserSearch/UserSearch.component';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes';
import { AdminPermissions } from './adminPermissions/AdminPermissions';
import { CustomDomainSettings } from './CustomDomainSettings/CustomDomainSettings';
import { CustomLoginPages } from './LoginPages/CustomLoginPages';

export const AdminToolsRouting = () => {
  return (
    <>
      <Switch>
        <Route path={`${ROUTES.home.adminTools.searchForUsers.root}`}>
          <UserSearch />
        </Route>
        <Route path={`${ROUTES.home.adminTools.adminPermissions.root}`}>
          <AdminPermissions />
        </Route>
        <Route path={`${ROUTES.home.adminTools.loginPages.root}`}>
          <CustomLoginPages />
        </Route>
        <Route path={`${ROUTES.home.adminTools.customDomains.root}`}>
          <CustomDomainSettings />
        </Route>
      </Switch>
    </>
  );
};
