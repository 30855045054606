import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import { Select } from 'formik-mui';
import React from 'react';

export interface SelectOptions {
  value: string;
  name: string;
}
interface IProps {
  fieldLabel: string;
  fieldName: string;
  errors?: string | string[] | FormikErrors<any>[];
  touched?: boolean | FormikTouched<any>[];
  options: SelectOptions[];
  isRequired?: boolean;
  type?: string;
  disabled?: boolean;
}

export const FormSelectField = ({
  fieldLabel,
  fieldName,
  errors,
  touched,
  options,
  type,
  disabled,
  isRequired,
}: IProps) => {
  return (
    <div className="form-group">
      <FormControl variant="outlined" className="w-100">
        <Field
          component={Select}
          label={fieldLabel}
          fullWidth
          className={errors && touched ? ' is-invalid bg-white' : 'bg-white'}
          autoComplete="off"
          name={fieldName}
          disabled={disabled}
          type={type ? type : 'text'}
          required={isRequired ? true : false}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((o, idx) => (
            <MenuItem key={idx} value={o.value}>
              {o.name}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      <ErrorMessage
        name={fieldName}
        component="div"
        className="invalid-feedback"
      />
    </div>
  );
};
