import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api';
import { IndexInfo } from 'api/models';
import { LoadingStateEnum } from 'models/enums';
import { RootState } from 'store/store';
import { UserInfo } from 'features/user/store/models';

export const fetchUser = createAsyncThunk<UserInfo, void, { state: RootState }>(
  'user/fetchUser',
  async () => {
    const response = await api.fetchUser();

    return response.data;
  },
  {
    condition: (userId, { getState, extra }) => {
      const { loading } = getState().user;
      if (loading === LoadingStateEnum.PENDING) {
        return false;
      }
    },
  },
);

export const setUser: any = createAsyncThunk<any, any, { state: RootState }>(
  'user/setUser',
  async (authData, { getState }) => {
    const { loading } = getState().user;
    if (loading !== LoadingStateEnum.PENDING) {
      return;
    }

    return authData.attributes;
  },
);

export const logoutUser: any = createAsyncThunk<
  string | undefined,
  string,
  { state: RootState }
>('user/logoutUser', async (_undefined, { getState }) => {
  const { loading } = getState().user;
  if (loading !== LoadingStateEnum.PENDING) {
    return;
  }
  throw new Error("Logout not implemented from admin portal");
  // eslint-disable-next-line no-unreachable
  return "success";
});

export const fetchUserId: any = createAsyncThunk<string, IndexInfo>(
  'user/fetchUserId',
  async (indexInfo) => {
    const response = await api.fetchUserId(indexInfo);

    return response.data[0].id;
  },
);
