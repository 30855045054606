import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import { VotingRoundData } from '../models';

interface IProps {
  eventVotingRounds: [string, VotingRoundData][];
  eventId: string;
}

export const VotingRoundsList = ({ eventVotingRounds, eventId }: IProps) => {
  return (
    <div className="">
      <table className="table table-striped table-bordered table-hover table-responsive-md">
        <thead>
          <tr>
            <th>Round Id</th>
            <th>Tab name</th>
            <th>Question</th>
            <th>Start time</th>
            <th>End time</th>
          </tr>
        </thead>
        <tbody>
          {eventVotingRounds?.map(([roundId, roundItem], index) => {
            return (
              <tr key={index}>
                <td>
                  <Link
                    to={generatePath(
                      ROUTES.home.events.votingRounds.actions.edit,
                      {
                        eventId,
                        roundId: roundId,
                      },
                    )}
                  >
                    {roundId}
                  </Link>
                </td>
                <td>{roundItem.tabName}</td>
                <td>{roundItem.question}</td>
                <td>{roundItem.startTime}</td>
                <td>{roundItem.endTime}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
