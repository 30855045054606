import React from 'react';
import { Field, FieldProps } from 'formik';

interface IProps {
  fieldLabel: string;
  fieldName: string;
  fieldValue?: string | number;
  noIndent?: boolean;
  disabled?: boolean;
}

export const FormCheckboxField = ({
  fieldLabel,
  fieldName,
  fieldValue,
  noIndent,
  disabled,
}: IProps) => {
  return (
    <div className="form-group row mb-0">
      {!noIndent && <div className="col-sm-4"></div>}
      <div className={noIndent ? 'col-auto ml-3' : 'col-sm-8'}>
        <div className="form-check">
          <Field name={fieldName}>
            {({ field, form }: FieldProps) => (
              <label className="form-check-label" htmlFor={fieldLabel}>
                <input
                  disabled={disabled}
                  type="checkbox"
                  className="form-check-input"
                  checked={field.value.includes(fieldValue)}
                  onChange={() => {
                    if (field.value.includes(fieldValue)) {
                      const nextValue = field.value.filter(
                        (value: string) => value !== fieldValue,
                      );
                      form.setFieldValue(fieldName, nextValue);
                    } else {
                      const nextValue = field.value.concat(fieldValue);
                      form.setFieldValue(fieldName, nextValue);
                    }
                  }}
                />
                {fieldLabel}
              </label>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};
