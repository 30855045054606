import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes';
import Spinner from 'components-lib/Spinner';
import JSONEditorReact from 'components-lib/JSONEditorReact';
import { fetchFormTemplateById } from 'features/formTemplate/store/formTemplateThunks';
import { resetState } from '../store/formTemplateSlice';
import {
  selectFormTemplate,
  selectFormTemplateError,
} from 'features/formTemplate/store/formTemplateSelectors';
import { onUpdateFormTemplateSubmit } from 'features/utils/formTemplateHelper';
import { LoadingStateEnum } from 'models/enums';
import { DEFAULT_EDITOR_MODES, formTemplateSchema } from './constants';
import { RootState } from 'store/store';
import { toast } from 'react-toastify';
export const FormTemplateForm = () => {
  const { loading } = useSelector((state: RootState) => state.formTemplate);
  const { push, goBack } = useHistory();
  const dispatch = useDispatch();
  const { formId, eventId } = useParams<Record<string, string>>();
  const formTemplate = useSelector(selectFormTemplate);
  const formTemplateError = useSelector(selectFormTemplateError);
  const [text, setText] = useState<string | null>(null);
  const [mode, setMode] = useState<string>(DEFAULT_EDITOR_MODES[0]);
  const onChangeText = (text: string) => {
    setText(text);
  };

  const onModeChange = (mode: string) => {
    setMode(mode);
  };
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!formTemplate || formId !== formTemplate.id) {
      dispatch(fetchFormTemplateById(formId));
    }
  }, [formId, dispatch, formTemplate]);

  useEffect(() => {
    const json = {
      formSchema: formId && formTemplate ? formTemplate.formSchema : {},
      order: formId && formTemplate ? formTemplate.order : [],
    };
    setText(JSON.stringify(json, null, 2));
  }, [formTemplate, formId]);
  useEffect(() => {
    if (formTemplateError) {
      toast.error('Form Template not found!');
      goBack();
    }
  }, [dispatch, formTemplateError, goBack]);

  return loading === LoadingStateEnum.IDLE && formTemplate ? (
    <div className="d-flex flex-column" style={{ height: '100%' }}>
      <div>Update Form Template </div>
      <JSONEditorReact
        schema={formTemplateSchema}
        text={text}
        mode={mode}
        modes={DEFAULT_EDITOR_MODES}
        indentation={4}
        onChangeText={onChangeText}
        onModeChange={onModeChange}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onUpdateFormTemplateSubmit(text, formId, eventId, push, dispatch);
        }}
      >
        <button type="submit">Confirm changes</button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setText(
              JSON.stringify(
                {
                  formSchema: {},
                  order: [],
                },
                null,
                2,
              ),
            );
          }}
        >
          Clear form
        </button>
        <button
          type="button"
          onClick={() => {
            goBack();
          }}
        >
          Cancel
        </button>
      </form>

      {/* Json schema preview */}
      {mode !== 'code' ? (
        <div className="code">
          <pre>
            <code>{text}</code>
          </pre>
        </div>
      ) : null}
    </div>
  ) : (
    <Spinner />
  );
};
