import * as api from 'api';
import { CreateEventDTO } from 'features/event/models';
import { createTemplateBaseForm } from 'features/utils/formTemplateHelper';
import { handleToastOnResponse } from 'features/utils/toastHelper';
import { ErrorMsgsEnum, SuccessMsgEnum } from 'models/enums';
import { toast } from 'react-toastify';
import { CreateEventFormValues } from '../models/CreateEventFormValues';
import { generateIdBasedOnCustomSuffix } from './generateIdBasedOnCustomSuffix';

export const handleCreateEventSubmit = async (
  values: CreateEventFormValues,
) => {
  const { id, suffix, title, skipSuffixGeneration, issuer } = values;
  const newEventId = generateIdBasedOnCustomSuffix(
    id,
    suffix,
    skipSuffixGeneration,
  );
  const baseForm = createTemplateBaseForm(`${newEventId}_MainForm`);

  const newEvent: CreateEventDTO = {
    id: newEventId,
    title,
    skipSuffixGeneration,
    issuer,
    templateBaseForm: baseForm,
  };

  const response = await api.createEvent(newEvent);

  handleToastOnResponse(
    response,
    ErrorMsgsEnum.CREATE_EVENT,
    SuccessMsgEnum.CREATE_EVENT,
  );

  if (response.status === 200 && !response.data.areStaticFoldersCreated) {
    toast.warn(ErrorMsgsEnum.CREATE_STATIC_PAGE_FOLDERS);
  }

  return response;
};
