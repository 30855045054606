import React, { useCallback, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { EditorMode } from '../../app/home/adminTools/LoginPages/components/CodeEditor/interfaces/EditorMode';
import Spinner from '../../components-lib/Spinner';
import { CodeEditor } from '../../app/home/adminTools/LoginPages/components/CodeEditor/CodeEditor';
import prettier from 'prettier';
import htmlParser from 'prettier/parser-html';
import cssParser from 'prettier/parser-postcss';
import jsonParser from 'prettier/parser-babel';

interface IPrettierError {
  loc: {
    start: {
      line: number;
      column: number;
    };
    end: {
      line: number;
      column: number;
    };
  };
  codeFrame: string;
}
interface Iprops {
  loadingFile: boolean;
  loadedCode: any;
  editorMode: EditorMode;
  saveFunction: (code: string) => Promise<void>;
  onSaveSuccess: () => void;
  onSaveError?: (error: unknown) => void;
  onFormatCodeError?: (error: any) => void;
  onChange?: (code: string) => void;
  eventId: string;
}

export const CustomEditor: React.FC<Iprops> = ({
  loadedCode,
  editorMode,
  loadingFile,
  saveFunction,
  onSaveSuccess,
  onSaveError,
  onFormatCodeError,
  onChange,
}) => {
  const editorRef = useRef(null);
  const [formatCodeError, setFormatCodeError] = useState<
    IPrettierError | undefined
  >();
  const [isSaving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false); // there are no edits initially.

  const handleSave = async () => {
    setSaving(true);

    const code = (editorRef.current as any).editor?.getValue();

    try {
      if (typeof code !== 'string')
        throw new Error(`Could not get editor code`);

      await saveFunction(code);

      setSaved(true);
      if (onSaveSuccess) onSaveSuccess();
    } catch (e) {
      if (onSaveError) onSaveError(e);
    } finally {
      setSaving(false);
    }
  };

  const handleChange = useCallback(
    (code: string) => {
      setSaved(false);

      if (onChange) onChange(code);
    },
    [onChange],
  );

  const formatCode = () => {
    try {
      if (editorRef.current) {
        const code = (editorRef.current as any).editor?.getValue();

        if (typeof code === 'string') {
          const formatted = prettier.format(code, {
            trailingComma: 'all',
            tabWidth: 2,
            semi: true,
            singleQuote: true,
            endOfLine: 'lf',
            arrowParens: 'always',
            parser: editorMode,
            plugins: [htmlParser, cssParser, jsonParser],
            'html.format.wrapAttributes': 'force-aligned',
          } as any);

          (editorRef.current as any).editor?.setValue(formatted);
        }
      }
    } catch (e) {
      const error = e as IPrettierError;
      if (onFormatCodeError) onFormatCodeError(e);

      setFormatCodeError(error);
    }
  };

  const codeEditor = useMemo(
    () => (
      <CodeEditor
        initialCode={loadedCode}
        readOnly={loadingFile || isSaving}
        mode={editorMode}
        ref={editorRef}
        onChange={handleChange}
      />
    ),

    [editorMode, handleChange, isSaving, loadedCode, loadingFile],
  );

  return (
    <Box>
      {loadingFile ? <Spinner /> : codeEditor}
      <Button
        onClick={handleSave}
        disabled={saved || isSaving}
        variant="contained"
      >
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button variant="contained" onClick={async () => await formatCode()}>
        Format code
      </Button>
    </Box>
  );
};
