import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import Spinner from 'components-lib/Spinner';
import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import { fetchUser } from 'features/user/store/userThunks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store/store';
import './App.css';
import PrivateRoutes from './routing/PrivateRoutes';
import PublicRoutes from './routing/PublicRoutes';
import { axiosClient } from 'api';

/**
 * Entrypoint for the react app
 *
 * Using basic react router setup
 * https://reacttraining.com/react-router/web/example/basic
 */
const theme = createTheme({
  palette: {
    primary: {
      main: '#6c08db',
    },
    secondary: {
      main: '#AF84D3',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
});

function App() {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);

  const config = getConfigFromSessionStorage();

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(fetchUser()).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cookieName = Cookies.get(axiosClient.defaults.xsrfCookieName);
    if (!cookieName) {
      axiosClient.get(`/api/csrfToken`).then((response) => {
        console.log(response);
      });
    }
  }, []);

  if (!isLoading) {
    if (!user.authenticated || !user.hasAdminAccess) {
      window.location.href = config.mainAppURL;
      return 'Redirecting...';
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Switch>
          {user.authenticated ? <PrivateRoutes /> : <PublicRoutes />}
        </Switch>
      )}
    </ThemeProvider>
  );
}

export default App;
