/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { fetchUser } from '../features/user/store/userThunks';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingStateEnum } from 'models/enums';
import { RootState } from 'store/models';

/**
 * Route to protect parts of app that you need to be signed in for
 * https://reacttraining.com/react-router/web/example/auth-workflow
 *
 * Dispatches fetchUser action to get user info
 * Redux state will set authenticated to true if a user is signed in
 *
 * If user is not signed in will be redirected to the login screen
 *
 * @param {Object} route passthrough for successful route
 */
export const PrivateRoute = (props: any) => {
  const { user, loading, authenticated } = useSelector(
    (state: RootState) => state.user,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authenticated && loading === LoadingStateEnum.IDLE) {
      dispatch(fetchUser());
    }
  }, [authenticated, dispatch, loading, user]);

  const { children, ...rest } = props;

  if (loading === LoadingStateEnum.PENDING) {
    return <div>Logging in</div>;
  }
  return loading === LoadingStateEnum.IDLE ? (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  ) : (
    <div>Logging in</div>
  );
};
