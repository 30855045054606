import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api';
import { ErrorMsgsEnum, SuccessMsgEnum } from 'models/enums';
import { handleToastOnResponse } from './../../utils/toastHelper';

export const fetchFormTemplateById: any = createAsyncThunk<any, string>(
  'formTemplate/fetchFormTemplateById',
  async (formTemplateId) => {
    const response = await api.fetchFormTemplateById(formTemplateId);
    // console.log('response', response);
    return response.data;
  },
);

export const createFormTemplate: any = createAsyncThunk(
  'formTemplate/createFormTemplate',
  async (formTemplate) => {
    const response = await api.createFormTemplate(formTemplate);

    handleToastOnResponse(
      response,
      ErrorMsgsEnum.CREATE_FORM_TEMPLATE,
      SuccessMsgEnum.CREATE_FORM_TEMPLATE,
    );

    // console.log('response', response);
    return response.data;
  },
);

export const updateFormTemplate: any = createAsyncThunk<any, any>(
  'formTemplate/updateFormTemplate',
  async (formTemplate) => {
    const response = await api.updateFormTemplate(formTemplate);

    handleToastOnResponse(
      response,
      ErrorMsgsEnum.UPDATE_FORM_TEMPLATE,
      SuccessMsgEnum.UPDATE_FORM_TEMPLATE,
    );

    // console.log('response', response);

    return response.data;
  },
);
