import React, { useState } from 'react';
import * as api from 'api';
import { FormInputField, FormSelectField, SelectOptions } from 'components-lib';
import Spinner from 'components-lib/Spinner';
import { ssoSignUpSchema } from 'features/validations/userSchema';
import { Form, Formik, FormikHelpers } from 'formik';
import { DEFAULT_ISSUER } from 'models/constants';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes';

interface SsoFormValues {
  email: string;
  given_name: string;
  family_name: string;
  userRoles: string[];
  issuer: string;
}

//TODO: need to be refactored after further discussion  for better user experience and new user roles logic
//suggestion - remove event read roles from checkboxes and leave dropdown for them(now only one is selected)

export const CreateSSOUserForm = () => {
  const { push } = useHistory();

  const [idp_options, setIdp_options] = useState<SelectOptions[]>([
    { value: DEFAULT_ISSUER, name: DEFAULT_ISSUER },
  ]);
  const [isFetchedIdp_options, setIsFetchedIdp_options] = useState<boolean>(
    false,
  );

  const onSubmit = async (
    values: SsoFormValues,
    actions: FormikHelpers<SsoFormValues>,
    push: any,
  ) => {
    const { resetForm } = actions;

    const user = {
      email: values.email.toLowerCase(),
      firstName: values.given_name,
      lastName: values.family_name,
      issuer: values.issuer,
    };
    console.log('user :>> ', user);

    //create user in user table
    const responseForCreateUser = await api.createUser(user);

    values.userRoles.push(values.issuer);
    values.userRoles.push(values.email.split('@')[1].toLowerCase());

    await api.createUserRole({
      userId: responseForCreateUser.data,
      userRoles: [...new Set<string>(values.userRoles)],
      eventId: undefined,
    });

    resetForm();
    //TODO: TBD where to redirect user to user detail page or user main page

    // push(generatePath(ROUTES.dashboard.events.users.root, { eventId }));
  };

  const initialValues: SsoFormValues = {
    email: '',
    given_name: '',
    family_name: '',
    userRoles: [],
    issuer: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => onSubmit(values, actions, push)}
        validationSchema={ssoSignUpSchema}
      >
        {({
          values,
          errors,
          status,
          touched,
          resetForm,
          isSubmitting,
          dirty,
          isValid,
          setFieldValue,
        }) =>
          isSubmitting ? (
            <Spinner />
          ) : (
            <Form className="w-75">
              <FormInputField
                fieldLabel="Email"
                fieldName="email"
                type="email"
                touched={touched.email}
                errors={errors.email}
                isRequired={true}
              />

              <FormInputField
                fieldLabel="First Name"
                fieldName="given_name"
                touched={touched.given_name}
                errors={errors.given_name}
                isRequired={true}
              />

              <FormInputField
                fieldLabel="Last Name"
                fieldName="family_name"
                touched={touched.family_name}
                errors={errors.family_name}
                isRequired={true}
              />

              <button
                // style={{ display: !isFetchedIdp_options ? 'block' : 'none' }}
                className="btn btn-info my-4"
                type="button"
                disabled={!touched.email || errors.email !== undefined}
                onClick={() => {
                  const domain = values.email.split('@')[1].toLowerCase();

                  api.fetchLoginDomainsInfoByDomain(domain).then((res) => {
                    console.log('idpForDomain :>> ', res);
                    setIdp_options(
                      res.data.map(
                        (idp: {
                          type: string;
                          identity_provider: string;
                          domain: string;
                        }) => ({
                          value: idp.identity_provider,
                          name: idp.identity_provider,
                        }),
                      ),
                    );
                    setIsFetchedIdp_options(true);
                  });
                }}
              >
                Check idp for email domain
              </button>

              <div
                style={{
                  display: isFetchedIdp_options ? 'block' : 'none',
                }}
              >
                {idp_options.length > 0 ? (
                  <FormSelectField
                    fieldLabel="Identity Providers"
                    fieldName="issuer"
                    touched={touched.issuer}
                    errors={errors.issuer}
                    options={idp_options}
                    isRequired={true}
                  />
                ) : (
                  <p>
                    No identity provider for this domain. You can not create an
                    user without identity provider for domain. Please uncheck
                    sso checkbox or try with different email
                  </p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-success mt-4"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Sign Up
                </button>
                <button
                  className="btn btn-danger mt-4"
                  type="button"
                  onClick={() => {
                    resetForm({
                      values: initialValues,
                    });
                    push(ROUTES.home.users.root);
                    // console.log('values :>> ', values);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )
        }
      </Formik>
    </>
  );
};
