import { Dispatch } from 'react';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'routes';
import { updateFormTemplate } from '../formTemplate/store/formTemplateThunks';
import { formTemplateBaseForm } from 'features/formTemplate/components/constants';
import { EvalFormField } from 'features/event/models';
import { FormTemplate } from 'features/formTemplate/models';
import { notAlphanumericDashUnderscore } from 'util/regex';

/**
 * @description create base form template from form id
 * @param {string} id
 * @returns {FormTemplate}
 */
export const createTemplateBaseForm = (id: string) => {
  const baseForm = {
    formSchema: formTemplateBaseForm.formSchema,
    id,
    order: formTemplateBaseForm.order,
  };

  return baseForm;
};

/**
 * @description
 * @param {EvalFormField[]} evalForms
 * @param {string} eventId
 * @param {EvalFormField[]} oldEvalForms
 * @returns {EvalFormsArrayObj}
 */
export const createEvalFormsDTO = (
  evalForms: EvalFormField[],
  eventId: string,
  oldEvalForms: EvalFormField[],
) => {
  const oldNotDeletedForms = evalForms.filter((evalForm) => evalForm.formId);

  // new forms (all forms without an ID)
  let newForms = evalForms.filter((evalForm) => !evalForm.formId);
  newForms.forEach((newForm) => {
    const evalFormNameClean = newForm.evalForm.replace(
      notAlphanumericDashUnderscore,
      '',
    );
    const formId = `${eventId}__${evalFormNameClean}__EvalForm`;
    newForm.formId = formId;
  });

  // const formsToRename = evalForms.filter((evalForm) =>
  //   oldEvalForms.some(
  //     (oldForm) =>
  //       evalForm.formId === oldForm.formId &&
  //       evalForm.evalForm !== oldForm.evalForm,
  //   ),
  // );

  // deleted forms
  const deletedForms = oldEvalForms.filter(
    (oldForm) =>
      !oldNotDeletedForms.some(
        (evalForm) => evalForm.formId === oldForm.formId,
      ), // the id of the old form is not in the array of the forms that should not be deleted.
  );
  const deletedFormIds = deletedForms.map((form) => form.formId);

  // check for duplicate formIDs and update if any
  newForms.forEach((newForm) => {
    let counter = 0;

    const isIdInEvalForms = () =>
      oldNotDeletedForms.some((evalForm) => {
        return newForm.formId === evalForm.formId;
      });
    const isIdInDeleted = () => deletedFormIds.includes(newForm.formId);

    while (isIdInEvalForms() || isIdInDeleted()) {
      newForm.formId += counter.toString();

      counter++;
      if (counter > 50) throw new Error('Infinite loop');
    }
  });

  const newFormTemplates = newForms.map((form) =>
    createTemplateBaseForm(form.formId),
  );
  // the ids should be updated because we don't create new objects when filtering evalForms,
  // we are just creating new arrays with the same references to the objects.
  // const order = evalForms.map((evalForm) => evalForm.formId);

  return {
    newFormTemplates,

    evalFormId: evalForms,
  };
};

export const onUpdateFormTemplateSubmit = (
  jsonText: string | null,
  formId: string,
  eventId: string,
  push: any,
  dispatch: Dispatch<any>,
) => {
  // @ts-ignore
  const jsonData = JSON.parse(jsonText);

  const updatedFormTemplate: FormTemplate = {
    id: formId,
    formSchema: jsonData.formSchema,
    order: jsonData.order,
  };

  dispatch(updateFormTemplate(updatedFormTemplate));

  push(
    generatePath(ROUTES.home.events.formTemplates.root, {
      eventId: eventId,
    }),
  );
};
