import { FormInputField } from 'components-lib';
import { EventFormValues } from 'features/event/models';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';

export const createIssuerField = (
  errors: FormikErrors<EventFormValues>,
  touched: FormikTouched<EventFormValues>,
) => (
  <FormInputField
    fieldLabel="Event issuer"
    fieldName="issuer"
    errors={errors.issuer}
    touched={touched.issuer ?? false}
    isRequired={false}
  />
);
