import React from 'react';
import { AdminPermissionsEventUsers } from './AdminPermissionsEventUsers';
import { AdminPermissionsUserAccess } from './AdminPermissionsUserAccess';

export const AdminPermissions = () => {
  return (
    <>
      <AdminPermissionsEventUsers />
      <AdminPermissionsUserAccess />
    </>
  );
};
