import { GetUserIssuerAndAllowedDomainsResponseDTO } from './models/response/GetUserIssuerAndAllowedDomainsResponseDTO';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getApiRoutes } from 'api';
import { MethodEnum } from 'models/enums';

export const fetchLoginDomainsInfoByDomain = async (domainName: string) => {
  const api = await getApiRoutes();

  const response = await axios.get(`${api.sso}/${domainName}`);

  return response;
};

export const fetchAllowedDomainsAndUserIssuer = async () => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: api.sso,
  };

  const response: AxiosResponse<GetUserIssuerAndAllowedDomainsResponseDTO> = await axios(
    request,
  );

  return response;
};
