import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchEventById,
  fetchPublicEventById,
  fetchEventReadRolesByEventId,
} from 'features/event/store/eventThunks';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';
import { useParams } from 'react-router';
import { LoadingStateEnum } from 'models/enums';
import { EventLimitedAccessRoutes } from 'app/events/EventLimitedAccessRoutes';
import { EventRouting } from 'app/events/EventRouting';
import { resetEvent } from 'features/event/store/eventActions';
import { toast } from 'react-toastify';
import { DEFAULT_TOAST_AUTO_CLOSE } from 'models/constants';
import { ROUTES } from 'routes';
import Spinner from 'components-lib/Spinner';

const EventContainer = () => {
  const { eventId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const { userError, loading: loadingUser } = useSelector(
    (state: RootState) => state.user,
  );
  const { push } = useHistory();
  const { loading, eventError, event } = useSelector(
    (state: RootState) => state.event,
  );
  const isUsingRoleMapSystem = event?.featureFlags?.isUsingRoleMapSystem;

  useEffect(() => {
    if (loading === LoadingStateEnum.ERROR || eventError || userError) {
      toast.error(eventError?.message, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });
      dispatch(resetEvent());
      push(ROUTES.errorPage);
    }
  }, [userError, loading, dispatch, push, eventError]);

  useEffect(() => {
    if (eventId !== event?.id) {
      dispatch(fetchEventById(eventId));
      dispatch(fetchPublicEventById(eventId));
      dispatch(fetchEventReadRolesByEventId(eventId));
    }
  }, [dispatch, event, eventId]);

  return event && loadingUser === LoadingStateEnum.IDLE ? (
    isUsingRoleMapSystem ? (
      <EventRouting />
    ) : (
      <EventLimitedAccessRoutes />
    )
  ) : (
    <Spinner />
  );
};

export default EventContainer;
