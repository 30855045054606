import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

interface IButtonTexts {
  confirm: string;
  reject: string;
  cancel?: string;
}

interface IProps extends DialogProps {
  title?: string;
  content?: string | JSX.Element;
  texts?: IButtonTexts;
  buttons?: Array<keyof IButtonTexts>;

  /** Fired when the 'confirm' button is clicked */
  onConfirm?: () => void;
  /** Fired when the 'reject' button is clicked */
  onReject?: () => void;
  /** Fired when the cancel button is clicked on when the modal is closed */
  onCancel?: () => void;
}

const defaultButtons: IProps['buttons'] = ['confirm', 'reject', 'cancel'];
const defaultTexts: IButtonTexts = {
  confirm: 'Confirm',
  reject: 'Reject',
  cancel: 'Cancel',
};

export const ConfirmRejectCancelModal = ({
  title,
  texts = defaultTexts,
  buttons = defaultButtons,
  onConfirm,
  onReject,
  onCancel,
  open,
  content,
  ...rest
}: IProps) => {
  const [isOpen, setOpen] = useState(open);

  const handleClose = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  return (
    <Dialog {...rest} onClose={handleClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {buttons.includes('confirm') && (
          <Button onClick={onConfirm} color="success">
            {texts.confirm}
          </Button>
        )}
        {buttons.includes('reject') && (
          <Button onClick={onReject} color="error">
            {texts.reject}
          </Button>
        )}
        {buttons.includes('cancel') && (
          <Button onClick={onCancel} color="info">
            {texts.cancel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
