import { VotingRoundFormValues } from 'features/voting/models';
import { EventItem } from 'api/models';
import { DEFAULT_VOTING_ROUND_DATA } from 'features/voting/components/constants';

export function generateInitialVotingRoundFormValues(
  event: EventItem | undefined,
  roundId: string,
): VotingRoundFormValues {
  if (!event || !event.votingRounds || !roundId)
    return { ...DEFAULT_VOTING_ROUND_DATA, event, isUpdate: false };

  const {
    fieldFilters,
    stageFilters,
    evalFieldFilters,
    ...roundValues
  } = event.votingRounds[roundId];
  return {
    fieldFilters: fieldFilters ?? [],
    stageFilters: stageFilters ?? [],
    evalFieldFilters: evalFieldFilters ?? [],
    ...roundValues,
    event,
    isUpdate: true,
    roundId,
  };
}
