import { axiosCustom } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import path from 'path';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { EditorMode } from '../../CodeEditor/interfaces/EditorMode';
import { isFolder } from '../../../../util';
import { TemplateEditorTab } from '../../../Models/TemplateEditorTab.enum';
import { TEMPLATES_FOLDER } from 'models/constants';
import { TemplateType } from 'models/TemplateType';

interface IOptions {
  selectedNode: string;
  selectedTab: string;
}

export const useLoadFile = ({ selectedNode, selectedTab }: IOptions) => {
  const [loadedCode, setLoadedCode] = useState<string | undefined>(undefined);
  const [loadingFile, setLoadingFile] = useState(false);
  const [edtiorMode, setEditorMode] = useState<EditorMode>('html');

  const { mainAppURL } = getConfigFromSessionStorage();

  const loadFile = useCallback(
    async (selectedTab: string) => {
      if (!selectedNode || isFolder(selectedNode) || !selectedTab) return;

      setLoadingFile(true);

      try {
        // const url = `${mainAppURL}/public/${TemplateType.LoginPages}/${TEMPLATES_FOLDER}/${selectedNode}/${selectedTab}`;
        const url = `${mainAppURL}/public/${TemplateType.LoginPages}/${TEMPLATES_FOLDER}/${selectedNode}/${selectedTab}`;
        const request: AxiosRequestConfig = {
          method: 'GET',
          url,
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'text/plain',
            Pragma: 'no-cache',
            Expires: '0',
          },
        };

        const { data } = await axiosCustom(request);

        let extension = path.extname(url);
        let mode: EditorMode;

        switch (extension) {
          case '.html':
            mode = 'html';
            break;
          case '.css':
            mode = 'css';
            break;
          case '.json':
            mode = 'json';
            break;
          default:
            mode = 'html';
        }

        setEditorMode(mode);

        // workaround for auto-parsing json data from the browser
        if (mode === 'json' && typeof data === 'object') {
          setLoadedCode(JSON.stringify(data, undefined, 2));
        } else {
          setLoadedCode(data);
        }
      } catch (e) {
        toast.error(`Unable to load ${selectedTab}`);
        setLoadedCode('');
      } finally {
        setLoadingFile(false);
      }
    },
    [mainAppURL, selectedNode],
  );

  return { loadedCode, loadingFile, edtiorMode, loadFile };
};
