import { uploadFileToS3 } from 'api';
import { getS3SignedUrlForPublicResourcesUpload } from 'api/publicResources';
import { TEMPLATES_FOLDER, TEMPLATE_FILE_NAMES } from 'models/constants';
import { TemplateType } from 'models/TemplateType';

import path from 'path';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { DomainProperties } from './useLoadDomainSettings';

export const useHandleSaveDomainSettings = (
  selectedNode: string,
  triggerReload: () => void,
) => {
  const [saved, setSaved] = useState(true); // Initially the code is unedited so it is considered saved
  const [isSaving, setSaving] = useState(false);

  const saveDomainSettings = useCallback(
    async (domainSettings: DomainProperties) => {
      let toastId;
      try {
        if (!domainSettings) return;

        toastId = toast.info('Updating domain settings...', {
          autoClose: false,
        });

        setSaving(true);
        const fileName = path.basename(selectedNode);

        const blob = new Blob([JSON.stringify(domainSettings)]);
        const file = new File([blob], fileName);

        const filePath = `${TemplateType.CustomDomains}/${TEMPLATES_FOLDER}/${selectedNode}/${TEMPLATE_FILE_NAMES.DOMAIN_SETTINGS}`;
        const response = await getS3SignedUrlForPublicResourcesUpload(
          file.type,
          filePath,
        );
        const url = response.data as string;
        await uploadFileToS3(url, file);

        setSaved(true);
        if (triggerReload) triggerReload();

        toast.success('Domain settings updated');
      } catch (e) {
        toast.error(`${JSON.stringify(e)}`);
      } finally {
        if (toastId) toast.dismiss(toastId);
        setSaving(false);
      }
    },
    [selectedNode, triggerReload],
  );

  return { saved, setSaved, saveDomainSettings, isSaving };
};
