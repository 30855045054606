import { getLoginPageTemplateMap } from 'api/loginPageTemplates';
import { useEffect, useState } from 'react';

export const useLoadTemplateMap = (templateId: string) => {
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState('');
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const { data: templateMap } = await getLoginPageTemplateMap(templateId);
        setData(templateMap);
      } catch (e) {
        setLoadingError(JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    })();
  }, [templateId]);

  return { loading, loadingError, data };
};
