import { ErrorPage } from 'app/ErrorPage';
import { NoMatch } from 'app/NoMatch';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes';

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path={`${ROUTES.errorPage}`}>
        <ErrorPage />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}

export default PublicRoutes;
