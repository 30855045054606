import { DEFAULT_ITEM_SEPARATOR } from 'models/constants';
import { SubmissionTab, SubmissionTabField } from 'features/event/models';

export function createSubmissionObjArrFromField(
  viewSubmissionTabs: SubmissionTabField[],
): SubmissionTab[] {
  const result: SubmissionTab[] = [];

  viewSubmissionTabs.forEach(({ forms, tabName }) => {
    const currentTab: SubmissionTab = {
      forms: forms.split(DEFAULT_ITEM_SEPARATOR).map((s) => s.trim()),
      tabName,
    };
    result.push(currentTab);
  });

  return result as SubmissionTab[];
}
