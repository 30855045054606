import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalProps,
  ProgressBar,
  Collapse,
} from 'react-bootstrap';

interface IProps extends ModalProps {
  total: number;
  completed: number;
  failed: number;
  failedUploads: Array<string>;
}

export const ProgressModal = ({
  total,
  completed,
  failed,
  failedUploads,
  modalTitle,
  ...props
}: IProps) => {
  const [isOpen, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const buttonsDisabled = total > completed + failed;

  return (
    <>
      <Modal
        {...props}
        backdrop={buttonsDisabled ? 'static' : true}
        show={isOpen}
        onHide={closeModal}
      >
        <Modal.Header closeButton={!buttonsDisabled}>
          <Modal.Title>
            {modalTitle ? modalTitle : 'Uploading files...'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Progress: {`${completed + failed}/${total}`}</div>
          <ProgressBar>
            <ProgressBar variant="success" max={total} now={completed} />
            <ProgressBar variant="danger" max={total} now={failed} />
          </ProgressBar>
          {failedUploads.length > 0 && (
            <>
              <Button
                className="mt-2"
                size="sm"
                onClick={() => setExpanded(!expanded)}
                aria-controls="example-collapse-text"
                aria-expanded={expanded}
              >
                {expanded ? 'Hide' : 'Show'} failed uploads
              </Button>
              <Collapse in={expanded}>
                <div id="example-collapse-text">
                  {failedUploads.map((filename, index) => (
                    <p key={index}>{filename}</p>
                  ))}
                </div>
              </Collapse>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={closeModal}
            disabled={buttonsDisabled}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProgressModal;
