import { appConfig } from 'index';
import { useParams } from 'react-router-dom';
import { IRouteParams } from 'routes/models/IRouteParams';

export const useFileExplorerProps = () => {
  const { eventId } = useParams<IRouteParams>();
  const pathPrefix = `${appConfig.s3.CUSTOM_PUBLIC_PREFIX}/${eventId}/`;

  return { eventId, pathPrefix };
};
