import { EvalPermissions, FeatureFlags } from 'features/event/models';

/**
 * @description transfer {[key: string]: boolean; } object to string array for represent in event create/update form
 * @export
 * @param {{
 *   [key: string]: boolean;
 * }} obj // set obj to any because of Typescript error
 * @returns {string[]}
 */

export function filterKeysWithTrueValue<
  T extends FeatureFlags | EvalPermissions
>(obj: T): string[] {
  const result: string[] = [];
  if (!obj) return result;
  Object.entries(obj).forEach(([key, value]) => {
    if (value) result.push(key);
  });
  return result;
}
