import { EventRoleTypeEnum } from 'models/enums';
import React, { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { MultiRolesForm } from '../MultipleRolesCard/MultiRolesForm';

interface IProps extends ModalProps {}

export const BulkAddDomainRolesModal = ({ ...props }: IProps) => {
  const [isOpen, setOpen] = useState(true);
  const { event } = useSelector((state: RootState) => state.event);

  if (!event?.id) return <>Error: eventId is required</>;

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal {...props} backdrop="static" show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add multiple domain roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MultiRolesForm
            eventId={event.id}
            type={EventRoleTypeEnum.DOMAIN}
            onSuccess={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BulkAddDomainRolesModal;
