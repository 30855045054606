import {
  Alert,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as api from 'api';
import Spinner from 'components-lib/Spinner';
import { EventRole } from 'features/event/models';
import { eventSlice } from 'features/event/store/eventSlice';
import { DEFAULT_SEPARATOR } from 'models/constants';
import { EventRoleTypeEnum } from 'models/enums';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/store';
import { useCreateRoleCheckboxes } from '../hooks/useCreateRoleCheckboxes';
import { RoleActionsInputs } from '../models/RoleActionsInputs';

interface IProps {
  eventId: string;
  type: EventRoleTypeEnum;
  title?: string;
  onSuccess?: () => void;
}

export const MultiRolesForm = ({ eventId, title, type, onSuccess }: IProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [submitError, setSubmitError] = React.useState('');
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RoleActionsInputs>({
    defaultValues: {
      actions: {
        read: false,
        submit: false,
        submitEvals: false,
        viewAllEvals: false,
        viewAllSubmissions: false,
        voting: false,
        write: false,
      },
      roleNames: '',
    },
  });

  const checkboxes = useCreateRoleCheckboxes(control);

  const onSubmit: SubmitHandler<RoleActionsInputs> = async (data) => {
    setSubmitError('');
    setIsSubmitting(true);

    try {
      const { actions, roleNames } = data;
      if (!roleNames) throw new Error('Please specify a role name');

      const roleNamesArr = roleNames
        .split(DEFAULT_SEPARATOR)
        .map((name) => name.trim());

      const roles: EventRole[] = roleNamesArr.map((roleName) => ({
        roleName,
        actions,
        roleType: type,
        isEditable: true,
      }));
      const copyOfRoles = [...roles];
      await api.createReplaceMultipleRoles(eventId, copyOfRoles);

      dispatch(eventSlice.actions.addUpdateMultipleRoles(roles));

      if (onSuccess) onSuccess();
    } catch (e) {
      setSubmitError(`${e}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const Textarea = styled('textarea')`
    height: 100%;
  `;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Box>
          <Stack direction="row">
            <Stack>
              <Typography variant="h6">Names:</Typography>
              <Textarea {...register('roleNames', { required: true })} />
            </Stack>
            <Stack>
              <Typography variant="h6">Actions:</Typography>
              <Box>{checkboxes}</Box>
            </Stack>
          </Stack>

          <Stack direction="row">
            <Button
              size="small"
              variant="contained"
              color="success"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => reset()}
              disabled={isSubmitting}
            >
              Reset
            </Button>
          </Stack>

          <Box>
            {isSubmitting && <Spinner />}
            {submitError && <Alert severity="error">{submitError}</Alert>}
            {errors.roleNames && (
              <Alert severity="error">Please select a name</Alert>
            )}
          </Box>
        </Box>
      </Container>
    </form>
  );
};
