import React from 'react';
import { FormikErrors, useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

interface Props {
  fieldLabel: string;
  isRequired: boolean;
  errors: FormikErrors<any>;
}

export const DatePickerField = ({ ...props }: Props) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <div className="form-group row mt-3">
      <label className="col-sm-4 col-form-label" htmlFor={props.fieldLabel}>
        {props.fieldLabel}{' '}
        {props.isRequired && <span className="text-danger"> *</span>}
      </label>
      <div className="col-sm-8">
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
        />
        <div className="invalid-feedback d-block">{props.errors}</div>
      </div>
    </div>
  );
};
