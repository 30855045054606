import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import logo from '../../../src/logo.svg';
import { DashboardSidebar } from './DashboardSidebar';
import { EventSidebar } from './EventSidebar';
import { UserSidebar } from './UserSidebar';

/**
 * Sidebar that displays different options depending on location
 * https://reacttraining.com/react-router/web/example/sidebar
 *
 * Options also highlight current location by setting active class
 */
export const Sidebar = () => {
  let history = useHistory();

  return (
    <ul
      className="navbar-nav bg-dark sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/** Sidebar brand */}
      <a
        className="sidebar-brand d-flex align-items-center justify-content-start mb-1"
        href="/"
      >
        <div className="sidebar-brand-icon">
          <img src={logo} alt="HelloIgnite!" height="60" />
        </div>
        <div className="sidebar-brand-text mx-3">
          <h4 className="mb-0">Admin Portal</h4>
          <p className="m-0 font-italic text-gray-800 font-weight-light">
            Welcome
          </p>
        </div>
      </a>

      <div className="sidebar-sticky">
        <ul className="nav flex-column text-center">
          <li className="nav-item mb-3">
            <button
              className="btn bg-white text-dark rounded-pill px-3 py-1"
              onClick={() => history.push(`${ROUTES.home.root}`)}
            >
              Portal Home
            </button>
          </li>

          <Switch>
            <Route path={`${ROUTES.home.events.withId}`}>
              <EventSidebar />
            </Route>
            <Route path={`${ROUTES.home.users.withId}`}>
              <UserSidebar />
            </Route>
            <Route path={`${ROUTES.home.root}`}>
              <DashboardSidebar />
            </Route>
          </Switch>
          {/*
          <li className="nav-item">
            <button
              className="btn btn-link nav-link"
              onClick={() => history.goBack()}
            >
              Go back
            </button>
          </li>

          */}
          {/* <li className="nav-item">
            <Link
              className="pill-btn text-white m-3 small"
              to={`${ROUTES.logout}`}
            >
              Sign out
            </Link>
          </li> */}
          {/* TODO: add sign out when fixed */}
        </ul>
      </div>
    </ul>
  );
};
