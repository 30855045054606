import { CreateUserForm } from 'features/user/components/CreateUser/forms/CreateUserForm';
import React, { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

interface IProps extends ModalProps {}

// TODO: add validation
export const CreateUserModal = (props: IProps) => {
  const [isOpen, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal {...props} backdrop="static" show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateUserForm onCancel={closeModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateUserModal;
