import { RoleActions } from 'features/event/models';

export const roleActionsLabels: Record<keyof RoleActions, string> = {
  read: 'Site access',
  submit: 'Submit form',
  viewAllSubmissions: 'View all submissions',
  voting: 'PairMatrix voting',
  submitEvals: 'Submit all evals',
  viewAllEvals: 'View all evals',
  write: 'Admin',
};
