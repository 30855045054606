import { Field } from 'formik';
import React from 'react';
import { CheckboxWithLabel } from 'formik-mui';

interface IProps {
  fieldLabel: string;
  fieldName: string;
  fieldValue?: string;
}

export const FormSingleCheckboxField = ({
  fieldLabel,
  fieldName,
  fieldValue,
}: IProps) => {
  return (
    <div className="form-group mb-0">
      <div className="form-check p-0 font-italic">
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          size="small"
          className="form-check-input p-0 ml-2 mr-1"
          color="primary"
          name={fieldName}
          value={fieldValue}
          Label={{ label: fieldLabel }}
        />
      </div>
    </div>
  );
};
