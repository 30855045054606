import { TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useCallback, useMemo } from 'react';
import TabContext from '@mui/lab/TabContext';
import { TemplateEditorTab } from '../../../Models/TemplateEditorTab.enum';

interface IOptions {
  selectedTab: TemplateEditorTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<TemplateEditorTab>>;
  editor: JSX.Element;
  applyToSettings: JSX.Element;
  showApplyToTab?: boolean;
}

// Note: this component uses the tabs from the 'lab' package of mui.
export const useTemplateEditorTabs = ({
  selectedTab,
  setSelectedTab,
  editor,
  applyToSettings,
  showApplyToTab,
}: IOptions) => {
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: TemplateEditorTab) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab],
  );

  const tabs = useMemo(
    () => (
      <Box sx={{ width: '100%' }}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Custom domain tabs">
              <Tab label="HTML" value={TemplateEditorTab.HTML} />
              <Tab label="CSS" value={TemplateEditorTab.CSS} />
              {showApplyToTab && (
                <Tab label="Apply to" value={TemplateEditorTab.APPLY_TO} />
              )}
            </TabList>
            <TabPanel value={TemplateEditorTab.HTML}>{editor}</TabPanel>
            <TabPanel value={TemplateEditorTab.CSS}>{editor}</TabPanel>
            <TabPanel value={TemplateEditorTab.APPLY_TO}>
              {applyToSettings}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    ),
    [selectedTab, handleChange, showApplyToTab, editor, applyToSettings],
  );

  return { tabs, selectedTab };
};
