import axios, { AxiosRequestConfig } from 'axios';
import AWS from 'aws-sdk';
import { generatePath } from 'react-router';

import { axiosClient, getApiRoutes } from 'api';
import { MethodEnum } from 'models/enums';
import { S3MoveCopyOperation } from './models/S3MoveCopyOperation';

export const getS3SignedUrlForStaticFilesUpload = async (
  contentType: string,
  s3Key: string,
  eventId: string,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.PUT,
    url: generatePath(api.eventStaticFiles, {
      eventId: eventId,
    }),
    params: { s3Key, contentType },
  };

  const response = await axiosClient(request);

  return response;
};

export const getStaticFilesList = async (
  eventId: string,
  path: string,
  delimiter = '/',
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: generatePath(api.eventStaticFiles, {
      eventId: eventId,
    }),
    params: { path, delimiter },
  };
  const response = await axios.request<AWS.S3.ListObjectsV2Output>(request);

  return response.data;
};

export const createStaticFilesFolder = async (
  eventId: string,
  folderPath: string,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: generatePath(api.eventStaticFiles, {
      eventId: eventId,
    }),
    params: { folderPath },
  };
  const response = await axios.request<AWS.S3.PutObjectOutput>(request);

  return response.data;
};

export const deleteObjects = async (eventId: string, deletePaths: string[]) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.DELETE,
    url: generatePath(api.eventStaticFiles, {
      eventId: eventId,
    }),
    data: {
      deletePaths,
    },
  };
  const response = await axiosClient.request<AWS.S3.DeleteObjectsOutput>(
    request,
  );

  return response.data;
};

export const copyObject = async (
  eventId: string,
  sourcePath: string,
  destinationPath: string,
) => {
  return await moveCopyObject(eventId, {
    type: 'COPY',
    sourcePath,
    destinationPath,
  });
};

export const moveObject = async (
  eventId: string,
  sourcePath: string,
  destinationPath: string,
) => {
  return await moveCopyObject(eventId, {
    type: 'MOVE',
    sourcePath,
    destinationPath,
  });
};

const moveCopyObject = async (
  eventId: string,
  operation: S3MoveCopyOperation,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.PATCH,
    url: generatePath(api.eventStaticFiles, {
      eventId: eventId,
    }),
    data: operation,
  };
  const response = await axiosClient.request<AWS.S3.DeleteObjectsOutput>(
    request,
  );

  return response.data;
};
