import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomRoles from './CustomRoles';
import DomainRoles from './DomainRoles';
import IssuerRoles from './IssuerRoles';
import * as api from 'api';

const Roles = () => {
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [allowedIssuers, setAllowedIssuers] = useState<string[]>([]);

  useEffect(() => {
    const getAllowedDomainsAndUserIssuer = async () => {
      try {
        const response = await api.fetchAllowedDomainsAndUserIssuer();
        return response.data;
      } catch (error) {}
    };

    getAllowedDomainsAndUserIssuer().then((result) => {
      if (result) {
        setAllowedDomains(result.allowedDomainsByUserIssuer);
        setAllowedIssuers(result.allowedIssuers);
      }
    });
  }, []);

  return (
    <Stack sx={{ width: '100%' }}>
      <IssuerRoles allowedIssuers={allowedIssuers} />
      <DomainRoles allowedDomains={allowedDomains} />
      <CustomRoles />
    </Stack>
  );
};

export default Roles;
