import React from 'react';
import { useLocation } from 'react-router';

/**
 * Catch all for if a route is not matched
 */
export const NoMatch = () => {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
