import { CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import * as api from 'api';
import { EventRole } from 'features/event/models';
import { eventSlice } from 'features/event/store/eventSlice';
import { generateRoleKey } from 'features/utils/generateRoleKey';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/store';
import { useCreateRoleCheckboxes } from './hooks/useCreateRoleCheckboxes';
import { RoleActionsInputs } from './models/RoleActionsInputs';

interface IProps {
  eventId: string;
  eventRole: EventRole;
  isNew?: boolean;
  hideName?: boolean;
  customName?: string; // Used to replace the roleName, in that case the roleName is not editable.
  isEditable?: boolean;
  onSuccess?: () => void;
}

export const RoleForm = ({
  eventId,
  eventRole,
  hideName,
  customName,
  isEditable = true,
  onSuccess,
}: IProps) => {
  const { actions, roleName } = eventRole;
  const [isSaving, setIsSaving] = React.useState(false);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RoleActionsInputs>({
    defaultValues: {
      roleName,
      actions,
    },
  });

  /** save (create/replace/update) logic */
  const onSubmit: SubmitHandler<RoleActionsInputs> = async (data) => {
    const updatedRole: EventRole = { ...eventRole, ...data };

    // when doing a rename, we are deleting the old role and creating a new one.
    const isRenamed = roleName && roleName !== updatedRole.roleName;

    setIsSaving(true);

    try {
      let roleKeyToDelete;
      if (isRenamed) {
        roleKeyToDelete = generateRoleKey(eventRole, eventId);

        await api.udpateSingleRole(eventId, roleName, updatedRole);

        dispatch(eventSlice.actions.removeRole(roleKeyToDelete));
        dispatch(eventSlice.actions.addUpdateRole(updatedRole));
      } else {
        await api.createReplaceSingleRole(eventId, updatedRole);

        dispatch(eventSlice.actions.addUpdateRole(updatedRole));
      }

      toast.success(`Saved`);
      if (onSuccess) onSuccess();
    } catch (e) {
      toast.error(`${e}`);
    } finally {
      setIsSaving(false);
    }
  };

  const checkboxes = useCreateRoleCheckboxes(control, isEditable);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {!customName ? (
            <Controller
              name={`roleName`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <TextField
                    label="Role name"
                    variant="outlined"
                    {...field}
                    disabled={!isEditable}
                    error={!!errors?.roleName}
                    helperText={errors?.roleName && 'Please enter a role name'}
                  />
                </>
              )}
            />
          ) : (
            <h4>{customName}</h4>
          )}
        </Box>

        <Box sx={{ py: 1 }}>{checkboxes}</Box>

        {isEditable && (
          <>
            {!isSaving ? (
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={isSaving}
                >
                  Save
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => reset()}
                  disabled={isSaving}
                >
                  Reset
                </Button>
              </Box>
            ) : (
              <Box>{isSaving && <CircularProgress size={`1.5rem`} />}</Box>
            )}
          </>
        )}
      </form>
    </>
  );
};
