import { getConfigInfo } from './configInfo';

export const getApiRoutes = async () => {
  let cachedConfig = sessionStorage.getItem('configInfo');
  let config;

  if (cachedConfig) {
    config = JSON.parse(cachedConfig);
  } else {
    try {
      config = await getConfigInfo();
    } catch (error) {
      throw new Error(`Failed to load config: ${error}`);
    }
  }

  const { adminPortalURL, mainAppURL } = config;

  const apiBaseUrl = `${adminPortalURL}/api`;

  const events = `${apiBaseUrl}/events`;
  const formTemplates = `${apiBaseUrl}/formTemplates`;
  const users = `${apiBaseUrl}/users`;
  const searchForUsers = `${apiBaseUrl}/users/searchForUsers`;
  const cognitoUsers = `${apiBaseUrl}/cognitoUsers`;
  const sso = `${apiBaseUrl}/sso`;
  const cognitoUserSignUp = `${cognitoUsers}/signUp`;
  const eventStaticFiles = `${apiBaseUrl}/events/:eventId/staticFiles`;
  const publicResources = `${apiBaseUrl}/publicResources`;
  const eventReadRoles = 'eventReadRoles';
  const userRoles = 'userRoles';
  const getUsersWithRoles = 'getUsersWithRoles';
  const roles = 'roles';
  const permissionsUser = `${apiBaseUrl}/permissions/user`;
  const loginPageTemplates = `${apiBaseUrl}/loginTemplates`;

  const mainAppGetUser = `${mainAppURL}/api/v1/user`;

  return {
    apiBaseUrl,
    events,
    formTemplates,
    users,
    mainAppGetUser,
    searchForUsers,
    cognitoUsers,
    sso,
    cognitoUserSignUp,
    eventStaticFiles,
    eventReadRoles,
    userRoles,
    getUsersWithRoles,
    roles,
    permissionsUser,
    loginPageTemplates,
    publicResources,
  };
};
