import axios, { AxiosRequestConfig } from 'axios';
import path from 'path';
import AWS from 'aws-sdk';

import { axiosClient, getApiRoutes, uploadFileToS3 } from 'api';
import { MethodEnum } from 'models/enums';

export const getPublicResourcesList = async (
  templateType: string,
  prefix: string = '',
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: api.publicResources,
    params: { path: `${templateType}/${prefix}/`, delimiter: '/' },
  };
  const response = await axios.request<AWS.S3.ListObjectsV2Output>(request);

  return response.data;
};

export const getS3SignedUrlForPublicResourcesUpload = async (
  contentType: string,
  s3Key: string,
) => {
  const api = await getApiRoutes();
  const request: AxiosRequestConfig = {
    method: MethodEnum.PUT,
    url: api.publicResources,
    params: { s3Key, contentType },
  };

  const response = await axiosClient(request);

  return response;
};

export const createPublicResourcesFolder = async (folderPath: string) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: api.publicResources,
    params: { folderPath },
  };
  const response = await axiosClient.request<AWS.S3.PutObjectOutput>(request);

  return response.data;
};

export const createPublicResourcesEmptyTextFile = async (
  pathWithFileName: string,
  contentType?: string,
) => {
  await createPublicResourcesFile(pathWithFileName, ['']);
};

export const createPublicResourcesFile = async (
  pathWithFileName: string,
  content: BlobPart[],
  contentType?: string,
) => {
  const fileName = path.basename(pathWithFileName);

  const blob = new Blob(content);
  const file = new File([blob], fileName, { type: contentType });

  const filePath = pathWithFileName;
  const response = await getS3SignedUrlForPublicResourcesUpload(
    file.type,
    filePath,
  );
  const url = response.data as string;

  await uploadFileToS3(url, file);
};

export const createCustomDomainJsonFile = async (pathWithFileName: string) => {
  const jsonBasicContent = `{
    "title": "",
    "faviconURL": ""
  }
  `;

  await createPublicResourcesFile(pathWithFileName, [jsonBasicContent]);
};

export const uploadPublicResourcesFile = async (
  file: File,
  pathToFolder: string,
) => {
  const directory = path.dirname(pathToFolder);

  const response = await getS3SignedUrlForPublicResourcesUpload(
    file.type,
    `${directory}/${file.name}`,
  );
  const url = response.data as string;

  await uploadFileToS3(url, file);
};

export const deletePublicResourcesObjects = async (deletePaths: string[]) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.DELETE,
    url: api.publicResources,
    data: {
      deletePaths,
    },
  };
  const response = await axiosClient.request<AWS.S3.DeleteObjectsOutput>(
    request,
  );

  return response.data;
};
