import prettier from 'prettier';
import htmlParser from 'prettier/parser-html';
import cssParser from 'prettier/parser-postcss';
import jsonParser from 'prettier/parser-babel';
import { useState } from 'react';
import { EditorMode } from '../../CodeEditor/interfaces/EditorMode';

interface IPrettierError {
  loc: {
    start: {
      line: number;
      column: number;
    };
    end: {
      line: number;
      column: number;
    };
  };
  codeFrame: string;
}

export const useFormatCode = (
  editorRef: React.MutableRefObject<null>,
  editorMode: EditorMode,
) => {
  const [formatCodeError, setFormatCodeError] = useState<
    IPrettierError | undefined
  >();

  const resetFormatCodeError = () => {
    setFormatCodeError(undefined);
  };

  const handleFormatCode = () => {
    resetFormatCodeError();

    try {
      if (editorRef.current) {
        const code = (editorRef.current as any).editor?.getValue();
        if (typeof code === 'string') {
          const formatted = prettier.format(code, {
            trailingComma: 'all',
            tabWidth: 2,
            semi: true,
            singleQuote: true,
            endOfLine: 'lf',
            arrowParens: 'always',
            parser: editorMode,
            plugins: [htmlParser, cssParser, jsonParser],
            'html.format.wrapAttributes': 'force-aligned',
          } as any);

          (editorRef.current as any).editor?.setValue(formatted);
        }
      }
    } catch (e) {
      const error = e as IPrettierError;

      setFormatCodeError(error);
    }
  };

  return { handleFormatCode, formatCodeError, resetFormatCodeError };
};
