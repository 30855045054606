import { VotingRoundData } from '../models/VotingRoundData';

export const DEFAULT_VOTING_ROUND_DATA: VotingRoundData = {
  roundId: '',
  addModifiedSubmissions: true,
  addModifiedSubmissionsWhileActive: true,
  addNewSubmissionsWhileActive: true,
  question: '',
  tabName: '',
  selfVote: false,
  smartVote: true,
  displayWhenInactive: false,
  startTime: null,
  endTime: null,
  stageFilters: [], // checkboxes with stage names then transform to indexes
  fieldFilters: [], // get fields from main form order, value is user input and excludedFromRound is checkbox
  evalFieldFilters: [], //get eval form names from event data,field and value are inputs,excludedFromRound is checkbox
};
