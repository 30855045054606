import { Field, FormikErrors, FormikTouched } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

interface IProps {
  fieldLabel: string;
  fieldName: string;
  type?: string;
  errors?:
    | string
    | string[]
    | FormikErrors<any>[]
    | FormikErrors<any>
    | undefined;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  isRequired?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
}

//TODO: add placeholder for inputs
//TODO: add tooltip for inputs
// <a href="#" data-toggle="tooltip" title="Some tooltip text!">
//                 Hover over me
//               </a>

//               <div className="tooltip bs-tooltip-top" role="tooltip">
//                 <div className="arrow"></div>
//                 <div className="tooltip-inner">Some tooltip text!</div>
//               </div>

export const FormInputField = ({
  fieldLabel,
  fieldName,
  type,
  errors,
  touched,
  isRequired,
  noLabel,
  disabled,
}: IProps) => {
  return (
    <div className="form-group">
      <Field
        component={TextField}
        label={fieldLabel}
        fullWidth
        className={errors && touched ? ' is-invalid bg-white' : 'bg-white'}
        autoComplete="off"
        name={fieldName}
        disabled={disabled}
        type={type ? type : 'text'}
        variant="outlined"
        required={isRequired ? true : false}
      />
    </div>
  );
};
