import { RoleActions } from 'features/event/models';
import { Control, Controller } from 'react-hook-form';
import { roleActionsLabels } from '../constants/roleActionsLabels';
import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { RoleActionsInputs } from '../models/RoleActionsInputs';

export const useCreateRoleCheckboxes = (
  control: Control<RoleActionsInputs, object>,
  isInEditMode: boolean = true,
) => {
  const checkboxes = useMemo(
    () =>
      Object.entries(roleActionsLabels).map(([actionKey, actionLabel]) => (
        <div key={actionKey}>
          <Controller
            name={`actions.${actionKey as keyof RoleActions}`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ ml: 0, mb: 0 }}
                label={actionLabel}
                control={
                  <Checkbox
                    sx={{ p: 0.5 }}
                    {...field}
                    disabled={!isInEditMode}
                    checked={field.value}
                  />
                }
              />
            )}
          />
        </div>
      )),
    [control, isInEditMode],
  );

  return checkboxes;
};
