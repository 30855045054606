import { HeadCell } from 'components-lib/GenericTable/models/HeadCell';
import { UserData } from 'api/models/SearchForUsersResponseDTO';
import { rolesRenderer } from 'components-lib/GenericTable/util/renderers/rolesRenderer';

export const userDataDefinition: HeadCell<UserData> = {
  id: {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'User Id',
  },
  email: {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'e-mail',
  },
  firstName: {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  lastName: {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  issuer: {
    id: 'issuer',
    numeric: false,
    disablePadding: false,
    label: 'Issuer',
  },
  roles: {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Roles',
    // eslint-disable-next-line react/display-name
    renderer: rolesRenderer,
  },
};
