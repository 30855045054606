import { NotificationField } from 'features/event/models';

/**
 * @description transfer NotificationField[] to {  [key: string]: { [key: string]: { id: string; type: string } };
}
 * @param {NotificationField[]} notificationFields
 * @returns {  [key: string]: { [key: string]: { id: string; type: string } }}
 */
export function createObjectFromNotificationFieldsArray(
  notificationFields: NotificationField[],
): {
  [key: string]: { [key: string]: { id: string; type: string } };
} {
  const result: {
    [key: string]: { [key: string]: { id: string; type: string } };
  } = {};

  notificationFields.forEach((notificationField) => {
    const formFieldName = notificationField.mainFormTemplateFieldName;

    const formFieldNameValue = result.hasOwnProperty(formFieldName)
      ? result[formFieldName]
      : {};

    //add new field data to form field name data map
    formFieldNameValue[notificationField.mainFormTemplateFieldValue] = {
      id: notificationField.id,
      type: notificationField.type,
    };

    //update form field name map
    result[formFieldName] = formFieldNameValue;
  });
  // console.log(`result createObjectFromNotificationFieldsArray`, result);

  return result;
}
