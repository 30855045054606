import { EventItem } from 'api/models';
import { NotificationField } from 'features/event/models';

/**
 * @description transfer {[key: string]: { [key: string]: { id: string; type: string } };} to NotificationField[]
 * @param {EventItem} event
 * @returns {NotificationField[]}
 */
export function createArrayFromNotificationFieldsObject(
  event: EventItem,
): NotificationField[] {
  const result: NotificationField[] = [];

  if (
    !event.notificationFields ||
    Object.keys(event.notificationFields).length === 0
  )
    return result;

  Object.entries(event.notificationFields).forEach(
    ([mainFormTemplateFieldName, value]) => {
      Object.entries(value).forEach(([formFieldValue, { id, type }]) => {
        const notificationFieldObj: NotificationField = {
          mainFormTemplateFieldName,
          mainFormTemplateFieldValue: formFieldValue,
          id,
          type,
        };
        result.push(notificationFieldObj);
      });
    },
  );

  return result as NotificationField[];
}
