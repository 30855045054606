import axios from 'axios';

export const fetchConfigInfo = async () => {
  if (
    process.env.REACT_APP_CONFIG_URL &&
    typeof process.env.REACT_APP_CONFIG_URL === 'string'
  ) {
    const response = await axios.get(process.env.REACT_APP_CONFIG_URL);

    return response;
  }

  throw new Error(
    'process.env.REACT_APP_CONFIG_URL is undefined not a string or empty',
  );
};

export const getConfigInfo = async () => {
  const cachedConfig = sessionStorage.getItem('configInfo');

  if (cachedConfig != null) {
    return JSON.parse(cachedConfig);
  } else {
    const configRes = await fetchConfigInfo();

    if (configRes.status === 200 && configRes.data) {
      const config = configRes.data;
      sessionStorage.setItem('configInfo', JSON.stringify(config));

      return config;
    } else {
      throw new Error('Unable to load configuration');
    }
  }
};
