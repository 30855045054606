import {
  TableSortLabel,
  TableRow,
  TableHead,
  TableCell,
  Checkbox,
  Typography,
} from '@mui/material';
import { BaseTableData } from 'api/models/SearchForUsersResponseDTO';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { HeadCell } from './models/HeadCell';
import { rolesSearch, stringSearch } from './util/searchFunctions';

type Order = 'asc' | 'desc';

interface EnhancedTableProps<T extends BaseTableData> {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headerCellDefinition: HeadCell<T>;
  visibleColumns: Array<keyof T>;
  filterRows: React.Dispatch<React.SetStateAction<T[]>>;
  rows: T[];
}

type InputValues = {
  [key: string]: string;
};

function EnhancedTableHead<T extends BaseTableData>(
  props: EnhancedTableProps<T>,
) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headerCellDefinition,
    visibleColumns,
    filterRows,
    rows,
  } = props;

  const [inputValues, setInputValues] = useState<InputValues>({});

  const { event } = useSelector((state: RootState) => state.event);

  const createSortHandler = (property: keyof T) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleFilter = () => {
    filterRows(() => rows.filter(search));
  };

  const search = (row: any) => {
    return Object.keys(inputValues).every((key) => {
      const stringToFind = inputValues[key];
      if (!stringToFind?.length) return true; // Return all results if no search term specified.
      const currentCellData = row[key];

      // TODO: don't hardcode, try to fix it with better typings
      if (key === 'roles') {
        return rolesSearch(stringToFind, currentCellData, event?.id);
      } else {
        return stringSearch(stringToFind, currentCellData);
      }
    });
  };

  const headCellsLocal = visibleColumns.map(
    (colName) => headerCellDefinition[colName],
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCellsLocal.map((headCell) => (
          <TableCell
            key={headCell.id.toString()}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Typography
                  sx={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Typography>
              ) : null}
            </TableSortLabel>
            <input
              type="text"
              name={headCell.id.toString()}
              value={inputValues[headCell.id.toString()] || ''}
              placeholder={`Filter by ${headCell.label}`}
              onChange={handleChange}
              onKeyUp={handleFilter}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
