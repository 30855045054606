import { useCallback } from 'react';
import { toast } from 'react-toastify';

interface IOptions {
  selectedNode: string;
  nextNode: string | undefined;
  saveCodeAsync: () => Promise<void>;
  setShowConfirmSaveModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedNode: React.Dispatch<React.SetStateAction<string>>;
}

export const useConfirmSaveActions = ({
  selectedNode,
  nextNode,
  saveCodeAsync,
  setSaved,
  setSelectedNode,
  setShowConfirmSaveModal,
}: IOptions) => {
  const onConfirmSave = useCallback(async () => {
    try {
      setShowConfirmSaveModal(false);
      await saveCodeAsync();

      if (nextNode) setSelectedNode(nextNode);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  }, [saveCodeAsync, nextNode, setSelectedNode, setShowConfirmSaveModal]);

  const onRejectSave = useCallback(() => {
    if (nextNode) {
      setShowConfirmSaveModal(false);
      setSelectedNode(nextNode);
      setSaved(true); // saved since it is a new node
    }
  }, [nextNode, setSaved, setSelectedNode, setShowConfirmSaveModal]);

  const onCancel = useCallback(() => {
    setSelectedNode(selectedNode); // workaround to revert the selection of the other node
    setShowConfirmSaveModal(false);
  }, [selectedNode, setSelectedNode, setShowConfirmSaveModal]);

  return { onConfirmSave, onRejectSave, onCancel };
};
