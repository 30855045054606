import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'routes';

import Main from 'features/event/components/Main';

/**
 * Routing for event with old roles
 */

export function EventLimitedAccessRoutes() {
  return (
    <Switch>
      <Route exact path={`${ROUTES.home.events.withId}`}>
        <Main />
      </Route>
      <Redirect to={`${ROUTES.home.events.withId}`} />
    </Switch>
  );
}
