import axios, { AxiosRequestConfig } from 'axios';
import { EventRole } from 'features/event/models';
import { MethodEnum } from 'models/enums';
import { axiosClient } from './axios';
import { getApiRoutes } from './routes';

export const createReplaceSingleRole = async (
  eventId: string,
  role: EventRole,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.PUT,
    url: `${api.events}/${eventId}/roles/${role.roleName}`,
    data: [role],
  };
  const response = await axiosClient(request);

  return response;
};

export const createReplaceMultipleRoles = async (
  eventId: string,
  roles: EventRole[],
) => {
  const api = await getApiRoutes();

  const rolesBatches = [];

  while (roles.length > 0) {
    rolesBatches.push(roles.splice(0, 15));
  }
  const requestBucket = rolesBatches.map((batch, index) => {
    const request: AxiosRequestConfig = {
      method: MethodEnum.POST,
      url: `${api.events}/${eventId}/roles`,
      data: batch,
    };
    return request;
  });
  const multiplePromises = requestBucket.map((param) => axiosClient(param));

  axios.all(multiplePromises).then(
    axios.spread((...responses) => {
      return responses;
    }),
  );
};

export const deleteRoles = async (eventId: string, roles: EventRole[]) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.DELETE,
    url: `${api.events}/${eventId}/roles`,
    data: roles,
  };
  const response = await axiosClient(request);

  return response;
};

export const udpateSingleRole = async (
  eventId: string,
  roleId: string,
  role: EventRole,
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.PATCH,
    url: `${api.events}/${eventId}/roles/${roleId}`,
    data: [role],
  };
  const response = await axiosClient(request);

  return response;
};
