import { combineReducers } from '@reduxjs/toolkit';
import eventReducer from 'features/event/store/eventSlice';
import userReducer from 'features/user/store/userSlice';
import formTemplateReducer from 'features/formTemplate/store/formTemplateSlice';

export const rootReducer = combineReducers({
  user: userReducer,
  event: eventReducer,
  formTemplate: formTemplateReducer,
});
