import { Button, Grid } from '@mui/material';
import { EventRoleTypeEnum } from 'models/enums';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useCreateRole } from '../hooks/useCreateRole';
import { useRoleCards } from '../hooks/useRoleCards';
import { RoleFormModalDialog } from '../Modals/RoleFormModalDialog';

const CustomRoles = () => {
  const { event: eventState } = useSelector((state: RootState) => state);
  const { customRoleCards } = useRoleCards(eventState);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);
  const newRole = useCreateRole(EventRoleTypeEnum.CUSTOM, ``);

  if (!eventState.event?.id) return <>No event loaded</>;

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid item lg={3}>
        <h3>Custom Roles</h3>
      </Grid>

      <Grid item lg={9}>
        <Button
          onClick={() => {
            setShowCreateRoleDialog(true);
          }}
          variant="contained"
        >
          Create custom role
        </Button>

        <Grid container>
          {customRoleCards.map((card, index) => (
            <Grid key={index} item xl={4} md={6} sm={12} sx={{ p: 0.5 }}>
              {card}
            </Grid>
          ))}
        </Grid>
      </Grid>

      {showCreateRoleDialog && (
        <RoleFormModalDialog
          eventId={eventState.event.id}
          eventRole={newRole}
          open={showCreateRoleDialog}
          onClose={() => {
            setShowCreateRoleDialog(false);
          }}
          onSuccess={() => {
            setShowCreateRoleDialog(false);
          }}
        />
      )}
    </Grid>
  );
};

export default CustomRoles;
