import React from 'react';
import { generatePath, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes';

/**
 * Sidebar displayed when in an event
 */
export function UserSidebar() {
  let { userId } = useParams<Record<string, string>>();

  return (
    <>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={generatePath(ROUTES.home.users.withId, {
            userId,
          })}
        >
          <span data-feather="file"></span>
          Users - only for preview
        </NavLink>
      </li>
    </>
  );
}
