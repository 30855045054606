import React from 'react';
import { AdminNavBar } from './AdminNavBar';
import { AdminToolsRouting } from './AdminToolsRouting';
export const AdminTools = () => {
  return (
    <>
      <AdminToolsRouting />
    </>
  );
};
