import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

interface IProps {
  fieldLabel: string;
  fieldName: string;
  type?: string;
  errors?: string | string[] | FormikErrors<any>[];
  touched?: boolean | FormikTouched<any>[];
  rows?: number | string;
  isRequired?: boolean;
  disabled?: boolean;
}

export const FormTextareaField = ({
  fieldLabel,
  fieldName,
  errors,
  touched,
  rows,
  type,
  disabled,
  isRequired,
}: IProps) => {
  return (
    <div className="form-group">
      <Field
        component={TextField}
        label={fieldLabel}
        fullWidth
        multiline
        variant="outlined"
        rows={rows}
        className={errors && touched ? ' is-invalid bg-white' : 'bg-white'}
        autoComplete="off"
        name={fieldName}
        disabled={disabled}
        type={type ? type : 'text'}
        required={isRequired ? true : false}
      />
      <ErrorMessage
        name={fieldName}
        component="div"
        className="invalid-feedback font-italic p-1"
      />
    </div>
  );
};
