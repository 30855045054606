import { AdminPermissionNamesEnum } from 'api/models/AdminPermissions/AdminPermissionNamesEnum';
import { HeadCell } from 'components-lib/GenericTable/models/HeadCell';
import { PermissionsAdminUserView } from '../models/PermissionsAdminUserView';
import { rolesRenderer } from 'components-lib/GenericTable/util/renderers/rolesRenderer';
import { booleanRenderer } from 'components-lib/GenericTable/util/renderers/booleanRenderer';

export const adminPermissionsUserDefinition: HeadCell<PermissionsAdminUserView> = {
  id: {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'id',
  },
  email: {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'e-mail',
  },
  firstName: {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  lastName: {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  issuer: {
    id: 'issuer',
    numeric: false,
    disablePadding: false,
    label: 'Issuer',
  },
  roles: {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Roles',
    // eslint-disable-next-line react/display-name
    renderer: rolesRenderer,
  },
  [AdminPermissionNamesEnum.basic_access]: {
    id: AdminPermissionNamesEnum.basic_access,
    numeric: false,
    disablePadding: false,
    label: 'Basic access',
    renderer: booleanRenderer,
  },
  [AdminPermissionNamesEnum.full_access]: {
    id: AdminPermissionNamesEnum.full_access,
    numeric: false,
    disablePadding: false,
    label: 'Full access',
    renderer: booleanRenderer,
  },
};
