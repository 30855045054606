import { EventRoleTypeEnum } from 'models/enums';
import { useMemo } from 'react';
import { createNewRole } from '../util/createNewRole';

export const useCreateRole = (
  roleType: EventRoleTypeEnum,
  roleName?: string,
) => {
  return useMemo(() => createNewRole(roleType, roleName), [roleName, roleType]);
};
