import {
  ChonkyActions,
  ChonkyIconName,
  defineFileAction,
  FileArray,
} from 'chonky';
import { useMemo } from 'react';

interface IProps {
  filesInClipboard: FileArray;
}

export const useFileActions = ({ filesInClipboard }: IProps) => {
  const RefreshFiles = defineFileAction({
    id: 'Refresh',
    button: {
      name: 'Refresh',
      toolbar: true,
      icon: ChonkyIconName.loading,
    },
  });

  const RenameFile = defineFileAction({
    id: 'Rename',
    button: {
      name: 'Rename',
      toolbar: true,
      icon: ChonkyIconName.placeholder,
    },
  });

  const PasteSelection = defineFileAction({
    id: 'Paste',
    requiresSelection: !filesInClipboard.length,
    fileFilter: () => {
      return !!filesInClipboard.length;
    },
    button: {
      name: 'Paste',
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.paste,
      group: 'Actions',
    },
  });

  const fileActionsArray = useMemo(
    () => [
      ChonkyActions.CreateFolder,
      ChonkyActions.UploadFiles,
      ChonkyActions.DeleteFiles,
      ChonkyActions.DownloadFiles,
      ChonkyActions.CopyFiles,
      ChonkyActions.MoveFiles,
      RefreshFiles,
      RenameFile,
      PasteSelection,
    ],
    [RefreshFiles, RenameFile, PasteSelection],
  );

  return {
    fileActionsArray,
    customFileActions: {
      RefreshFiles,
      RenameFile,
      PasteSelection,
    },
  };
};
