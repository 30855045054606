import axios, { InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { DEFAULT_TOAST_AUTO_CLOSE } from 'models/constants';

/** Custom axios instance to use without Authorization */
export const axiosClient = axios.create({
  xsrfCookieName: '__Host-XSRF-TOKEN',
});
export const axiosCustom = axios.create({
  withCredentials: true,
});

export const initAxiosInterceptors = () => {
  axios.interceptors.request.use(
    async (request: InternalAxiosRequestConfig) => {
      const contentType = request.headers && request.headers['Content-Type'];

      request.headers['Content-Type'] = contentType ?? 'application/json';
      request.withCredentials = true; // allow the use of cookies

      return request;
    },
    (error) => {
      console.log('error on request', error);
      toast.error(error.message, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });

      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => {
      // Edit response config
      return response;
    },
    (error) => {
      console.log('error on response', error);
      let errorMsg = 'Something went wrong';
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        //TODO: preview error.response for message
        //TODO: handle transaction errors - error.response.data.message
        errorMsg = error.response.data.message;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        errorMsg = error.message;
      }
      console.log(error.config);
      toast.error(errorMsg, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });

      return Promise.reject(error);
    },
  );
};
