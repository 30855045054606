import { DEFAULT_LDAP_PERMISSION_ACTIONS_VALUES } from 'features/event/components/constants';
import {
  LdapFieldPermissionField,
  LdapPermissionActions,
} from 'features/event/models';

/**
 * @description create ldapFieldPermission DTO from event form field array
 * @param {LdapFieldPermissionField[]} ldapFieldPermissionFields
 * @returns {{
 *   [key: string]: { [key: string]: LdapPermissionActions };
 * }}
 */
export function createObjectFromLdapFieldPermissionFieldsArray(
  ldapFieldPermissionFields: LdapFieldPermissionField[],
): {
  [key: string]: { [key: string]: LdapPermissionActions };
} {
  const result: {
    [key: string]: { [key: string]: LdapPermissionActions };
  } = {};

  ldapFieldPermissionFields.forEach((ldapPermissionField) => {
    const ldapFieldName = ldapPermissionField.ldapFieldName;
    const ldapUserGroup = ldapPermissionField.ldapUserGroup;

    const ldapFieldNameDataMap = result.hasOwnProperty(ldapFieldName)
      ? result[ldapFieldName]
      : {};

    const userGroupData = ldapFieldNameDataMap.hasOwnProperty(ldapUserGroup)
      ? ldapFieldNameDataMap[ldapUserGroup]
      : {};

    const ldapActions: any = {};

    Object.keys(DEFAULT_LDAP_PERMISSION_ACTIONS_VALUES).forEach((action) => {
      let oldPermissionArray: string[] = Object.keys(userGroupData);

      ldapActions[action] =
        ldapPermissionField.ldapActions.includes(action) ||
        oldPermissionArray.includes(action);
    });

    //add new field data to form field name data
    ldapFieldNameDataMap[ldapUserGroup] = ldapActions;

    //update form field name
    result[ldapFieldName] = ldapFieldNameDataMap;
  });

  return result;
}
