import { EventRole } from 'features/event/models';
import { EventState } from 'features/event/store/models';
import { EventRoleTypeEnum } from 'models/enums';
import React, { useMemo } from 'react';
import { RoleCardForm } from '../RoleCardForm';

/**
 *
 * @param eventState
 * @param isSuperAdmin If false, will replace the 'GENERAL' issuer role card with a 'Public access' title
 *
 * @returns
 */
export const useRoleCards = (
  eventState: EventState,
  isSuperAdmin: boolean = false,
) => {
  const { event } = eventState;
  const { id, roles } = useMemo(() => {
    if (event) {
      return event;
    } else {
      return { id: '', roles: {} };
    }
  }, [event]);

  /** Issuer cards */
  const issuerRoleCards = useMemo(
    () =>
      createRoleCards(Object.values(roles), id, {
        typeFilter: EventRoleTypeEnum.ISSUER,
      }),
    [id, roles],
  );

  /** Domain cards */
  const domainRoleCards = useMemo(
    () =>
      createRoleCards(Object.values(roles), id, {
        typeFilter: EventRoleTypeEnum.DOMAIN,
      }),
    [id, roles],
  );

  /** Custom cards */
  const customRoleCards = useMemo(
    () =>
      createRoleCards(Object.values(roles), id, {
        typeFilter: EventRoleTypeEnum.CUSTOM,
      }),
    [id, roles],
  );

  return {
    domainRoleCards,
    issuerRoleCards,
    customRoleCards,
  };
};

const createRoleCards = (
  roles: EventRole[],
  eventId: string,
  options: {
    typeFilter?: EventRoleTypeEnum;
    areRolesNew?: boolean;
  },
) => {
  let roleCardsToCreate;
  if (options?.typeFilter) {
    roleCardsToCreate = roles.filter(
      (role) => role.roleType === options.typeFilter,
    );
  } else {
    roleCardsToCreate = roles;
  }

  return roleCardsToCreate.map((role, index) => (
    <RoleCardForm
      eventId={eventId}
      eventRole={role}
      key={role.roleName + index}
    />
  ));
};
