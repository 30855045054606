import { SelectOptions } from 'components-lib/FormSelectField';
import {
  EvalPermissions,
  FeatureFlags,
  RoleActions,
  NotificationField,
  EventRoleField,
} from 'features/event/models';
import { DEFAULT_ADMIN_EVENT_ROLE, DEFAULT_USER_ROLE } from 'models/constants';
import {
  EventTypeEnum,
  LdapFieldPermissionFieldEnum,
  LdapPermissionUserGroupEnum,
  EventRoleTypeEnum,
} from 'models/enums';

export const DEFAULT_NOTIFICATION_FIELD: NotificationField = {
  mainFormTemplateFieldName: '',
  mainFormTemplateFieldValue: '',
  id: '',
  type: '',
};

export const DEFAULT_EVALS_PERMISSIONS: EvalPermissions = {
  submitEvals: false,
  viewAllEvals: false,
};

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  exportDoc: false,
  reviewSection: false,
  ideaBuildr: false,
  infoMeta: false,
  submissionCardImages: false,
  kanbanView: false,
  enablePublicSubmitApp: false,
};

export const DEFAULT_EVENT_TYPE_OPTIONS: SelectOptions[] = [
  {
    value: EventTypeEnum.CHALLENGE.toLowerCase(),
    name: EventTypeEnum.CHALLENGE,
  },
  {
    value: EventTypeEnum.NOMINATION.toLowerCase(),
    name: EventTypeEnum.NOMINATION,
  },
  { value: EventTypeEnum.FORUM.toLowerCase(), name: EventTypeEnum.FORUM },
];

export const DEFAULT_EVENT_TYPE = DEFAULT_EVENT_TYPE_OPTIONS[0].value;

export const DEFAULT_LDAP_FIELD_PERMISSION_FIELD = {
  [LdapFieldPermissionFieldEnum.LDAP_FIELD_NAME]: '',
  [LdapFieldPermissionFieldEnum.LDAP_USER_GROUP]: '',
  [LdapFieldPermissionFieldEnum.LDAP_ACTIONS]: [],
};

export const DEFAULT_LDAP_PERMISSION_ACTIONS_VALUES = {
  submitEvals: false,
  viewAllEvals: false,
  viewAll: false,
  write: false,
};

export const DEFAULT_LDAP_PERMISSION_ACTIONS_OPTIONS: SelectOptions[] =
  Object.keys(DEFAULT_LDAP_PERMISSION_ACTIONS_VALUES).map(
    (fieldName: string) => ({
      value: fieldName,
      name: fieldName,
    }),
  );

export const DEFAULT_LDAP_PERMISSION_USER_GROUP_OPTIONS: SelectOptions[] = [
  {
    value: LdapPermissionUserGroupEnum.USERS,
    name: LdapPermissionUserGroupEnum.USERS,
  },
  {
    value: LdapPermissionUserGroupEnum.USERS_MANAGERS,
    name: LdapPermissionUserGroupEnum.USERS_MANAGERS,
  },
];

export const DEFAULT_ROLE_ACTIONS: RoleActions = {
  submit: false,
  viewAllSubmissions: false,
  voting: false,
  write: false,
  read: false,
  viewAllEvals: false,
  submitEvals: false,
};

export const DEFAULT_ROLE_ACTIONS_KEYS: string[] =
  Object.keys(DEFAULT_ROLE_ACTIONS);

export const DEFAULT_DOMAIN_ROLE: EventRoleField = {
  roleName: '',
  actions: [],
  roleType: EventRoleTypeEnum.DOMAIN,
  isEditable: true,
};

export const DEFAULT_CUSTOM_ROLE: EventRoleField = {
  roleName: '',
  actions: [],
  roleType: EventRoleTypeEnum.CUSTOM,
  isEditable: true,
};

export const DEFAULT_ISSUER_ROLE: EventRoleField = {
  roleName: '',
  actions: [],
  roleType: EventRoleTypeEnum.ISSUER,
  isEditable: true,
};

export const RESTRICTED_ROLE_NAMES = [
  DEFAULT_ADMIN_EVENT_ROLE,
  DEFAULT_USER_ROLE,
];
