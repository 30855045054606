import { Button, Chip, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react';
import { useImmerReducer } from 'use-immer';
import Spinner from 'components-lib/Spinner';
import {
  deleteLoginPageTemplateMap,
  putLoginPageTemplateMap,
} from 'api/loginPageTemplates';
import { useLoadTemplateMap } from './hooks/useLoadTemplateMap';

interface IProps {
  selectedNode: string;
}

interface ILoginTemplatesMap {
  eventIds: string[];
  domains: string[];
  eventIssuers: string[];
}

type State = ILoginTemplatesMap;

enum ActionType {
  ADD_EVENT_ID = 'ADD_EVENT_ID',
  REMOVE_EVENT_ID = 'REMOVE_EVENT_ID',
  ADD_DOMAIN = 'ADD_DOMAIN',
  REMOVE_DOMAIN = 'REMOVE_DOMAIN',
  ADD_EVENT_ISSUER = 'ADD_EVENT_ISSUER',
  REMOVE_EVENT_ISSUER = 'REMOVE_EVENT_ISSUER',

  UPDATE_FULL_STATE = 'UPDATE_FULL_STATE',
}

interface Action {
  type: ActionType;
  payload: string | ILoginTemplatesMap | undefined;
}

const initialState: State = {
  domains: [],
  eventIds: [],
  eventIssuers: [],
};

const reducer = (state: State, action: Action) => {
  if (!action.payload) return;

  if (typeof action.payload === 'string') {
    switch (action.type) {
      case ActionType.ADD_EVENT_ID: {
        const index = state.eventIds.indexOf(action.payload);
        if (index < 0) state.eventIds.push(action.payload);
        break;
      }
      case ActionType.REMOVE_EVENT_ID: {
        const index = state.eventIds.indexOf(action.payload);
        state.eventIds.splice(index, 1);
        break;
      }

      case ActionType.ADD_EVENT_ISSUER: {
        const index = state.eventIssuers.indexOf(action.payload);
        if (index < 0) state.eventIssuers.push(action.payload);
        break;
      }
      case ActionType.REMOVE_EVENT_ISSUER: {
        const index = state.eventIssuers.indexOf(action.payload);
        state.eventIssuers.splice(index, 1);
        break;
      }

      case ActionType.ADD_DOMAIN: {
        const index = state.domains.indexOf(action.payload);
        if (index < 0) state.domains.push(action.payload);
        break;
      }
      case ActionType.REMOVE_DOMAIN: {
        const index = state.domains.indexOf(action.payload);
        state.domains.splice(index, 1);
        break;
      }
    }
  } else {
    switch (action.type) {
      case ActionType.UPDATE_FULL_STATE: {
        return action.payload;
      }
    }
  }
};

interface IChunkProps {
  placeholder: string;
  onAdd: (value: string) => void;
  onDelete: (value: string) => void;
  appliedTo: string[];
}

const TemplateApplyToChunk = ({
  appliedTo,
  onAdd,
  onDelete,
  placeholder,
}: IChunkProps) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Box>
      <Box>
        <input placeholder={placeholder} ref={ref} />
        <Button
          onClick={() => {
            const value = ref?.current?.value;
            if (value) {
              onAdd(value);
            }
          }}
        >
          Add
        </Button>
      </Box>
      {appliedTo.map((id) => (
        <Chip
          label={id}
          onDelete={() => {
            onDelete(id);
          }}
          key={id}
        />
      ))}
    </Box>
  );
};

export const TemplateApplyToForm = ({ selectedNode }: IProps) => {
  const [appliedTo, dispatch] = useImmerReducer(reducer, initialState);

  const { loading, data } = useLoadTemplateMap(selectedNode);

  useEffect(() => {
    if (data) {
      dispatch({ type: ActionType.UPDATE_FULL_STATE, payload: data });
    }
  }, [data, dispatch]);

  const addDeleteTemplateDb = async (
    type: ActionType,
    templateTarget: string,
  ) => {
    let eventId, eventIssuer, domain;
    let addOrRemove: 'add' | 'remove' | undefined;

    switch (type) {
      case ActionType.ADD_EVENT_ID:
      case ActionType.ADD_EVENT_ISSUER:
      case ActionType.ADD_DOMAIN:
        addOrRemove = 'add';
        break;
      case ActionType.REMOVE_EVENT_ID:
      case ActionType.REMOVE_EVENT_ISSUER:
      case ActionType.REMOVE_DOMAIN:
        addOrRemove = 'remove';
        break;
      default:
        throw new Error('unsupported action type');
    }

    switch (type) {
      case ActionType.ADD_EVENT_ID:
      case ActionType.REMOVE_EVENT_ID:
        eventId = templateTarget;
        break;
      case ActionType.ADD_EVENT_ISSUER:
      case ActionType.REMOVE_EVENT_ISSUER:
        eventIssuer = templateTarget;
        break;
      case ActionType.ADD_DOMAIN:
      case ActionType.REMOVE_DOMAIN:
        domain = templateTarget;
        break;
    }

    switch (addOrRemove) {
      case 'add': {
        await putLoginPageTemplateMap({
          templateId: selectedNode,
          eventId,
          eventIssuer,
          domain,
        });
        break;
      }
      case 'remove': {
        await deleteLoginPageTemplateMap({
          templateId: selectedNode,
          eventId,
          eventIssuer,
          domain,
        });
        break;
      }
    }

    dispatch({
      type,
      payload: templateTarget,
    });
  };

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <Stack>
          <TemplateApplyToChunk
            placeholder="By event id"
            appliedTo={appliedTo.eventIds}
            onAdd={async (eventId: string) => {
              await addDeleteTemplateDb(ActionType.ADD_EVENT_ID, eventId);
            }}
            onDelete={async (eventId: string) => {
              addDeleteTemplateDb(ActionType.REMOVE_EVENT_ID, eventId);
            }}
          />

          <TemplateApplyToChunk
            placeholder="By event issuer"
            appliedTo={appliedTo.eventIssuers}
            onAdd={async (eventIssuer: string) => {
              await addDeleteTemplateDb(
                ActionType.ADD_EVENT_ISSUER,
                eventIssuer,
              );
            }}
            onDelete={async (eventIssuer: string) => {
              addDeleteTemplateDb(ActionType.REMOVE_EVENT_ISSUER, eventIssuer);
            }}
          />

          <TemplateApplyToChunk
            placeholder="By domain"
            appliedTo={appliedTo.domains}
            onAdd={async (domain: string) => {
              await addDeleteTemplateDb(ActionType.ADD_DOMAIN, domain);
            }}
            onDelete={async (domain: string) => {
              addDeleteTemplateDb(ActionType.REMOVE_DOMAIN, domain);
            }}
          />
        </Stack>
      )}
    </Box>
  );
};
