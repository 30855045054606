import React from 'react';

export default function Spinner() {
  return (
    <>
      <div className="big-spinner spinner mx-auto py-5">
        <span className="sr-only">Loading...</span>
        <div className="bounce1 mx-2" />
        <div className="bounce2 mx-2" />
        <div className="bounce3 mx-2" />
      </div>
    </>
  );
}
