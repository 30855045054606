import { resetUserError } from './userActions';
import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './models';
import { LoadingStateEnum } from 'models/enums';
import { setUser, fetchUser, logoutUser, fetchUserId } from './userThunks';
import { DEFAULT_ADMIN_EVENT_ROLE } from 'models/constants';

const initialState: UserState = {
  user: undefined,
  loading: LoadingStateEnum.IDLE,
  userError: '',
  authenticated: false,
  userId: '',
  loadingUserId: LoadingStateEnum.IDLE,
  hasAdminAccess: false,
  isSuperAdmin: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser.pending, (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    });
    builder.addCase(setUser.fulfilled, (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.user = action.payload;
        state.authenticated = true;
        state.userError = initialState.userError;
      }
    });
    builder.addCase(setUser.rejected, (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.userError = action.error;
      }
    });

    builder.addCase(fetchUser.pending, (state, action) => {
      state.loading = LoadingStateEnum.PENDING;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const isSuperAdmin =
        action?.payload?.userRoles?.includes(DEFAULT_ADMIN_EVENT_ROLE) ?? false;
      const hasAdminAccess =
        isSuperAdmin ||
        !!action?.payload?.permissionsAdminUser?.basic_access ||
        !!action?.payload?.permissionsAdminUser?.full_access;

      state.loading = LoadingStateEnum.IDLE;
      state.user = action.payload;
      state.authenticated = true;
      state.isSuperAdmin = isSuperAdmin;
      state.hasAdminAccess = hasAdminAccess;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      return {
        ...initialState,
        userError: action?.error?.message,
      };
    });

    builder.addCase(logoutUser.pending, (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state = Object.assign(state, initialState);
      }
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.userError = action.error;
      }
    });

    builder.addCase(fetchUserId.pending, (state, action) => {
      if (state.loadingUserId === LoadingStateEnum.IDLE) {
        state.loadingUserId = LoadingStateEnum.PENDING;
      }
    });
    builder.addCase(fetchUserId.fulfilled, (state, action) => {
      if (state.loadingUserId === LoadingStateEnum.PENDING) {
        state.loadingUserId = LoadingStateEnum.IDLE;
        state.userId = action.payload;
        state.userError = initialState.userError;
      }
    });
    builder.addCase(fetchUserId.rejected, (state, action) => {
      if (state.loadingUserId === LoadingStateEnum.PENDING) {
        state.loadingUserId = LoadingStateEnum.IDLE;
        state.userError = action.error;
      }
    });

    builder.addCase(resetUserError, (state, action) => {
      state.userError = initialState.userError;
    });
  },
});

export default userSlice.reducer;
