import { LoadingStateEnum } from 'models/enums';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, ModalProps } from 'react-bootstrap';
import { ROOT_PREFIX } from '../constants';
import Spinner from 'components-lib/Spinner';
import { FileData } from 'chonky';

interface IProps extends ModalProps {
  folderPrefix: string; // the prefix used to determine the full folder path
  title?: string;
  createFolderHandler: (folderPath: string) => Promise<void>;
  onSuccess?: (newFolder: FileData) => void;
}

const DEFAULT_TITLE = `Create new folder`;

// TODO: add validation
export const CreateFolderModal = ({
  folderPrefix,
  title,
  createFolderHandler,
  onSuccess,
  ...props
}: IProps) => {
  const [isOpen, setOpen] = useState(true);
  const [creationStatus, setCreationStatus] = useState(LoadingStateEnum.IDLE);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const closeModal = () => {
    setOpen(false);
  };

  const createFolderObject = (newFolderName: string): FileData => {
    const folderName = newFolderName.split('/')[0];

    return {
      id: folderPrefix + folderName,
      name: folderName,
      isDir: true,
    };
  };

  const handleCreateFolder = () => {
    if (ref.current?.value) {
      setCreationStatus(LoadingStateEnum.PENDING);

      const newFolderName = ref.current.value;

      const folderPath =
        folderPrefix === ROOT_PREFIX
          ? newFolderName
          : folderPrefix + newFolderName;

      createFolderHandler(folderPath)
        .then(() => {
          setCreationStatus(LoadingStateEnum.COMPLETE);

          if (onSuccess) {
            onSuccess(createFolderObject(newFolderName));
          }
        })
        .catch(() => {
          setCreationStatus(LoadingStateEnum.ERROR);
        });
    } else {
      throw new Error('FolderName is: ' + ref.current?.value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === 'Enter') {
      handleCreateFolder();
    }
  };

  const buttonsDisabled = creationStatus === LoadingStateEnum.PENDING;

  return (
    <>
      <Modal {...props} backdrop="static" show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton={!buttonsDisabled}>
          <Modal.Title>{title ? title : DEFAULT_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            name="folderName"
            id="folderName"
            ref={ref}
            onKeyUp={handleKeyUp}
          />
        </Modal.Body>

        <Modal.Footer>
          {creationStatus === LoadingStateEnum.PENDING && <Spinner />}
          <Button
            variant="primary"
            onClick={handleCreateFolder}
            disabled={buttonsDisabled}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={buttonsDisabled}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFolderModal;
