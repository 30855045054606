import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { getS3SignedUrlForStaticFilesUpload, uploadFileToS3 } from 'api';
import {
  copyObject,
  createStaticFilesFolder,
  getStaticFilesList,
  moveObject,
} from 'api/staticFiles';
import { useStaticFileLoad } from 'app/home/adminTools/LoginPages/components/LoginTemplateEditor/hooks/useStaticFileLoad';
import {
  ChonkyActions,
  ChonkyFileActionData,
  FileBrowser,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
} from 'chonky';
import Spinner from 'components-lib/Spinner';
import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useRef, useState } from 'react';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import { toast } from 'react-toastify';
import { ROOT_PREFIX } from './constants';
import { CustomEditor } from './CustomEditor';
import { useFileActions } from './hooks/useFileActions';
import { useFileExplorerProps } from './hooks/useFileExplorerProps';
import { useFileExplorerState } from './hooks/useFileExplorerState';
import { useFolderChain } from './hooks/useFolderChain';
import {
  CreateFolderModal,
  DeleteObjectsModal,
  ProgressModal,
  RenameModal,
} from './Modals';
import { convertToChonkyFileArray } from './util/convertToChonkyFileArray';
import { saveStaticFile } from './util/saveStaticFile';
import mime from 'mime-types';

const fileBrowserName = 'AWS S3 browser';

// TODO: disallow closing of modals while a request is pending
export const FileExplorerV2 = () => {
  const {
    reloadTrigger,
    setReloadTrigger,
    folderPrefix,
    setFolderPrefix,
    showCreateFolder,
    setShowCreateFolder,
    showDeleteModal,
    setShowDeleteModal,
    showRenameModal,
    setShowRenameModal,
    showProgress,
    setShowProgress,
    files,
    setFiles,
    filesInClipboard,
    setFilesInClipboard,
    objectsToDelete,
    setObjectsToDelete,
    objectToRename,
    setObjectToRename,
    failedUploads,
    setFailedUploads,
    uploadState,
    setUploadState,
    modalTitle,
    setModalTitle,
    loading,
    setLoading,
  } = useFileExplorerState();

  const { eventId, pathPrefix } = useFileExplorerProps();
  const { folderChain } = useFolderChain(folderPrefix);
  const [openInlineEditor, setOpenInlineEditor] = React.useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [filePath, setFilePath] = useState<string>('');
  const [openedFile, setOpenedFile] = useState<FileData | undefined>(undefined);
  const matchFileExtension = new RegExp('.(html|css|js|json)$', 'g');
  const [fileEditorSaved, setFileEditorSaved] = useState(true);

  const { fileActionsArray, customFileActions } = useFileActions({
    filesInClipboard,
  });

  const handleOpenInlineEditor = () => {
    window.onbeforeunload = () => {
      return (
        'Any string value here forces a dialog box to \n' +
        'appear before closing the window.'
      );
    };
    setOpenInlineEditor(true);
  };
  const handleCloseInlineEditor = () => {
    if (!fileEditorSaved) {
      if (
        window.confirm(
          'Do you really want to leave the page without saving the changes?',
        )
      ) {
        window.onbeforeunload = function () {
          // overwrite the handler when modal is closed
        };
        setOpenInlineEditor(false);
      }
    } else {
      window.onbeforeunload = function () {
        // overwrite the handler when modal is closed
      };
      setOpenInlineEditor(false);

      // file is saved -> reset so it forces a file reload when opening the same file
      setOpenedFile(undefined);
      setFilePath('');
    }
  };

  const { edtiorMode, loadStaticFile, loadedCode, loadingFile } =
    useStaticFileLoad();

  useEffect(() => {
    if (filePath.length !== 0) {
      loadStaticFile(filePath);
    }
  }, [loadStaticFile, filePath]);

  useEffect(() => {
    const path = folderPrefix === ROOT_PREFIX ? '' : folderPrefix;
    setLoading(true);

    getStaticFilesList(eventId, path)
      .then((result) => {
        const files = convertToChonkyFileArray(result, pathPrefix);

        setFiles(files);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [folderPrefix, eventId, setFiles, pathPrefix, reloadTrigger, setLoading]);

  const uploadFiles = async (files: FileList) => {
    if (files) {
      setShowProgress(true);
      setUploadState({ total: files.length, completed: 0, failed: 0 });
      setFailedUploads([]);

      const folderPath = folderPrefix === ROOT_PREFIX ? '' : folderPrefix;

      for (const file of files) {
        if (file) {
          try {
            const filePath = `${eventId}/${folderPath}${file.name}`;
            const response = await getS3SignedUrlForStaticFilesUpload(
              file.type,
              filePath,
              eventId,
            );
            const url = response.data as string;

            await uploadFileToS3(url, file);

            setUploadState((prevState) => {
              return { ...prevState, completed: prevState.completed + 1 };
            });
          } catch (e) {
            setUploadState((prevState) => {
              return { ...prevState, failed: prevState.failed + 1 };
            });
            setFailedUploads((prevState) => {
              return [...prevState, folderPath + file.name];
            });
          }
        }
      }

      return;
    }

    // TODO: add upload file toast for error
  };

  const uploadSingleFile = async (path: String, file: File) => {
    if (file) {
      setShowProgress(true);
      setUploadState((prevState) => {
        return { ...prevState, total: prevState.total + 1 };
      });
      setFailedUploads([]);

      const folderPath = folderPrefix === ROOT_PREFIX ? '' : folderPrefix;
      try {
        const filePath = `${eventId}/${folderPath}${path}${file.name}`;
        const response = await getS3SignedUrlForStaticFilesUpload(
          file.type,
          filePath,
          eventId,
        );
        const url = response.data as string;

        await uploadFileToS3(url, file);

        setUploadState((prevState) => {
          return { ...prevState, completed: prevState.completed + 1 };
        });
        triggerFileListReload();
      } catch (e) {
        setUploadState((prevState) => {
          return { ...prevState, failed: prevState.failed + 1 };
        });
        setFailedUploads((prevState) => {
          return [...prevState, folderPath + file.name];
        });
      }
    }

    // TODO: add upload file toast for error
  };

  const changeFolder = (newPrefix: string) => {
    if (!newPrefix) newPrefix = ROOT_PREFIX;
    if (!newPrefix.endsWith('/')) newPrefix += '/';

    setFolderPrefix(newPrefix);
  };

  const triggerFileListReload = () => {
    setReloadTrigger(!reloadTrigger);
  };

  const handleFileAction = (data: ChonkyFileActionData) => {
    if (data.id === ChonkyActions.OpenFiles.id) {
      if (data.payload.files.length === 1 && !data.payload.targetFile?.isDir) {
        if (matchFileExtension.test(data?.payload?.targetFile?.name as any)) {
          setOpenedFile(data.payload.targetFile);
          let url = `${pathPrefix}${data?.payload?.targetFile?.id}`
            .split('web/static/event-pages/')
            .join('static/events/');

          setFilePath(url);
          handleOpenInlineEditor();
        } else {
          let toastId;
          toastId = toast.info(
            'You cannot edit files with type other than .html, .css, .json or .js!',
          );
          setFilePath('');
        }
      }
      if (data.payload.files?.length !== 1) return; // To be a folder, the selected object count should be 1
      if (!data.payload.targetFile?.isDir) return; // Check if the selected object is a folder

      let newPrefix = data.payload?.targetFile.id.replace(pathPrefix, '');

      changeFolder(newPrefix);
    }

    if (data.id === ChonkyActions.CreateFolder.id) {
      setShowCreateFolder(true);
    }

    if (data.id === ChonkyActions.DeleteFiles.id) {
      setObjectsToDelete(data.state.selectedFilesForAction);
      setShowDeleteModal(true);
    }

    if (data.id === ChonkyActions.UploadFiles.id) {
      fileInput.current?.click();
    }

    if (data.id === ChonkyActions.CopyFiles.id) {
      setFilesInClipboard(data.state.selectedFiles);
    }

    if (data.id === ChonkyActions.MoveFiles.id) {
      const { destination, files } = data.payload;

      const prepareDestinationPath = (path: string) => {
        if (path === '/') {
          // Handle case when the destination is the root directory
          return '';
        } else if (!/^.*\/$/.test(path)) {
          /*
            Handle case when the destination is a folder from the breadcrumbs
            The chonky library handles diferently the folder path from the file explorer and the breadcrumbs
            We make sure the destination is always a directory
          */
          return path + '/';
        } else {
          return path;
        }
      };

      const destinationPath = prepareDestinationPath(destination.id);
      // TODO: Implement multiple files and folders drag and drop transfer
      if (files.length > 1) {
        alert('You can only move single files');
      } else {
        handleMove(files[0], destinationPath).then(() => {
          triggerFileListReload();
        });
      }
    }

    if (data.id === customFileActions.PasteSelection.id) {
      handlePaste().then(() => {
        setFilesInClipboard([]);
        triggerFileListReload();
      });
    }

    if (data.id === customFileActions.RefreshFiles.id) {
      triggerFileListReload();
    }

    if (data.id === customFileActions.RenameFile.id) {
      if (data.state.selectedFiles.length === 1) {
        setObjectToRename(data.state.selectedFiles[0]);
        setShowRenameModal(true);
      } else {
        alert('Select a file or folder to rename');
      }
    }
  };

  const handleMove = async (
    file: { id: string; name: string; isDir?: boolean },
    destinationPath: string,
  ) => {
    setModalTitle('Moving files...');
    setShowProgress(true);
    setUploadState({ total: 1, completed: 0, failed: 0 });
    try {
      const suffix = file.isDir === true ? '/' : '';
      const fullDestinationPath = destinationPath + file.name + suffix;

      await moveObject(eventId, file.id, fullDestinationPath);
      setUploadState({ total: 1, completed: 1, failed: 0 });
    } catch (e) {
      // console.log(e);
      setFailedUploads([file.name]);
    }
  };

  const handlePaste = async () => {
    const folderPath = folderPrefix === ROOT_PREFIX ? '' : folderPrefix;
    setModalTitle('Pasting files...');
    setShowProgress(true);
    setUploadState({ total: filesInClipboard.length, completed: 0, failed: 0 });
    setFailedUploads([]);

    for (const file of filesInClipboard) {
      if (file) {
        try {
          const suffix = file.isDir === true ? '/' : '';
          const destinationPath = folderPath + file.name + suffix;
          await copyObject(eventId, file.id, destinationPath);
          setUploadState((prevState) => {
            return { ...prevState, completed: prevState.completed + 1 };
          });
        } catch (e) {
          setUploadState((prevState) => {
            return { ...prevState, failed: uploadState.failed + 1 };
          });
          setFailedUploads((prevState) => {
            return [...prevState, folderPath + file.name];
          });
        }
      }
    }
  };

  const handleUploadFiles = async (e: React.ChangeEvent) => {
    e.preventDefault();

    const files = fileInput.current?.files;

    if (files) {
      await uploadFiles(files);
      triggerFileListReload();
      if (fileInput.current) {
        fileInput.current.value = '';
      }
    }
  };

  const handleCreateFolder = async (folderPath: string) => {
    await createStaticFilesFolder(eventId, folderPath);
  };

  const handleCreateSuccess = (newFolder: FileData) => {
    //setFiles([...files, newFolder]);
    setShowCreateFolder(false);
    triggerFileListReload();
  };

  const handleDeleteSuccess = () => {
    setObjectsToDelete([]);
    setShowDeleteModal(false);
    triggerFileListReload();
  };

  const handleRenameSuccess = () => {
    setShowRenameModal(false);
    triggerFileListReload();
  };

  const onDrop = (e: React.DragEvent) => {
    var items = e.dataTransfer.items;
    for (var i = 0; i < items.length; i++) {
      var item = items[i].webkitGetAsEntry();
      if (item) {
        traverseFileTree(item);
      }
    }

    function traverseFileTree(item: any, path = '') {
      if (item.isFile) {
        item.file(async (file: File) => {
          await uploadSingleFile(path, file);
        });
      } else if (item.isDirectory) {
        createStaticFilesFolder(eventId, path + item.name);
        // Get folder contents
        var dirReader = item.createReader();
        dirReader.readEntries(function (entries: any) {
          for (var i = 0; i < entries.length; i++) {
            traverseFileTree(entries[i], path + item.name + '/');
          }
        });
      }
    }
  };

  const handleSaveStaticFile = async (code: string) => {
    if (!openedFile) return;

    const toastId = toast.info('Saving file...', { autoClose: false });
    try {
      const s3Path = `${eventId}/${openedFile.id}`;
      const contentTypeMime = mime.lookup(openedFile.id);

      if (contentTypeMime) {
        await saveStaticFile(code, s3Path, eventId, contentTypeMime);
      } else {
        await saveStaticFile(code, s3Path, eventId);
      }

      toast.dismiss(toastId);
      toast.success('Saved');
    } catch (e) {
      toast.error(`Error saving file`);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleEditorFormatCodeError = (error: any) => {
    toast.error(`Formatting failed!`);
  };

  return (
    //  <!-- Main Explorer View -->
    <div
      id="page-wrapper"
      onDrop={onDrop}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log('drag enter');
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
    >
      <div className="row">
        <div className="col-lg-12">
          <input
            type="file"
            ref={fileInput}
            multiple
            onChange={handleUploadFiles}
            style={{ position: 'absolute', top: '-1000px' }} // Move off screen. Using display: none might make the control disabled on safari
          />
          <h1 className="h4 rounded-pill bg-dark text-white px-3 py-1 mb-4 d-inline-block font-weight-light">
            Static Pages
          </h1>
          <p>
            Manage the files for the event, drag {'&'} drop files into the
            explorer below.
          </p>
          <FileBrowser
            instanceId={fileBrowserName}
            files={files}
            folderChain={folderChain}
            onFileAction={handleFileAction}
            fileActions={fileActionsArray}
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
          >
            <FileNavbar />
            <FileToolbar />

            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Spinner />
              </div>
            ) : (
              <FileList />
            )}
          </FileBrowser>
        </div>
      </div>

      {filePath.length > 0 && openedFile && (
        <Modal
          open={openInlineEditor}
          onClose={handleCloseInlineEditor}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 1,
            }}
          >
            <CustomEditor
              saveFunction={handleSaveStaticFile}
              onSaveSuccess={() => {
                setFileEditorSaved(true);
              }}
              onChange={() => {
                setFileEditorSaved(false);
              }}
              onFormatCodeError={handleEditorFormatCodeError}
              loadedCode={loadedCode}
              loadingFile={loadingFile}
              editorMode={edtiorMode}
              eventId={eventId}
            />
          </Box>
        </Modal>
      )}

      {showCreateFolder && (
        <CreateFolderModal
          folderPrefix={folderPrefix}
          createFolderHandler={handleCreateFolder}
          onSuccess={handleCreateSuccess}
          onExit={() => {
            setShowCreateFolder(false);
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteObjectsModal
          objects={objectsToDelete}
          onSuccess={handleDeleteSuccess}
          onExit={() => {
            setShowDeleteModal(false);
          }}
        />
      )}

      {showProgress && (
        <ProgressModal
          {...uploadState}
          failedUploads={failedUploads}
          modalTitle={modalTitle}
          onExit={() => {
            setShowProgress(false);
            setUploadState({ completed: 0, failed: 0, total: 0 });
            setModalTitle(undefined);
          }}
        />
      )}

      {showRenameModal && (
        <RenameModal
          objectToRename={objectToRename}
          folderPrefix={folderPrefix}
          folderChain={folderChain}
          onSuccess={handleRenameSuccess}
          onExit={() => {
            setShowRenameModal(false);
          }}
        />
      )}
    </div>
  );
};
