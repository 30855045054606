import { UserRolesDTO } from 'api/models';
import Spinner from 'components-lib/Spinner';
import React, { useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { IRouteParams } from 'routes/models/IRouteParams';
import { RoleList } from '../RoleList';

interface IProps extends ModalProps {
  title: string;
  users: string[];
  roles: string[];
  successCallback: (items: UserRolesDTO[]) => void;
}

export const UserRolesModal = ({
  users,
  roles,
  title,
  successCallback,
  ...props
}: IProps) => {
  const [isOpen, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
  };
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams<IRouteParams>();

  const handleSelectionChange = (roles: string[]) => {
    setSelected(roles);
  };

  const handleOKClicked = async () => {
    const items: UserRolesDTO[] = users.map((userId) => {
      // add #{eventId} to the role
      const rolesWithEventId = selected.map((role) => `${role}#${eventId}`);

      return {
        userId,
        userRoles: rolesWithEventId,
      };
    });

    try {
      setLoading(true);
      await successCallback(items);
      setLoading(false);
    } catch {
      // TODO: add error message.
    }
  };

  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        className="text-center"
        show={isOpen}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RoleList roles={roles} onSelectionChange={handleSelectionChange} />
        </Modal.Body>
        <Modal.Footer>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Button variant="light" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant="secondary"
                onClick={handleOKClicked}
                disabled={selected.length === 0}
              >
                Save
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserRolesModal;
