import { StaticLink, StaticLinkObject } from 'features/event/models';

export function createStaticLinkArrFromStaticObjArr(
  staticLinks: StaticLinkObject[],
): StaticLink[] {
  const result: StaticLink[] = [];

  if (!staticLinks || staticLinks.length === 0) return result;

  staticLinks.forEach((linkObj) => {
    Object.entries(linkObj).forEach(([key, value]) => {
      const currentStaticLink: StaticLink = {
        tabName: key as string,
        path: value.path,
        target: value.target,
      };
      result.push(currentStaticLink);
    });
  });

  return result as StaticLink[];
}
