import { uploadPublicResourcesFile } from 'api/publicResources';
import { TEMPLATES_FOLDER } from 'models/constants';
import { TemplateType } from 'models/TemplateType';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

export const useUploadFavicon = (
  selectedNode: string,
  faviconFileRef: React.RefObject<HTMLInputElement>,
) => {
  const [isUploadingFavicon, setIsUploadingFavicon] = useState(false);

  const handleUploadFavicon = useCallback(async () => {
    let toastId;
    try {
      const file = faviconFileRef?.current?.files?.[0];

      if (!file) return;
      if (file.name.toLowerCase() !== 'favicon.ico') {
        toast.error(
          <>
            Upload failed...
            <br /> The file name should be &apos;favicon.ico&apos;
          </>,
        );
        return;
      }

      setIsUploadingFavicon(true);

      const uploadPath = `${TemplateType.CustomDomains}/${TEMPLATES_FOLDER}/${selectedNode}/${file.name}`;

      toastId = toast.info('Uploading...', { autoClose: false });

      await uploadPublicResourcesFile(file, uploadPath);

      toast.success('Favicon uploaded successfully!');

      return uploadPath;
    } catch (e) {
      toast.error(`Unable to upload favicon!`);
    } finally {
      if (toastId) toast.dismiss(toastId);
      setIsUploadingFavicon(false);
    }
  }, [faviconFileRef, selectedNode]);

  return { handleUploadFavicon, isUploadingFavicon };
};
