import EventList from 'features/event/components/EventList';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';

export const EventsMain = () => {
  const { push } = useHistory();
  return (
    <>
      <Button
        onClick={() => push(`${ROUTES.home.createEvent}`)}
        className="mt-1 mb-4"
      >
        Create event
      </Button>
      <EventList />
    </>
  );
};
