import React, { useState } from 'react';

import { CreateUserForm } from './forms/CreateUserForm';
import { CreateSSOUserForm } from './forms/CreateSSOUserForm';

export const CreateUser = () => {
  const [isSSO, setIsSSO] = useState(false);

  return (
    <>
      <div className="h2">Create new user</div>
      Is SSO user:{' '}
      <input
        type="checkbox"
        id="myCheck"
        onClick={() => setIsSSO(!isSSO)}
      ></input>
      {isSSO ? <CreateSSOUserForm /> : <CreateUserForm />}
    </>
  );
};
