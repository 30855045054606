import { getS3SignedUrlForStaticFilesUpload, uploadFileToS3 } from 'api';
import * as path from 'path';

const createFileFromString = (
  textContent: string,
  fileName: string,
  contentType?: string,
) => {
  const parts = [new Blob([`${textContent}`])];
  const file = new File(parts, fileName, { type: contentType });

  return file;
};

// const pathUrl = `${eventId}/${chosenFile.id}`;
export const saveStaticFile = async (
  code: string,
  s3FilePath: string,
  eventId: string,
  contentType?: string,
) => {
  const fileName = path.basename(s3FilePath);
  const fileToSave = createFileFromString(code, fileName, contentType);

  const response = await getS3SignedUrlForStaticFilesUpload(
    fileToSave.type,
    s3FilePath,
    eventId,
  );

  const signedUrl = response.data as string;

  await uploadFileToS3(signedUrl, fileToSave);
};
