import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  DatePickerField,
  FormArrayField,
  FormCheckboxField,
  FormSingleCheckboxField,
  FormTextareaField,
  FormInputField,
} from 'components-lib';

import Spinner from 'components-lib/Spinner';
import {
  onEventVotingRoundSubmit,
  createSelectOptionsFromStringArray,
} from 'features/utils/eventUtils';
import { EventFormTypeEnum, LoadingStateEnum } from 'models/enums';
import { RootState } from 'store/models';
import { fetchEventById } from './../../event/store/eventThunks';
import { fetchFormTemplateById } from './../../formTemplate/store/formTemplateThunks';
import { votingRoundSchema } from 'features/validations/votingRoundSchema';
import { ROUTES } from 'routes';
import { VotingRoundFormValues } from '../models';
import { generateInitialVotingRoundFormValues } from 'features/utils/votingHelper';

export const VotingRoundsForm = () => {
  const dispatch = useDispatch();
  const { push, goBack } = useHistory();

  let { eventId, roundId } = useParams<Record<string, string>>();
  const { event, loading, eventError } = useSelector(
    (state: RootState) => state.event,
  );
  const { formTemplate, loading: loadingMainFormData } = useSelector(
    (state: RootState) => state.formTemplate,
  );
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const stages = (event && event.stages) ?? [];

  const mainFormFieldOptions =
    formTemplate && createSelectOptionsFromStringArray(formTemplate.order);

  const evalFormNameOptions =
    event && event.evalFormId
      ? createSelectOptionsFromStringArray(
          event.evalFormId.map((ef) => ef.evalForm),
        )
      : [];

  useEffect(() => {
    if (eventId !== event?.id) {
      dispatch(fetchEventById(eventId));
    }
    if (event) {
      dispatch(fetchFormTemplateById(event.formId));
    }
  }, [dispatch, event, eventId]);

  useEffect(() => {
    //TODO  add checking for other errors
    if (eventError) {
      push(ROUTES.errorPage);
    }

    //to redirect to voting round section after successful update event submit
    if (isFormSubmit) {
      push(
        generatePath(ROUTES.home.events.votingRounds.root, {
          eventId: event?.id || eventId,
        }),
      );
    }
  }, [event?.id, eventError, eventId, isFormSubmit, push]);

  //TODO: Add page for non-existing event,rounds and ..
  if (roundId && !event?.votingRounds?.hasOwnProperty(roundId))
    return <>Non existing round</>;

  const initialValues: VotingRoundFormValues =
    generateInitialVotingRoundFormValues(event, roundId);

  return loading === LoadingStateEnum.IDLE &&
    loadingMainFormData === LoadingStateEnum.IDLE ? (
    <div className="row">
      <div className="col-12">
        <h3 className="mb-4">
          {roundId ? EventFormTypeEnum.UPDATE : EventFormTypeEnum.CREATE} Voting
          Round Form
        </h3>

        <Formik
          initialValues={initialValues}
          validationSchema={votingRoundSchema}
          onSubmit={(values, actions) =>
            onEventVotingRoundSubmit(
              // @ts-ignore
              values,
              actions,
              dispatch,
              eventId,
              setIsFormSubmit,
            )
          }
        >
          {({
            values,
            errors,
            status,
            touched,
            resetForm,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            handleBlur,
          }) =>
            isSubmitting ? (
              <Spinner />
            ) : (
              <Form className="max-600">
                {!roundId && (
                  <FormInputField
                    fieldLabel="Round ID"
                    fieldName="roundId"
                    errors={errors.roundId}
                    touched={touched.roundId}
                    isRequired={true}
                  />
                )}
                <FormInputField
                  fieldLabel="Tab Name"
                  fieldName="tabName"
                  errors={errors.tabName}
                  touched={touched.tabName}
                  isRequired={true}
                />
                <FormTextareaField
                  fieldLabel="Round Question"
                  fieldName="question"
                  errors={errors.question}
                  touched={touched.question}
                  rows="3"
                  isRequired={true}
                />

                <FormSingleCheckboxField
                  fieldLabel="Add Modified Submissions"
                  fieldName="addModifiedSubmissions"
                />

                <FormSingleCheckboxField
                  fieldLabel="Add Modified Submissions While Active"
                  fieldName="addModifiedSubmissionsWhileActive"
                />

                <FormSingleCheckboxField
                  fieldLabel="Add New Submissions While Active"
                  fieldName="addNewSubmissionsWhileActive"
                />

                <FormSingleCheckboxField
                  fieldLabel="Self Vote"
                  fieldName="selfVote"
                />

                <FormSingleCheckboxField
                  fieldLabel="Smart Vote"
                  fieldName="smartVote"
                />

                <FormSingleCheckboxField
                  fieldLabel="Display When Inactive"
                  fieldName="displayWhenInactive"
                />

                <DatePickerField
                  name="startTime"
                  fieldLabel="Start Date"
                  value={values.startTime}
                  onChange={setFieldValue}
                  showTimeSelect
                  isClearable
                  dateFormat="Pp"
                  minDate={new Date()}
                  maxDate={values.endTime}
                  // @ts-ignore
                  errors={errors.startTime}
                />

                <DatePickerField
                  name="endTime"
                  fieldLabel="End Date"
                  value={values.endTime}
                  onChange={setFieldValue}
                  showTimeSelect
                  isClearable
                  dateFormat="Pp"
                  minDate={values.startTime ?? new Date()}
                  // @ts-ignore
                  errors={errors.endTime}
                />

                <hr />

                {stages.length > 0 && (
                  <>
                    <div>Stage Filters</div>

                    {stages.map((stageObj, index) => (
                      <FormCheckboxField
                        fieldLabel={`${stageObj.name}`}
                        fieldValue={index}
                        key={index}
                        fieldName="stageFilters"
                      />
                    ))}
                    <hr />
                  </>
                )}

                <FormArrayField
                  fieldLabel="Main form template fields filters"
                  fieldName="fieldFilters"
                  errors={errors.fieldFilters}
                  touched={touched.fieldFilters}
                  fieldArr={values.fieldFilters ?? []}
                  btnLabel="Add a filter"
                  placeholder=""
                  withCheckbox={{
                    name: 'excludedFromRound',
                    label: 'Exclude from round',
                  }}
                  fieldDataArray={[
                    {
                      name: 'field',
                      placeholder: 'Main form field name',
                      options: mainFormFieldOptions,
                    },
                    {
                      name: 'value',
                      placeholder: 'Main form field value',
                    },
                  ]}
                />

                <hr />

                {evalFormNameOptions.length > 0 && (
                  <FormArrayField
                    fieldLabel="Eval form templates fields filters"
                    fieldName="evalFieldFilters"
                    errors={errors.evalFieldFilters}
                    touched={touched.evalFieldFilters}
                    fieldArr={values.evalFieldFilters ?? []}
                    btnLabel="Add a filter"
                    placeholder=""
                    withCheckbox={{
                      name: 'excludedFromRound',
                      label: 'Exclude from round',
                    }}
                    fieldDataArray={[
                      {
                        name: 'evalForm',
                        placeholder: 'Eval form name',
                        options: evalFormNameOptions,
                      },
                      {
                        name: 'field',
                        placeholder: 'Eval form field name',
                      },
                      {
                        name: 'value',
                        placeholder: 'Eval form field value',
                      },
                    ]}
                  />
                )}
                <div className="d-flex justify-content-between w-50">
                  <button
                    type="submit"
                    className="btn btn-success mt-4"
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    Save Round
                  </button>
                  <button
                    className="btn btn-danger mt-4"
                    type="button"
                    onClick={() => {
                      resetForm({
                        values: initialValues,
                      });
                      goBack();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};
