import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTES } from 'routes';
import { resetEventError } from 'features/event/store/eventActions';
import { resetFormTemplateError } from 'features/formTemplate/store/formTemplateActions';
import { resetUserError } from 'features/user/store/userActions';
import Spinner from 'components-lib/Spinner';

/**
 * Generic error page
 */
export const ErrorPage = () => {
  const [clearAllErrorsComplete, setClearAllErrorsComplete] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(resetEventError()),
      dispatch(resetFormTemplateError()),
      dispatch(resetUserError()),
    ]).then(() => {
      setClearAllErrorsComplete(true);
    });
  }, [dispatch]);

  return clearAllErrorsComplete ? (
    <div>
      <h2>
        Something wrong here <span>&hellip;</span>
      </h2>
      <p>Sorry, we&apos;re having some technical issues (as you can see)</p>
      <Link to={`${ROUTES.home.root}`}>To the dashboard</Link>
    </div>
  ) : (
    <Spinner />
  );
};

//Add clear all errors dispatch actions while rendering error page
