import { searchForUsers } from 'api';
import { UserData } from 'api/models/SearchForUsersResponseDTO';
import { GenericTable } from 'components-lib/GenericTable/GenericTable.component';
import React, { useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { userDataDefinition } from './util/userDataDefinition';

export const UserSearch = () => {
  const [emails, setEmails] = useState('');
  const [issuers, setIssuers] = useState('');
  const [roles, setRoles] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchResultData, setSearchResultData] = useState<UserData[]>([]);
  const [notFound, setNotFound] = useState('');

  const search = async () => {
    setSearchResult('');
    setNotFound('');
    try {
      const emailsToSend = convertInputToArray(emails);
      const issuersToSend = convertInputToArray(issuers);
      const rolesToSend = convertInputToArray(roles);

      const data = await searchForUsers({
        emails: emailsToSend,
        issuers: issuersToSend,
        roles: rolesToSend,
      });

      const ids = Object.keys(data.result);

      setSearchResult(ids.join('\n'));

      const foundEmails = ids.map((id) => data.result[id].email);
      const notFoundEmails = emailsToSend.filter(
        (email) => !foundEmails.includes(email),
      );

      setNotFound(notFoundEmails.join('\n'));
      setSearchResultData(Object.values(data.result));
    } catch (e) {
      // TODO: show error message
    }
  };

  const copyToClipboard = () => {
    if (searchResultData.length > 0) {
      const headers = Object.keys(searchResultData[0]).join('\t');
      const dataAsTextArray = searchResultData.map((row) =>
        Object.values(row).join('\t'),
      );
      const dataAsText = headers + '\n' + dataAsTextArray.join('\n');

      const tempTextArea = document.createElement('textarea');
      document.body.appendChild(tempTextArea);

      tempTextArea.value = dataAsText;
      tempTextArea.focus();
      tempTextArea.select();

      document.execCommand('copy');

      document.body.removeChild(tempTextArea);
    }
  };

  const convertInputToArray = (params: string) => {
    if (!params) return [];
    const terms = params.split('\n');

    return terms.map((term) => term.trim());
  };

  const userListTable = useMemo(
    () => (
      <GenericTable
        rows={searchResultData}
        visibleColumns={['id', 'email', 'firstName', 'lastName', 'issuer']}
        headCellsDefinition={userDataDefinition}
      />
    ),
    [searchResultData],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={4}>
          <Form.Group controlId="emails">
            <Form.Label>Emails:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="emails"
              onChange={(e) => {
                setEmails(e.target.value);
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={4}>
          <Form.Group controlId="roles">
            <Form.Label>Roles:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="roles"
              onChange={(e) => {
                setRoles(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group controlId="issuers">
            <Form.Label>Issuers:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="issuers"
              onChange={(e) => {
                setIssuers(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Button onClick={search}>search</Button>
      </Row>
      <Row>
        <Button onClick={copyToClipboard} disabled={!searchResult}>
          Copy
        </Button>
      </Row>
      <Row>{userListTable}</Row>
      <Row>
        <Form.Group controlId="results">
          <Form.Label>Not found:</Form.Label>
          <Form.Control as="textarea" readOnly rows={5} value={notFound} />
        </Form.Group>
      </Row>
    </Container>
  );
};
