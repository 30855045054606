import { StaticLink, StaticLinkObject } from 'features/event/models';

export function createStaticObjArrFromStaticLinkArr(
  staticLinks: StaticLink[],
): StaticLinkObject[] {
  const result: StaticLinkObject[] = [];

  if (!staticLinks || staticLinks.length === 0) return result;

  staticLinks.forEach(({ tabName, path, target }) => {
    const currentStaticLinkObj: StaticLinkObject = {
      [tabName]: {
        path,
        target,
      },
    };
    result.push(currentStaticLinkObj);
  });

  return result as StaticLinkObject[];
}
