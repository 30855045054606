import React from 'react';
import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { SelectOptions } from 'components-lib/FormSelectField';

interface FieldData {
  name: string;
  placeholder: string;
  options?: SelectOptions[];
  multiple?: boolean;
  hidden?: boolean;
}

interface FormArrayFieldProps {
  fieldLabel: string;
  fieldName: string;
  placeholder: string;
  errors?: string | string[] | FormikErrors<any>[];
  touched?: boolean | FormikTouched<any>[];
  fieldArr: any[];
  btnLabel: string;
  withBorder?: string;
  withControls?: boolean;
  fieldDataArray?: FieldData[];
  withCheckbox?: { name: string; label: string };
}

export const FormArrayField = ({
  fieldLabel,
  fieldName,
  placeholder,
  errors,
  touched,
  fieldArr,
  btnLabel,
  withBorder,
  withControls,
  fieldDataArray,
  withCheckbox,
}: FormArrayFieldProps) => {
  return (
    <div className={`form-group row ${withBorder}`}>
      <label className="col-sm-4 col-form-label" htmlFor={fieldName}>
        {fieldLabel}
      </label>
      <div className="col-sm-8">
        <FieldArray
          name={fieldName}
          render={(arrayHelpers) => (
            <div className="mt-1">
              {fieldArr &&
                fieldArr.length > 0 &&
                fieldArr.map((el, index) => (
                  <div
                    key={`${fieldName}-${index}`}
                    className="row d-flex justify-content-between mx-0 mb-1"
                  >
                    {withCheckbox && (
                      <div>
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          name={`${fieldName}.${index}.${withCheckbox.name}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={withCheckbox.name}
                        >
                          {withCheckbox.label}
                        </label>
                      </div>
                    )}
                    {withControls && (
                      <>
                        <button
                          type="button"
                          onClick={() => arrayHelpers.move(index, index - 1)}
                          disabled={index === 0}
                        >
                          &and;
                        </button>
                        <button
                          type="button"
                          onClick={() => arrayHelpers.move(index, index + 1)}
                          disabled={index === fieldArr.length - 1}
                        >
                          &or;
                        </button>
                      </>
                    )}

                    {fieldDataArray &&
                      fieldDataArray.map((fieldData, arr_idx) => {
                        if (fieldData.options) {
                          return (
                            <div
                              className="form-group"
                              key={`${index}-${arr_idx}`}
                            >
                              <Field
                                as="select"
                                className={'form-control'}
                                autoComplete="off"
                                name={`${fieldName}.${index}.${fieldData.name}`}
                                multiple={fieldData.multiple}
                              >
                                {!fieldData.multiple && (
                                  <option value="">Select An Option</option>
                                )}
                                {fieldData.options.map(
                                  (
                                    o: { name: string; value: string },
                                    idx: number,
                                  ) => (
                                    <option
                                      key={`${index}--${idx}`}
                                      value={o.value}
                                    >
                                      {o.name}
                                    </option>
                                  ),
                                )}
                              </Field>
                            </div>
                          );
                        }

                        return (
                          <Field
                            key={`${index}-${arr_idx}`}
                            name={`${fieldName}.${index}.${fieldData.name}`}
                            placeholder={fieldData.placeholder}
                            className={`form-group ${
                              fieldData.hidden ? 'd-none' : ''
                            }`}
                          />
                        );
                      })}
                    {!fieldDataArray && (
                      <Field
                        name={`${fieldName}.${index}`}
                        value={el}
                        placeholder={placeholder}
                        className="form-group"
                      />
                    )}

                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      x
                    </button>
                  </div>
                ))}
              <ErrorMessage
                name={fieldName}
                component="div"
                className="invalid-feedback d-block"
              />
              <button
                className="my-1"
                type="button"
                onClick={() => arrayHelpers.push('')}
              >
                {btnLabel}
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
};
