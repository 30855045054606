import { EventItem } from 'api/models';
import { LdapFieldPermissionField } from 'features/event/models';
import { filterKeysWithTrueValue } from '.';

export function createArrayFromLdapFieldPermissionObject(
  event: EventItem,
): LdapFieldPermissionField[] {
  const result: LdapFieldPermissionField[] = [];

  if (
    !event.ldapFieldPermission ||
    Object.keys(event.ldapFieldPermission).length === 0
  )
    return result;

  Object.entries(event.ldapFieldPermission).forEach(
    ([formFieldName, fieldMap]) => {
      Object.entries(fieldMap).forEach(([userGroup, actionsData]) => {
        const ldapActions = filterKeysWithTrueValue(actionsData);
        const ldapFieldPermissionObj: LdapFieldPermissionField = {
          ldapFieldName: formFieldName,
          ldapUserGroup: userGroup,
          ldapActions,
        };
        result.push(ldapFieldPermissionObj);
      });
    },
  );

  return result as LdapFieldPermissionField[];
}
