import { TreeItem, TreeView } from '@mui/lab';
import { TreeNode } from 'path-list-to-tree';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useCallback, useMemo } from 'react';

export const useBuildUITree = (
  tree: TreeNode[],
  onNodeSelect: (e: React.SyntheticEvent, nodeId: string) => void,
  selectedNode: string,
) => {
  const buildUITree = useCallback((tree: TreeNode[], path = '') => {
    const nodes = tree.map((treeNode, index) => {
      const { children, name } = treeNode;
      let nodeId = path ? `${path}/${name}` : name;

      // empty names should be skipped
      if (!name) {
        return undefined;
      } else if (children.length > 0) {
        // it is a folder
        nodeId += '/';
        return (
          <TreeItem nodeId={nodeId} label={name} key={nodeId}>
            {buildUITree(treeNode.children, path + name)}
          </TreeItem>
        );
      } else {
        return <TreeItem nodeId={nodeId} label={name} key={nodeId} />;
      }
    });

    return nodes;
  }, []);

  return useMemo(
    () => (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={onNodeSelect}
        selected={selectedNode}
      >
        {buildUITree(tree)}
      </TreeView>
    ),
    [buildUITree, onNodeSelect, selectedNode, tree],
  );
};
