import { getPublicResourcesList } from 'api/publicResources';
import { TemplateType } from 'models/TemplateType';
import pathListToTree from 'path-list-to-tree';
import { useCallback, useState } from 'react';

export const useLoadTree = (templateType: TemplateType) => {
  const [loadingTree, setLoadingTree] = useState(true);

  const loadTree = useCallback(
    async (prefix?: string) => {
      setLoadingTree(true);

      const response = await getPublicResourcesList(templateType, prefix);
      const list: string[] = [];
      // folders
      const s3Prefixes = response.CommonPrefixes;
      if (s3Prefixes) {
        s3Prefixes.forEach((prefix) => {
          if (prefix.Prefix) {
            list.push(prefix.Prefix);
          }
        });
      }

      // files
      const s3Objects = response.Contents;
      if (s3Objects) {
        s3Objects.forEach((object) => {
          if (object.Key) {
            list.push(object.Key);
          }
        });
      }

      const replacePattern = prefix
        ? `public/${templateType}/${prefix}/`
        : `public/${templateType}/`;

      const cleanList = list.map((item) =>
        item.replace(replacePattern, ``).replace(/\/$/, ''),
      );

      setLoadingTree(false);

      return pathListToTree(cleanList);
    },
    [templateType],
  );

  return { loadingTree, loadTree };
};
