import { Dialog, DialogContent, DialogProps } from '@mui/material';
import { EventRole } from 'features/event/models';
import * as React from 'react';
import { RoleForm } from '../RoleForm';

interface IProps extends DialogProps {
  eventId: string;
  eventRole: EventRole;
  onSuccess?: () => void;
  customName?: string; // Used to replace the roleName, in that case the roleName is not editable.
}

export const RoleFormModalDialog = ({
  eventId,
  eventRole,
  onSuccess,
  customName,

  ...rest
}: IProps) => {
  return (
    <>
      <Dialog {...rest}>
        <DialogContent>
          <RoleForm
            eventId={eventId}
            eventRole={eventRole}
            customName={customName}
            onSuccess={onSuccess}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
