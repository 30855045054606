import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStateEnum } from 'models/enums';
import {
  createEvent,
  createEventReadRoles,
  deleteEventReadRoles,
  fetchEventById,
  fetchPublicEventById,
  fetchEventReadRolesByEventId,
  fetchEventsList,
  updateEvent,
} from './eventThunks';
import { resetEvent, resetEventError, resetEventState } from './eventActions';
import { EventState } from './models';
import { EventRole } from '../models';
import { generateRoleKey } from 'features/utils/generateRoleKey';

const initialState: EventState = {
  loading: LoadingStateEnum.IDLE,
  loadingPublicId: LoadingStateEnum.IDLE,
  event: undefined,
  publicEventId: undefined,
  eventError: undefined,
  eventReadRoles: [],
  loadingReadRoles: LoadingStateEnum.IDLE,
  userEventList: undefined,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    addUpdateRole: (state, action: PayloadAction<EventRole>) => {
      if (!state.event) throw new Error('Expected event to be an object');

      const role = action.payload;
      const newRoleKey = generateRoleKey(role, state.event.id);

      state.event.roles = {
        ...state.event.roles,
        [newRoleKey]: { ...role, actions: { ...role.actions } }, // since actions are a primitive type, we need to do deep nesting update. A slice just for the roles might be a better idea.
      };
    },
    addUpdateMultipleRoles: (state, action: PayloadAction<EventRole[]>) => {
      if (!state.event) throw new Error('Expected event to be an object');

      const roles = action.payload;

      const newRoleObjects: Record<string, EventRole> = roles.reduce(
        (previous, role) => {
          if (!state.event) return previous;

          const roleKey = generateRoleKey(role, state.event.id);
          const { actions, isEditable, roleName, roleType } = role;

          return {
            ...previous,
            [roleKey]: {
              actions: { ...actions }, // create new actions object for each new role // since actions are a primitive type, we need to do deep nesting update. A slice just for the roles might be a better idea.
              isEditable,
              roleName,
              roleType,
            },
          };
        },
        {},
      );

      state.event.roles = {
        ...state.event.roles,
        ...newRoleObjects,
      };
    },
    removeRole: (state, action: PayloadAction<string>) => {
      if (!state.event) throw new Error('Expected event to be an object');
      delete state.event.roles[action.payload];
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchEventById.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [fetchEventById.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.event = action.payload;
      }
    },
    [fetchEventById.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [fetchPublicEventById.pending]: (state, action) => {
      if (state.loadingPublicId === LoadingStateEnum.IDLE) {
        state.loadingPublicId = LoadingStateEnum.PENDING;
      }
    },
    [fetchPublicEventById.fulfilled]: (state, action) => {
      if (state.loadingPublicId === LoadingStateEnum.PENDING) {
        state.loadingPublicId = LoadingStateEnum.IDLE;
        state.publicEventId = action.payload;
      }
    },
    [fetchPublicEventById.rejected]: (state, action) => {
      if (state.loadingPublicId === LoadingStateEnum.PENDING) {
        state.loadingPublicId = LoadingStateEnum.IDLE;
        state.publicEventId = undefined;
      }
    },
    [fetchEventsList.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [fetchEventsList.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.userEventList = action.payload;
      }
    },
    [fetchEventsList.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [createEvent.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [createEvent.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [createEvent.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.event = action.payload;
        state.eventReadRoles = action.payload.updatedEventReadRoles;
      }
    },
    [updateEvent.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [updateEvent.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [updateEvent.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.event = { ...state.event, ...action.payload.updatedEvent };
        state.eventReadRoles = action.payload.updatedEventReadRoles;
      }
    },
    [createEventReadRoles.pending]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.IDLE) {
        state.loadingReadRoles = LoadingStateEnum.PENDING;
      }
    },
    [createEventReadRoles.rejected]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.PENDING) {
        state.loadingReadRoles = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [createEventReadRoles.fulfilled]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.PENDING) {
        const { roleName } = action.meta.arg;
        state.loadingReadRoles = LoadingStateEnum.IDLE;
        state.eventReadRoles.push(roleName);
      }
    },
    [deleteEventReadRoles.pending]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.IDLE) {
        state.loadingReadRoles = LoadingStateEnum.PENDING;
      }
    },
    [deleteEventReadRoles.rejected]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.PENDING) {
        state.loadingReadRoles = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [deleteEventReadRoles.fulfilled]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.PENDING) {
        state.loadingReadRoles = LoadingStateEnum.IDLE;
        state.eventReadRoles = state.eventReadRoles.filter((role) => {
          return role !== action.meta.arg.roleName;
        });
      }
    },
    [fetchEventReadRolesByEventId.pending]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.IDLE) {
        state.loadingReadRoles = LoadingStateEnum.PENDING;
      }
    },
    [fetchEventReadRolesByEventId.rejected]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.PENDING) {
        state.loadingReadRoles = LoadingStateEnum.IDLE;
        state.eventError = action.error;
      }
    },
    [fetchEventReadRolesByEventId.fulfilled]: (state, action) => {
      if (state.loadingReadRoles === LoadingStateEnum.PENDING) {
        state.loadingReadRoles = LoadingStateEnum.IDLE;
        state.eventReadRoles = action.payload;
      }
    },
    [resetEvent]: (state, action) => {
      state.event = undefined;
      state.publicEventId = undefined;
      state.eventReadRoles = [];
      state.eventError = initialState.eventError;
    },
    [resetEventState]: (state, action) => {
      state = Object.assign(state, initialState);
    },
    [resetEventError]: (state, action) => {
      state.eventError = initialState.eventError;
    },
  },
});

export default eventSlice.reducer;
export const actions = eventSlice.actions;
