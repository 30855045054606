export enum SuccessMsgEnum {
  UPDATE_EVENT = 'Event successfully updated',
  CREATE_EVENT = 'Event successfully created',
  UPDATE_FORM_TEMPLATE = 'Form template successfully updated',
  CREATE_FORM_TEMPLATE = 'Form template successfully created',
  CREATE_USER_ROLE = 'User role/s successfully created',
  DELETE_USER_ROLE = 'User role/s successfully deleted',
  CREATE_EVENT_READ_ROLE = 'Event read role successfully created',
  DELETE_EVENT_READ_ROLE = 'Event read role successfully deleted',
  INVITE_USER = 'User invited successfully',
}
