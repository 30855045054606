import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';

import { LoadingStateEnum } from 'models/enums';
import Spinner from 'components-lib/Spinner';
import { ROUTES } from 'routes';
import { RootState } from 'store/models';
import { fetchEventById } from '../../event/store/eventThunks';
import { VotingRoundsList } from './VotingRoundsList';

export const VotingRoundsMain = () => {
  const dispatch = useDispatch();

  let { eventId } = useParams<Record<string, string>>();
  const { event, loading } = useSelector((state: RootState) => state.event);
  const eventVotingRounds = event?.votingRounds;

  //TODO:  add code for error checking and goto error page

  useEffect(() => {
    if (eventId !== event?.id) {
      dispatch(fetchEventById(eventId));
    }
  }, [dispatch, event, eventId]);

  if (loading === LoadingStateEnum.IDLE && event) {
    return (
      <div className="row">
        <div className="col-12">
          <h1 className="h4 rounded-pill bg-dark text-white px-3 py-1 mb-4 d-inline-block font-weight-light">
            Voting Rounds
          </h1>
        </div>
        <div className="col-12">
          <Link
            className="d-block mb-2"
            to={generatePath(ROUTES.home.events.votingRounds.actions.create, {
              eventId,
            })}
          >
            Add voting round
          </Link>

          {!eventVotingRounds || !Object.keys(eventVotingRounds).length ? (
            <div className="font-italic">No voting rounds for this event</div>
          ) : (
            <VotingRoundsList
              eventVotingRounds={Object.entries(eventVotingRounds)}
              eventId={eventId}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
};
