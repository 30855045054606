export const stringSearch = (
  searchTerm: string,
  data: string,
  ignireCase = true,
) => {
  if (ignireCase) {
    return data.toLowerCase().includes(searchTerm.toLowerCase());
  } else {
    return data.includes(searchTerm);
  }
};

export const rolesSearch = (
  searchTerm: string,
  roles: string[],
  eventId?: string,
  ignoreCase = true,
) => {
  return roles.some((role) => {
    if (eventId?.length) {
      role = role.replace(/#.*$/i, '');
    }

    if (ignoreCase) {
      role = role.toLowerCase();
      searchTerm = searchTerm.toLowerCase();
    }

    return role.includes(searchTerm);
  });
};
