import { DEFAULT_ROLE_ACTIONS } from 'features/event/components/constants';
import { EventRole } from 'features/event/models';
import { EventRoleTypeEnum } from 'models/enums';

export const createNewRole = (
  roleType: EventRoleTypeEnum,
  roleName?: string,
) => {
  const newRole: EventRole = {
    isEditable: true,
    actions: DEFAULT_ROLE_ACTIONS,
    roleName: roleName ?? `new_${roleType}_role`,
    roleType,
  };

  return newRole;
};
