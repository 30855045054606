import { axiosClient, getApiRoutes } from 'api';
import { UserRoleItem } from 'api/models';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UserInfo } from 'features/user/store/models';
import { handleToastOnResponse } from 'features/utils/toastHelper';
import { DEFAULT_PAGE_LIMIT, DEFAULT_USER_ROLE } from 'models/constants';
import { ErrorMsgsEnum, MethodEnum, SuccessMsgEnum } from 'models/enums';
import { IndexInfo, UserItem } from './models';
import { GetUserDataByCognitoIdResponseDTO } from './models/response/GetUserDataByCognitoIdResponseDTO';
import { SearchForUsersDTO } from './models/SearchForUsersDTO';
import { SearchForUsersResponseDTO } from './models/SearchForUsersResponseDTO';
import { UserRolesDTO } from './models/UserRolesDTO';

export const fetchUser = async () => {
  const api = await getApiRoutes();

  const response = await axios.get<UserInfo>(api.mainAppGetUser);

  return response;
};

export const fetchUserId = async (indexInfo: IndexInfo) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: api.users,
    params: { ...indexInfo },
  };
  const response = await axios(request);

  return response;
};

export const createUserRole = async (data: UserRolesDTO) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: `${api.users}/${data.userId}/${api.userRoles}`,
    data,
  };

  const response = await axios(request);

  return response;
};

export const deleteUserRole = async (data: UserRolesDTO) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.DELETE,
    url: `${api.users}/${data.userId}/${api.userRoles}`,
    data,
  };

  const response = await axiosClient(request);

  return response;
};

export const bulkAddUserRoles = async (items: UserRolesDTO[]) => {
  return await bulkModifyUserRoles(items, 'POST');
};

export const bulkDeleteUserRoles = async (items: UserRolesDTO[]) => {
  return await bulkModifyUserRoles(items, 'DELETE');
};

//TODO: add logic
export const editUserRoles = () => {
  throw new Error('Function not implemented.');
};

const bulkModifyUserRoles = async (
  items: UserRolesDTO[],
  method: 'POST' | 'DELETE',
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method,
    url: `${api.users}/${api.roles}`,
    data: {
      usersRoles: items,
    },
  };

  const response = await axiosClient(request);

  handleToastOnResponse(
    response,
    method === 'POST'
      ? ErrorMsgsEnum.CREATE_USER_ROLE
      : ErrorMsgsEnum.DELETE_USER_ROLE,
    method === 'POST'
      ? SuccessMsgEnum.CREATE_USER_ROLE
      : SuccessMsgEnum.DELETE_USER_ROLE,
  );

  return response;
};

export const fetchUserRolesByUserId = async (userId: string) => {
  const api = await getApiRoutes();
  const url = `${api.users}/${userId}/${api.userRoles}`;
  const request: AxiosRequestConfig = {
    params: { pageLimit: DEFAULT_PAGE_LIMIT },
  };

  const response = await axios.get<UserRoleItem[]>(url, request);

  return response;
};

export const createUser = async (data: UserItem) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: `${api.users}`,
    data,
  };

  const response = await axiosClient(request);

  return response;
};

export const fetchUsersByEventReadRoles = async (eventReadRoles: string[]) => {
  const api = await getApiRoutes();

  const querystring = eventReadRoles
    .map((role) => {
      return 'role=' + role;
    })
    .join('&');

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: `${api.users}/${api.getUsersWithRoles}?${querystring}`,
  };

  const response = await axios(request);

  return response;
};

export const searchForUsers = async (data: SearchForUsersDTO) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.POST,
    url: api.searchForUsers,
    data: data,
  };

  const response = await axiosClient(request);

  return response.data as SearchForUsersResponseDTO;
};

export const fetchUserDataByCognitoId = async (
  projectionExpressionParams: string = 'issuer',
) => {
  const api = await getApiRoutes();

  const request: AxiosRequestConfig = {
    method: MethodEnum.GET,
    url: api.users,
    params: { projectionExpressionParams },
  };

  const response = await axios(request);

  return response.data as GetUserDataByCognitoIdResponseDTO;
};
