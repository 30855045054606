import { getConfigFromSessionStorage } from 'config/getConfigFromSessionStorage';
import { LoadingStateEnum } from 'models/enums';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

/**
 * Basic navbar
 */
export const EventNavbar = () => {
  const { event, loading } = useSelector((state: RootState) => state.event);
  const { mainAppURL } = getConfigFromSessionStorage();

  if (!event || loading !== LoadingStateEnum.IDLE) return <></>;

  return (
    <nav className="navbar navbar-expand navbar-dark bg-primary topbar flex-wrap justify-content-between px-lg-4 static-top">
      <button
        id="sidebarToggleTop"
        className="btn btn-link bg-white d-none rounded-circle mr-3"
      >
        <i className="fa fa-bars"></i>
      </button>
      <h1 className="h2 my-2 text-white">{event.title}</h1>
      <a
        target="_blank"
        href={`${mainAppURL}/event/${event.id}/`}
        rel="noreferrer"
        className="btn-pill d-flex align-items-center text-white small"
      >
        Open site in new window
        <i className="material-icons ml-2">launch</i>
      </a>
    </nav>
  );
};
