import React, { Dispatch, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { Link, generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchEventsList } from '../store/eventThunks';
import Spinner from 'components-lib/Spinner';

import { ROUTES } from 'routes';
import { RootState } from 'store/store';
import { LoadingStateEnum } from 'models/enums';
import { DEFAULT_TOAST_AUTO_CLOSE } from 'models/constants';
import { resetEvent } from './../store/eventActions';
import { SerializedError } from '@reduxjs/toolkit';

//TODO: Pagination
export default function EventList() {
  const { userError, user } = useSelector((state: RootState) => state.user);
  const userId = user?.id;

  const { loading, userEventList, eventError } = useSelector(
    (state: RootState) => state.event,
  );
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);

  //load user events ids by query to userRole table
  //if we want to show info about event in table we need to get this from events table
  useEffect(() => {
    const fetchEvents = async () => {
      await dispatch(fetchEventsList());
    };

    fetchEvents().catch((err) => {
      console.log('ERROR IN FETCH EVENT IDS :>> ', err);
      toast.error(err.message, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });
      push(ROUTES.errorPage);
    });
  }, [dispatch, push]); // TODO: if 'loading' is added as a dependency, the app will create an infinite loop loading the event list

  useEffect(() => {
    //TODO: add check for global error
    handleError(loading, eventError, userError, dispatch, push);
  }, [userError, loading, dispatch, push, eventError]);

  //TODO: use this when story is updated with some actions for selected events
  const onChangeSelection = (index: number) => {
    console.log('selectedEventIds :>> ', selectedEventIds);
    setSelectedEventIds((prevState) => {
      const isSelected = prevState.some((i) => i === index);
      if (!isSelected) {
        return [...prevState, index];
      } else {
        return prevState.filter((i) => i !== index);
      }
    });
  };

  if (loading === LoadingStateEnum.PENDING) {
    return <Spinner />;
  }

  if (loading === LoadingStateEnum.IDLE && userEventList) {
    return (
      <div className="max-800">
        <table className="table table-striped table-bordered table-hover table-responsive-sm">
          <thead>
            <tr>
              <th></th>
              <th>Event Title</th>
              <th>Event Id</th>
            </tr>
          </thead>
          <tbody>
            {userEventList?.map((eventItem, index) => {
              return (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      id={index.toString()}
                      onChange={() => {
                        onChangeSelection(index);
                      }}
                    />
                  </td>
                  <td>
                    <Link
                      to={generatePath(ROUTES.home.events.actions.edit, {
                        eventId: eventItem.id,
                      })}
                    >
                      {eventItem.title}
                    </Link>
                  </td>
                  <td>{eventItem.id}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div className="font-italic">No events for this user</div>;
  }
}

//TODO  create function for handle error global
function handleError(
  loading: LoadingStateEnum,
  eventError: SerializedError | undefined,
  userError: string | undefined,
  dispatch: Dispatch<any>,
  push: any,
) {
  if (loading === LoadingStateEnum.ERROR || eventError || userError) {
    toast.error(eventError?.message, { autoClose: DEFAULT_TOAST_AUTO_CLOSE });
    dispatch(resetEvent());
    push(ROUTES.errorPage);
  }
}
