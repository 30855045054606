import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { TemplateTree } from 'components-lib/TemplateTree/TemplateTree';
import { TemplateType } from 'models/TemplateType';
import React, { useState } from 'react';
import { DomainSettingsForm } from './components/DomainSettingsForm/DomainSettingsForm';

export const CustomDomainSettings = () => {
  const [selectedNode, setSelectedNode] = useState('');

  const onNodeSelect = (e: React.SyntheticEvent, nodeId: string) => {
    if (nodeId === selectedNode) return;
    e.preventDefault();

    setSelectedNode(nodeId);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Box>
            <TemplateTree
              templateType={TemplateType.CustomDomains}
              onTreeNodeSelect={onNodeSelect}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          {selectedNode && <DomainSettingsForm selectedNode={selectedNode} />}
        </Grid>
      </Grid>
    </Box>
  );
};
