import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import Spinner from 'components-lib/Spinner';
import { TreeNode } from 'path-list-to-tree';
import React, { useEffect, useMemo, useState } from 'react';
import { useBuildUITree } from './hooks/useBuildUITree';
import { useTemplateActions } from './hooks/useTemplateActions';
import { useLoadTree } from './hooks/useLoadTree';
import { CreateFolderModal } from 'components-lib/FileExplorerV2/Modals';
import { TemplateType } from 'models/TemplateType';
import { DEFAULT_TEMPLATE_ID, TEMPLATES_FOLDER } from 'models/constants';

interface IProps {
  templateType: TemplateType;
  selectedNode: string;
  setSelectedNode: React.Dispatch<React.SetStateAction<string>>;
  onTreeNodeSelect: (e: React.SyntheticEvent, nodeId: string) => void;
}

export const TemplateTree = ({
  templateType,
  selectedNode,
  setSelectedNode,
  onTreeNodeSelect,
}: IProps) => {
  const [treeReloadTrigger, setTreeReloadTrigger] = useState(false);
  const [tree, setTree] = useState<TreeNode[]>([]);
  const [filter, setFilter] = useState('');

  const { loadTree, loadingTree } = useLoadTree(templateType);

  const triggerTreeReload = () => {
    setTreeReloadTrigger(!treeReloadTrigger);
  };

  useEffect(() => {
    // ensure we get a dependency warning
    if (treeReloadTrigger) {
    }

    const loadTrees = async () => {
      const tree = await loadTree(TEMPLATES_FOLDER);

      setTree(tree);
    };

    loadTrees();
  }, [loadTree, treeReloadTrigger]);

  const filteredTree = useMemo(
    () =>
      tree.filter((treeNode) => {
        // remove the default template from the List
        if (treeNode.name === DEFAULT_TEMPLATE_ID) {
          return false;
        } else {
          return !!treeNode.name.match(new RegExp(`${filter}`, 'i'));
        }
      }),
    [filter, tree],
  );

  // Handles the case after a 'delete' when no nodes are left
  useEffect(() => {
    if (tree.every((treeNode) => treeNode.name !== selectedNode)) {
      setSelectedNode('');
    }
  }, [selectedNode, setSelectedNode, tree]);

  const UITree = useBuildUITree(filteredTree, onTreeNodeSelect, selectedNode);

  const onFilterChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.currentTarget?.value;

    if (value && value.length > 2) {
      setFilter(value);
    } else {
      setFilter('');
    }
  };

  const {
    handleAddClicked,
    handleAddTemplate,
    handleDeleteClicked,
    handleAddTemplateModalExit,
    showAddModal,
    isDeleting,
  } = useTemplateActions({
    templateType,
    selectedNode,
    triggerTreeReload,
    tree,
  });

  return (
    <>
      <Stack>
        <Box>
          <Button variant="outlined" onClick={handleAddClicked}>
            Add
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteClicked}
            disabled={isDeleting}
          >
            Delete
          </Button>
        </Box>
        <Box>
          <input
            type="text"
            id="tree-filter"
            placeholder="filter (min 3 characters)"
            onChange={onFilterChange}
          />
        </Box>
        <Box>{loadingTree ? <Spinner /> : UITree}</Box>
      </Stack>

      {showAddModal && (
        <CreateFolderModal
          folderPrefix={``}
          createFolderHandler={handleAddTemplate}
          title={'Add new template'}
          onExit={handleAddTemplateModalExit}
        />
      )}
    </>
  );
};
