import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getConfigInfo, initAxiosInterceptors } from './api';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { DEFAULT_TOAST_AUTO_CLOSE } from 'models/constants';

export let appConfig;

getConfigInfo()
  .then((configInfo) => {
    appConfig = configInfo;
    setChonkyDefaults({ iconComponent: ChonkyIconFA }); // For the file browser component
    initAxiosInterceptors();

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <ToastContainer
            position="top-right"
            autoClose={DEFAULT_TOAST_AUTO_CLOSE}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
            draggable
            toastClassName=""
          />
          <Router>
            <Route path="/" component={App} />
          </Router>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  .catch((error) => {
    ReactDOM.render(
      <React.StrictMode>
        <div>{error.toString()}</div>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
