import { VotingEventDTO } from 'features/event/models';
import { AccessEventDTO, UpdateEventDTO } from './../models/EventDTO';
import * as api from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorMsgsEnum, LoadingStateEnum, SuccessMsgEnum } from 'models/enums';
import { handleToastOnResponse } from './../../utils/toastHelper';
import { EventReadRoleItem, EventReadRoleTableItem } from 'api/models';
import { toast } from 'react-toastify';
import { RootState } from 'store/store';

export const fetchEventById: any = createAsyncThunk<any, string>(
  'event/fetchEventById',
  async (eventId) => {
    const response = await api.fetchEventById(eventId);

    return response.data;
  },
);

export const fetchPublicEventById: any = createAsyncThunk<any, string>(
  'event/fetchPublicEventById',
  async (eventId) => {
    const response = await api.fetchPublicEventById(eventId);

    return response.data;
  },
);

export const fetchEventsList: any = createAsyncThunk<
  any,
  any,
  { state: RootState }
>('event/fetchEventsList', async (_undefined, { getState }) => {
  const { loading } = getState().event;
  if (loading !== LoadingStateEnum.PENDING) {
    return;
  }
  const response = await api.fetchEventsList();

  return response.data;
});

export const createEvent: any = createAsyncThunk<any, any>(
  'event/createEvent',
  async (event) => {
    const response = await api.createEvent(event);

    handleToastOnResponse(
      response,
      ErrorMsgsEnum.CREATE_EVENT,
      SuccessMsgEnum.CREATE_EVENT,
    );

    if (response.status === 200 && !response.data.areStaticFoldersCreated) {
      toast.warn(ErrorMsgsEnum.CREATE_STATIC_PAGE_FOLDERS);
    }

    return response.data;
  },
);

export const updateEvent: any = createAsyncThunk<
  any,
  UpdateEventDTO | VotingEventDTO | AccessEventDTO
>('event/updateEvent', async (event) => {
  const response = await api.updateEvent(event);

  handleToastOnResponse(
    response,
    ErrorMsgsEnum.UPDATE_EVENT,
    SuccessMsgEnum.UPDATE_EVENT,
  );

  return response.data;
});

export const createEventReadRoles: any = createAsyncThunk<
  string,
  EventReadRoleItem
>('event/createEventReadRole', async (eventReadRoleData) => {
  const response = await api.createEventReadRoles(eventReadRoleData);

  handleToastOnResponse(
    response,
    ErrorMsgsEnum.CREATE_EVENT_READ_ROLE,
    SuccessMsgEnum.CREATE_EVENT_READ_ROLE,
  );

  return response.data;
});

export const deleteEventReadRoles: any = createAsyncThunk<
  string,
  EventReadRoleItem
>('event/deleteEventReadRole', async (eventReadRoleData) => {
  const response = await api.deleteEventReadRoles(eventReadRoleData);

  handleToastOnResponse(
    response,
    ErrorMsgsEnum.DELETE_EVENT_READ_ROLE,
    SuccessMsgEnum.DELETE_EVENT_READ_ROLE,
  );

  return response.data;
});

export const fetchEventReadRolesByEventId: any = createAsyncThunk<any, string>(
  'event/fetchEventReadRolesByEventId',
  async (eventId) => {
    const response = await api.fetchEventReadRolesByEventId(eventId);

    return response.data.map(
      (eventReadRoleItem: EventReadRoleTableItem) => eventReadRoleItem.role,
    );
  },
);
