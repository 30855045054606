import React from 'react';
import { generatePath, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import {
  EVENT_FORMS_SECTION_NAME,
  EVENT_INFO_SECTION_NAME,
  EVENT_STATIC_PAGES_SECTION_NAME,
  EVENT_USER_ACCESS_SECTION_NAME,
  EVENT_VOTING_ROUNDS_SECTION_NAME,
} from 'models/constants';
import { ROUTES } from 'routes';

/**
 * Sidebar displayed when in an event
 */
export function EventSidebar() {
  let { eventId } = useParams<Record<string, string>>();

  return (
    <>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          exact
          to={generatePath(ROUTES.home.events.withId, {
            eventId: eventId,
          })}
        >
          <span data-feather="home"></span>
          {EVENT_INFO_SECTION_NAME}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={generatePath(ROUTES.home.events.access.root, {
            eventId: eventId,
          })}
        >
          <span data-feather="file"></span>
          {EVENT_USER_ACCESS_SECTION_NAME}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={generatePath(ROUTES.home.events.formTemplates.root, {
            eventId: eventId,
          })}
        >
          <span data-feather="file"></span>
          {EVENT_FORMS_SECTION_NAME}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={generatePath(ROUTES.home.events.staticPages.root, {
            eventId: eventId,
          })}
        >
          <span data-feather="file"></span>
          {EVENT_STATIC_PAGES_SECTION_NAME}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={generatePath(ROUTES.home.events.votingRounds.root, {
            eventId: eventId,
          })}
        >
          <span data-feather="file"></span>
          {EVENT_VOTING_ROUNDS_SECTION_NAME}
        </NavLink>
      </li>
    </>
  );
}
