import {
  bulkAddUserRoles,
  bulkDeleteUserRoles,
  editUserRoles,
  searchForUsers,
} from 'api';
import { UserRolesDTO } from 'api/models';
import { UserData } from 'api/models/SearchForUsersResponseDTO';
import { GenericTable } from 'components-lib/GenericTable/GenericTable.component';
import { SearchForUsers } from 'components-lib/SearchForUsers/SearchForUsers';
import { userDataDefinition } from 'features/user/components/UserSearch/util/userDataDefinition';
import { DEFAULT_USER_ROLE } from 'models/constants';
import { EventRoleTypeEnum } from 'models/enums/Event.enum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import UserRolesModal from './Modals/UserRolesModal';

// TODO: check what is the puprpose of the custom roles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps {
  customRoles: string[];
}

const UserProvisioning = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [showBulkAdd, setShowBulkAdd] = useState(false);
  const [showBulkRemove, setShowBulkRemove] = useState(false);
  const [showEditUserCustomRoles, setShowEditUserCustomRoles] = useState(false);

  const { event } = useSelector((state: RootState) => state.event);

  const customRoleIds = useMemo(() => {
    if (event?.roles) {
      return Object.entries(event?.roles)
        .filter(
          ([, roleObject]) => roleObject.roleType === EventRoleTypeEnum.CUSTOM,
        )
        .map(([roleKey]) => roleKey);
    }

    return [];
  }, [event?.roles]);

  const customRoleNames = useMemo(() => {
    if (event?.roles) {
      return Object.values(event?.roles)
        .filter(
          (roleObject) => roleObject.roleType === EventRoleTypeEnum.CUSTOM,
        )
        .map((roleObject) => roleObject.roleName);
    }

    return [];
  }, [event?.roles]);

  const getUsers = useCallback(async () => {
    if (customRoleIds.length) {
      const userResult = await searchForUsers({
        roles: customRoleIds,
        eventId: event?.id,
      });
      if (userResult?.result && Object.keys(userResult.result).length > 0) {
        return Object.values(userResult.result);
      }
    }
    return [];
  }, [event?.id, customRoleIds]);

  useEffect(() => {
    const updateUsers = async () => {
      setUsers(await getUsers());
    };
    updateUsers();
  }, [getUsers]);

  const handleSearchComplete = (userData: UserData[]) => {
    setUsers(userData);
  };

  const handleResetSearch = async () => {
    const users = await getUsers();
    setUsers(users);
  };

  const selectionChangeHandler = (items: string[]) => {
    setSelectedUsers(items);
  };

  const onBulkAddClick = async () => {
    setShowBulkAdd(true);
  };

  const onBulkRemoveClick = async () => {
    setShowBulkRemove(true);
  };

  // TODO: review this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEditCustomRolesClick = async () => {
    setShowEditUserCustomRoles(true);
  };

  const createOwnerRoleDTOFromSeletedUsers = () => {
    const items: UserRolesDTO[] = selectedUsers.map((userId) => {
      // add #{eventId} to the role
      const ownerRole = `${DEFAULT_USER_ROLE}#${event?.id}`;

      return {
        userId,
        userRoles: [ownerRole],
      };
    });

    return items;
  };

  const handleMakeOwner = async () => {
    const items = createOwnerRoleDTOFromSeletedUsers();

    try {
      bulkAddUserRoles(items);
    } catch (error) {
      // TODO: error handling
    }
  };

  const handleRemoveOwner = async () => {
    const items = createOwnerRoleDTOFromSeletedUsers();

    try {
      bulkDeleteUserRoles(items);
    } catch (error) {
      // TODO: error handling
    }
  };

  let modal: JSX.Element | undefined = useMemo(() => {
    let title, callback, onExit;

    if (showBulkAdd) {
      title = 'Add roles';
      callback = bulkAddUserRoles;
      onExit = () => {
        setShowBulkAdd(false);
      };
    } else if (showBulkRemove) {
      title = 'Remove roles';
      callback = bulkDeleteUserRoles;
      onExit = () => {
        setShowBulkRemove(false);
      };
    } else if (showEditUserCustomRoles) {
      title = 'Edit custom roles';
      callback = editUserRoles;
      onExit = () => {
        setShowEditUserCustomRoles(false);
      };
    } else {
      return undefined;
    }

    return (
      <UserRolesModal
        title={title}
        users={selectedUsers}
        roles={customRoleNames}
        successCallback={callback}
        onExit={onExit}
      />
    );
  }, [
    customRoleNames,
    selectedUsers,
    showBulkAdd,
    showBulkRemove,
    showEditUserCustomRoles,
  ]);

  return (
    <div className="col-12">
      <h3>Provision Access</h3>

      {modal}
      <SearchForUsers
        onSearchComplete={handleSearchComplete}
        visibleColumns={['email']}
        eventId={event?.id}
      />
      <Button variant="link" className="mr-1" onClick={handleResetSearch}>
        Reset search
      </Button>
      <Button
        className="mr-1"
        onClick={onBulkAddClick}
        disabled={selectedUsers.length === 0 || customRoleIds.length === 0}
      >
        Add roles
      </Button>
      <Button
        onClick={onBulkRemoveClick}
        className="mr-1"
        disabled={selectedUsers.length === 0 || customRoleIds.length === 0}
      >
        Remove roles
      </Button>
      <Button
        onClick={handleMakeOwner}
        className="mr-1"
        disabled={selectedUsers.length === 0}
      >
        Make owner
      </Button>
      <Button
        onClick={handleRemoveOwner}
        className="mr-1"
        disabled={selectedUsers.length === 0}
      >
        Remove owner rights
      </Button>
      {/* <Button
        onClick={onEditCustomRolesClick}
        disabled={selectedUsers.length !== 1 || customRoleIds.length === 0}
      >
        Edit user&apos;s roles...
      </Button> */}
      <GenericTable
        rows={users}
        visibleColumns={['firstName', 'lastName', 'email', 'roles']}
        onSelectedItemsChange={selectionChangeHandler}
        headCellsDefinition={userDataDefinition}
      />
    </div>
  );
};

export default UserProvisioning;
