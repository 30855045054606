import { DEFAULT_FEATURE_FLAGS } from 'features/event/components/constants';
import { EventFormValues, FeatureFlags } from 'features/event/models';

/**
 * @description transfer flags field input to FeatureFlags object
 * @param {EventFormValues} fields
 * @returns {FeatureFlags}
 */
export function createFeatureFlagsFromFlagsArray(
  fields: EventFormValues,
): FeatureFlags {
  const result: any = {};
  if (!fields.featureFlags) return result;
  Object.keys(DEFAULT_FEATURE_FLAGS).forEach((key) => {
    fields.featureFlags.includes(key)
      ? (result[key] = true)
      : (result[key] = false);
  });

  //add isUsingRoleMapSystem
  result.isUsingRoleMapSystem = true;
  return result as FeatureFlags;
}
