export enum ErrorMsgsEnum {
  UPDATE_EVENT = 'Error when updating event',
  CREATE_EVENT = 'Error when creating event',
  CREATE_FORM_TEMPLATE = 'Error when creating event form template',
  UPDATE_FORM_TEMPLATE = 'Error when updating event form template',
  CREATE_USER_ROLE = 'Error when creating user role/s',
  DELETE_USER_ROLE = 'Error when deleting user role/s',
  CREATE_EVAL_FORM = 'Error when creating event eval form',
  CREATE_EVENT_READ_ROLE = 'Error when creating event read role',
  DELETE_EVENT_READ_ROLE = 'Error when deleting event read role',
  CREATE_STATIC_PAGE_FOLDERS = 'Error when creating folders for static pages. We still can add these folders manually',
  INVITE_USER = 'Invite user failed',
}
