import { createSlice } from '@reduxjs/toolkit';
import { LoadingStateEnum } from 'models/enums';
import {
  createFormTemplate,
  fetchFormTemplateById,
  updateFormTemplate,
} from './formTemplateThunks';
import { FormTemplatesState } from './models';
import {
  resetFormTemplateState,
  resetTemplate,
  resetFormTemplateError,
} from './formTemplateActions';
import StaticPages from 'features/event/components/StaticPages/StaticPages';

export const initialState: FormTemplatesState = {
  loading: LoadingStateEnum.IDLE,
  formTemplate: undefined,
  formTemplateError: undefined,
};

export const formTemplateSlice = createSlice({
  name: 'formTemplate',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [fetchFormTemplateById.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [fetchFormTemplateById.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        console.log(action.payload);
        state.formTemplate = action.payload;
        state.formTemplateError = undefined;
      }
    },
    [fetchFormTemplateById.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.formTemplateError = action.error;
      }
    },

    [createFormTemplate.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [createFormTemplate.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.formTemplate = action.payload;
      }
    },
    [createFormTemplate.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.formTemplateError = action.error;
      }
    },

    [updateFormTemplate.pending]: (state, action) => {
      if (state.loading === LoadingStateEnum.IDLE) {
        state.loading = LoadingStateEnum.PENDING;
      }
    },
    [updateFormTemplate.fulfilled]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.formTemplate = action.payload;
      }
    },
    [updateFormTemplate.rejected]: (state, action) => {
      if (state.loading === LoadingStateEnum.PENDING) {
        state.loading = LoadingStateEnum.IDLE;
        state.formTemplateError = action.error;
      }
    },

    [resetTemplate]: (state, action) => {
      state.formTemplate = initialState.formTemplate;
    },

    [resetFormTemplateState]: (state, action) => {
      state = Object.assign(state, initialState);
    },

    [resetFormTemplateError]: (state, action) => {
      state.formTemplateError = initialState.formTemplateError;
    },
  },
});

export default formTemplateSlice.reducer;
export const { resetState } = formTemplateSlice.actions;
