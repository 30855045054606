import * as Yup from 'yup';

Yup.addMethod(
  Yup.string,
  'uniqueRoundId',
  function (message, mapper = (a) => a) {
    return this.test('uniqueRoundId', message, function (roundId) {
      if (this.parent.isUpdate || !this.parent.event.votingRounds) return true;
      const roundsIds = Object.keys(this.parent.event.votingRounds) ?? [];
      return !roundsIds.includes(roundId);
    });
  },
);

Yup.addMethod(
  Yup.string,
  'isGreaterThenStartDate',
  function (message, mapper = (a) => a) {
    return this.test(
      'isGreaterThenStartDate',
      message,
      function (endDateString) {
        const startDate = new Date(this.parent.startTime);
        const endDate = new Date(endDateString);
        if (endDate.valueOf() === 0) return true;

        return startDate.valueOf() < endDate.valueOf();
      },
    );
  },
);

const votingRoundSchema = Yup.object().shape(
  {
    isUpdate: Yup.boolean(),
    roundId: Yup.string().uniqueRoundId(
      'Round with this id already exists',
      (a) => a,
    ),
    // .required('RoundID is required'),
    question: Yup.string().required('Question is required').max(1000),
    tabName: Yup.string().required('TabName is required').max(100),
    startTime: Yup.string().when('endTime', {
      is: null,
      then: Yup.string().nullable(true),
      otherwise: Yup.string()
        .required('If end time is selected start time is required')
        .nullable(true),
    }),
    endTime: Yup.string().when('startTime', {
      is: null,
      then: Yup.string().nullable(true),
      otherwise: Yup.string()
        .required('If start time is selected end time is required')
        .nullable(true)
        .isGreaterThenStartDate(
          'End time should be greater than start time',
          (a) => a,
        ),
    }),
  },
  [['startTime', 'endTime']],
);

export { votingRoundSchema };
